import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase',
  pure: true,
})
export class CamelcasePipe implements PipeTransform {
  transform(inputString: string): string {
    return inputString
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }
}
