import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WorkSheet, utils, writeFile } from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExportCsvService {
  exportAlarmListButton = new BehaviorSubject<boolean>(false);

  constructor() {}

  writeWorkSheetToFile(
    workSheet: WorkSheet,
    fileName: string,
    writeOptions: { [key: string]: any } = {},
    workSheetName: string | null = null,
  ): void {
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, workSheetName as string);
    writeFile(workBook, fileName, writeOptions);
    this.exportAlarmListButton.next(false);
  }
}
