import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent implements OnInit, OnDestroy {
  currentDateAndTime: Date = new Date();
  intervalId: any;

  ngOnInit(): void {
    this.setCurrentDateAndTime();
  }

  setCurrentDateAndTime() {
    this.intervalId = setInterval(() => {
      this.currentDateAndTime = new Date();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
