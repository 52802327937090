import { ProdMode } from '@utils/enums/prod-mode-enums';
import { Environment } from 'src/environments/IEnvironment';

export const environment: Environment = {
  production: false,
  e2e: false,
  version: '2.0.0',
  serverName: 'res-feature.thermalrsidev.com',
  redirectURI: '/gui/',
  prodMode: ProdMode.TouchPanel,
};
