import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-no-data-available',
  templateUrl: './no-data-available.component.html',
  styleUrl: './no-data-available.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class NoDataAvailableComponent {
  @Input() header: string = 'No Data Available';
  @Input() subHeader: string = '';
}
