<mat-sidenav-container>
  <mat-sidenav opened mode="side" [style.width]="sidenavWidth()" disableClose="true">
    <div class="col-flex" style="height: 100%">
      <div class="sidenav-header-{{ state() }} flex-col-center flex-between">
        <img src="assets/images/nvent_logo_white.png" class="sidenav-img-{{ state() }}" alt="nvent_logo" />

        <button class="btn-circle" (click)="toggleNav()">
          <img src="assets/images/{{ nextState() }}.png" style="width: 22px; margin: auto; display: inherit" />
        </button>
      </div>

      <mat-nav-list>
        @for (item of menuItems; track $index) {
          <a
            mat-list-item
            [routerLink]="item.disabled ? null : item.route"
            [routerLinkActive]="['is-active']"
            [matTooltipDisabled]="!item.disabled"
            [matTooltip]="item.title + ' feature not yet available'"
            [matTooltipPosition]="'right'"
            [style.cursor]="item.disabled ? 'not-allowed' : 'pointer'">
            <div class="material-symbols-outlined sidenav-icon-{{ state() }}">{{ item.icon }}</div>
            @if (!isCollapsed()) {
              <div class="list-item-name">{{ item.title }}</div>
            }
            @if (item.title === 'Alarms' && alarmCount) {
              <div class="{{ state() }}-count-notification">{{ alarmCount }}</div>
            }
          </a>
        } @empty {
          Empty Menu list
        }
      </mat-nav-list>

      <div class="sidenav-footer">
        <button class="flex" [ngClass]="isCollapsed() ? 'btn-circle' : 'btn-pill'" [routerLink]="'about'">
          <span
            class="material-symbols-outlined sidenav-footer-icon-{{ state() }}"
            [ngClass]="isCollapsed() ? 'medium-icon' : 'large-icon'">
            info
          </span>
          @if (!isCollapsed()) {
            <div>About</div>
          }
        </button>

        <div class="profile-container-{{ state() }}">
          @if (!isCollapsed()) {
            <div class="col-flex name-container">
              <div style="display: flex; gap: 3px">
                {{ userName }}
              </div>
            </div>
          } @else {
            <button class="btn-circle" (click)="openSettingModal()">
              <span class="material-symbols-outlined medium-icon">person</span>
            </button>
          }
          @if (isAuthenticated) {
            <button id="logout" class="btn-circle" (click)="signOut()" matTooltip="Logout">
              <span class="material-symbols-outlined medium-icon">logout</span>
            </button>
          } @else {
            <button id="login" class="btn-circle" (click)="signIn()" matTooltip="Login">
              <span class="material-symbols-outlined medium-icon">login</span>
            </button>
          }
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    style="background-color: white"
    [style.margin-left]="sidenavWidth()"
    [style.width]="'calc(100vw - ' + sidenavWidth() + ')'">
    <div class="outlet-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
