<div class="devices-table-header">
  <button
    mat-icon-button
    [ngClass]="showOverlay ? 'active-icon' : 'inactive-icon'"
    class="material-symbols-outlined"
    (click)="openOverlay()">
    {{ iconName }}
  </button>
</div>

@if (showOverlay) {
  <app-overlay
    title="Customize Columns"
    (closeOverlayEvent)="onCancel()"
    [showCloseBtn]="true"
    [iconName]="iconName"
    class="description">
    <ng-container body>
      <div class="body-container">
        <ng-container *ngTemplateOutlet="addColumns"></ng-container>
        <ng-container *ngTemplateOutlet="activeColumns"></ng-container>
      </div>
    </ng-container>
    <ng-container footer>
      <div class="action-buttons">
        <button id="close" class="round-edge-button btn-light" (click)="onCancel()">Cancel</button>
        <button id="save" class="round-edge-button btn-dark" (click)="saveChanges()">Save changes</button>
      </div>
    </ng-container>
  </app-overlay>
}

<ng-template #addColumns>
  <div class="add-columns-container">
    @if (showSearch) {
      <div class="search-container">
        <ng-container *ngTemplateOutlet="searchBar"></ng-container>
      </div>
    } @else {
      <div class="flex-between flex-col-center title-container">
        <div class="title">Add columns</div>
        <button class="search-icon-button">
          <span class="material-symbols-outlined search-icon" (click)="enableSearch()">search</span>
        </button>
      </div>
    }
    <div class="columns-list">
      @for (group of hiddenColumnGroups() | keyvalue; track group.key) {
        <div class="group">{{ group.key }}</div>
        @for (column of group.value; track column.field) {
          <div class="flex-between column-container">
            <div class="flex-col-center column-details">
              <span class="column-name">{{ column.header }}</span>
            </div>
            <button class="action" (click)="makeColumnVisible(column)">Add</button>
          </div>
        }
      }
    </div>
  </div>
</ng-template>

<ng-template #activeColumns>
  <div>
    <div class="flex-between flex-col-center title-container">
      <div class="title">Active columns</div>
    </div>
    <div class="columns-list">
      @for (column of lockedColumns(); track column.field) {
        <div class="flex-between column-container">
          <div class="flex-col-center column-details">
            <span class="material-symbols-outlined general-icon">lock</span>
            <span class="column-name">{{ column.header }}</span>
          </div>
        </div>
      }
      <div cdkDropList class="draggable-columns" cdkScrollable (cdkDropListDropped)="drop($event)">
        @for (column of draggableColumns(); track column.field) {
          <div class="flex-between column-container" cdkDragBoundary=".draggable-columns" cdkDragLockAxis="y" cdkDrag>
            <div class="flex-col-center column-details">
              <span class="material-symbols-outlined general-icon drag-icon" cdkDragHandle>drag_indicator</span>
              <span class="column-name">{{ column.header }}</span>
            </div>
            <button class="action" (click)="hideColumn(column)">Remove</button>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchBar>
  <mat-form-field class="full-width">
    <div class="flex">
      <span class="material-symbols-outlined flex-col-center cl-grey search-bar">search</span>
      <input type="text" placeholder="Search" matInput [formControl]="searchControl" />
      <span class="material-symbols-outlined flex-col-center font-med cl-grey cursor-pointer" (click)="clearSearch()"
        >close</span
      >
    </div>
  </mat-form-field>
</ng-template>
