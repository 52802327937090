import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent, merge, takeUntil } from 'rxjs';
import { AuthUtilityService } from './auth-utility.service';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  [key: string]: any;
  private idle: number = 10 * 60;
  private idling!: boolean;
  private idleHandle: any;
  private expiryTime!: Date | null;
  private activityEvents!: Observable<any>;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  private logoutUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private ngZone: NgZone,
    private authUtilityService: AuthUtilityService,
  ) {
    if (authUtilityService.isUserAuthenticated()) this.interruptTimer();
  }

  interruptTimer() {
    if (!this.activityEvents) {
      this.activityEvents = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown'),
        fromEvent(document, 'touchmove'),
        fromEvent(document, 'touchstart'),
        fromEvent(document, 'touchend'),
        fromEvent(document, 'wheel'),
        fromEvent(document, 'mousedown'),
      );
    }
    this.startTimer();
    this.activityEvents.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.startTimer();
    });
  }

  setIdleTimer(timer: number) {
    this.idle = timer * 60;
  }

  private startTimer() {
    this.clearInterval('idleHandle');
    this.expiryTime = new Date(new Date().getTime() + this.idle * 1000);
    if (this.idling) this.setIdling(!this.idling);
    const watchFn = () => {
      this.ngZone.run(() => {
        const diff = this.getExpiryDiff();
        if (diff > 0) {
          this.clearInterval('idleHandle');
          this.setIdleIntervalOutsideOfZone(watchFn, 1000);
        } else {
          this.onTimeout();
        }
      });
    };
    this.setIdleIntervalOutsideOfZone(watchFn, 1000);
  }

  private setIdleIntervalOutsideOfZone(watchFn: () => void, frequency: number): void {
    this.ngZone.runOutsideAngular(() => {
      this.idleHandle = setInterval(watchFn, frequency);
    });
  }

  private setIdling(value: boolean): void {
    this.idling = value;
  }

  private clearInterval(handleName: string): void {
    const handle = this[handleName];
    if (handle !== null && typeof handle !== 'undefined') {
      clearInterval(this[handleName]);
      this[handleName] = null;
    }
  }

  private getExpiryDiff(): number {
    const now: Date = new Date();
    const last: Date = this.expiryTime || now;
    return last.getTime() - now.getTime();
  }

  private onTimeout(): void {
    this.ngUnsubscribe.next(null);
    this.clearInterval('idleHandle');
    this.setIdling(true);
    this.logoutUser.next(true);
  }

  getUserLoggedOut(): Observable<boolean> {
    return this.logoutUser;
  }
}
