<ul class="accordion">
  @for (menu of menus; track menu; let menuIndex = $index) {
    <li [class.active]="menu.active">
      <div
        class="flex-between font-med menu"
        [ngClass]="menu.options.length == 0 ? 'no-child' : ''"
        (click)="menu.options.length > 0 ? toggle(menuIndex) : onSelect(menu.value!, menuIndex)">
        @if (menu.options.length == 0) {
          <span class="material-symbols-outlined radio-icon">
            @if (menu.active) {
              radio_button_checked
            } @else {
              radio_button_unchecked
            }
          </span>
        }
        {{ menu.groupName }}
        @if (menu.options.length > 0) {
          <span class="material-symbols-outlined arrow-icon">
            @if (menu.active) {
              arrow_drop_down
            } @else {
              arrow_right
            }
          </span>
        }
      </div>
      <ul class="submenu" #submenu [style.height.px]="menu.active ? submenu.scrollHeight : 0">
        @for (submenu of menu.options; track submenu; let submenuIndex = $index) {
          <li [class.active]="submenu.value == selectedGroup">
            <button
              class="menu-sub-header flex-col-center"
              (click)="onSelect(submenu.value, menuIndex)"
              [disabled]="submenu.isDisabled">
              <span class="material-symbols-outlined radio-icon">
                @if (submenu.value == selectedGroup) {
                  radio_button_checked
                } @else {
                  radio_button_unchecked
                }
              </span>
              {{ submenu.viewValue }}
            </button>
          </li>
        }
      </ul>
    </li>
  }
</ul>
