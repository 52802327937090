import { Injectable } from '@angular/core';
import { Ac2000p, Ac2000p_latest_actual_config } from '@models/ac2000';
import { AlarmUIModel } from '@models/alarm';
import { IncludedRelationship } from '@models/device';
import {
  AlarmAttrs,
  AlarmPulseAttrs,
  ArticleAttrs,
  Device,
  MultiAlarmData,
  MultiAlarmPulseData,
} from '@services/jsonapi-services/alarm.service';
import { Cm2000 } from '@services/jsonapi-services/cm2000.service';
import { Cm2000p } from '@services/jsonapi-services/cm2000p.service';
import { Elexant3500i, Elexant3500i_latest_actual_config } from '@services/jsonapi-services/elexant3500i.service';
import { Elexant40X0, Elexant40X0_latest_actual_config } from '@services/jsonapi-services/elexant40X0.service';
import { Elexant5010i, Elexant5010i_latest_actual_config } from '@services/jsonapi-services/elexant5010i.service';
import { Htc910, Htc910_latest_actual_config } from '@services/jsonapi-services/htc910.service';
import { Htc920, Htc920_latest_actual_config } from '@services/jsonapi-services/htc920.service';
import { Ngc20, Ngc20_latest_actual_config } from '@services/jsonapi-services/ngc20.service';
import { Ngc40htc } from '@services/jsonapi-services/ngc40htc.service';
import { Ngc40htc3 } from '@services/jsonapi-services/ngc40htc3.service';
import { Ngc40htc3_actual_config } from '@services/jsonapi-services/ngc40htc3_actual_config.service';
import { Ngc40htc_actual_config } from '@services/jsonapi-services/ngc40htc_actual_config.service';
import { Ngc40io, Ngc40io_latest_actual_config } from '@services/jsonapi-services/ngc40io.service';
import { Ngc40slim, Ngc40slim_latest_actual_config } from '@services/jsonapi-services/ngc40slim.service';
import { alarmDescriptionMapping } from '@utils/constants/alarm-description';
import { alarmTypeDescription, alarmTypes, getAlarmTypesMapping } from '@utils/constants/alarm-types';
import { DeviceType } from '@utils/enums/device-enums';

@Injectable({
  providedIn: 'root',
})
export class AlarmDataMapperService {
  constructor() {}
  mapAlarmsDataToFrontEnd(jsonData: MultiAlarmData, alarmPulseJsonData: MultiAlarmPulseData): AlarmUIModel[] {
    const alarms: AlarmUIModel[] = [];
    jsonData.data.forEach(alarm => {
      const includedData = jsonData.included as IncludedRelationship[];
      const alarmArticlesData = includedData.find(
        x => alarm.relationships?.article?.data && x.id === alarm.relationships?.article?.data?.id,
      )?.attributes as ArticleAttrs;

      const alarmPulsesData = alarmPulseJsonData.data.find(
        alarmPulse => alarmPulse.relationships?.alarm.data.id === alarm.id,
      )?.attributes as AlarmPulseAttrs;

      const device = includedData.find(
        x =>
          x.type === DeviceType.Devices &&
          alarm.relationships?.device?.data &&
          x.id === alarm.relationships?.device?.data?.id,
      ) as Device;

      const ngc40htc = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ngc40htc?.data?.id &&
          x.id === device?.relationships?.ngc40htc?.data?.id,
      ) as Ngc40htc;
      const htcActualConfigData = includedData.find(
        x =>
          ngc40htc?.relationships?.latest_actual_config?.data &&
          x.id === ngc40htc?.relationships?.latest_actual_config?.data?.id,
      ) as Ngc40htc_actual_config;
      const ngc40htc3 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ngc40htc3?.data &&
          x.id === device?.relationships?.ngc40htc3?.data?.id,
      ) as Ngc40htc3;
      const htc3ActualConfigData = includedData.find(
        x =>
          ngc40htc3?.relationships?.latest_actual_config?.data &&
          x.id === ngc40htc3?.relationships?.latest_actual_config?.data?.id,
      ) as Ngc40htc3_actual_config;
      const ngc40io = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ngc40io?.data?.id &&
          x.id === device?.relationships?.ngc40io?.data?.id,
      ) as Ngc40io;
      const ioActualConfigData = includedData.find(
        x =>
          ngc40io?.relationships?.latest_actual_config?.data &&
          x.id === ngc40io?.relationships?.latest_actual_config?.data?.id,
      ) as Ngc40io_latest_actual_config;
      const ngc40slim = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ngc40slim?.data?.id &&
          x.id === device?.relationships?.ngc40slim?.data?.id,
      ) as Ngc40slim;
      const slimActualConfigData = includedData.find(
        x =>
          ngc40slim?.relationships?.latest_actual_config?.data &&
          x.id === ngc40slim?.relationships?.latest_actual_config?.data?.id,
      ) as Ngc40slim_latest_actual_config;
      const htc910 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.htc910?.data?.id &&
          x.id === device?.relationships?.htc910?.data?.id,
      ) as Htc910;
      const htc910ActualConfigData = includedData.find(
        x =>
          htc910?.relationships?.latest_actual_config?.data &&
          x.id === htc910?.relationships?.latest_actual_config?.data?.id,
      ) as Htc910_latest_actual_config;
      const htc920 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.htc920?.data?.id &&
          x.id === device?.relationships?.htc920?.data?.id,
      ) as Htc920;
      const htc920ActualConfigData = includedData.find(
        x =>
          htc920?.relationships?.latest_actual_config?.data &&
          x.id === htc920?.relationships?.latest_actual_config?.data?.id,
      ) as Htc920_latest_actual_config;
      const elexant3500i = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.elexant3500i?.data?.id &&
          x.id === device?.relationships?.elexant3500i?.data?.id,
      ) as Elexant3500i;
      const elexant3500iActualConfigData = includedData.find(
        x =>
          elexant3500i?.relationships?.latest_actual_config?.data &&
          x.id === elexant3500i?.relationships?.latest_actual_config?.data?.id,
      ) as Elexant3500i_latest_actual_config;
      const elexant40x0 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.elexant40x0?.data?.id &&
          x.id === device?.relationships?.elexant40x0?.data?.id,
      ) as Elexant40X0;
      const elexant40x0ActualConfigData = includedData.find(
        x =>
          elexant40x0?.relationships?.latest_actual_config?.data &&
          x.id === elexant40x0?.relationships?.latest_actual_config?.data?.id,
      ) as Elexant40X0_latest_actual_config;
      const ngc20 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ngc20?.data?.id &&
          x.id === device?.relationships?.ngc20?.data?.id,
      ) as Ngc20;
      const ngc20ActualConfigData = includedData.find(
        x =>
          ngc20?.relationships?.latest_actual_config?.data &&
          x.id === ngc20?.relationships?.latest_actual_config?.data?.id,
      ) as Ngc20_latest_actual_config;
      const elexant5010i = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.elexant5010i?.data?.id &&
          x.id === device?.relationships?.elexant5010i?.data?.id,
      ) as Elexant5010i;
      const elexant5010iActualConfigData = includedData.find(
        x =>
          elexant5010i?.relationships?.latest_actual_config?.data &&
          x.id === elexant5010i?.relationships?.latest_actual_config?.data?.id,
      ) as Elexant5010i_latest_actual_config;

      const cm2000 = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.cm2000?.data?.id &&
          x.id === device?.relationships?.cm2000?.data?.id,
      ) as Cm2000;
      const cm2000p = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.cm2000p?.data?.id &&
          x.id === device?.relationships?.cm2000p?.data?.id,
      ) as Cm2000p;

      const ac2000p = includedData.find(
        x =>
          x.type !== DeviceType.Devices &&
          device?.relationships?.ac2000p?.data?.id &&
          x.id === device?.relationships?.ac2000p?.data?.id,
      ) as Ac2000p;
      const ac2000pActualConfigData = includedData.find(
        x =>
          ac2000p?.relationships?.latest_actual_config?.data &&
          x.id === ac2000p?.relationships?.latest_actual_config?.data?.id,
      ) as Ac2000p_latest_actual_config;

      const deviceType =
        ngc40htc?.type ||
        ngc40htc3?.type ||
        ngc40io?.type ||
        ngc40slim?.type ||
        htc910?.type ||
        htc920?.type ||
        ngc20?.type ||
        elexant5010i?.type ||
        cm2000?.type ||
        cm2000p?.type ||
        elexant3500i?.type ||
        elexant40x0?.type ||
        ac2000p?.type;
      const alarmType = this.getAlarmTypes(alarmArticlesData.name, deviceType);
      if (
        (htcActualConfigData ||
          htc3ActualConfigData ||
          ioActualConfigData ||
          slimActualConfigData ||
          ac2000pActualConfigData ||
          htc910ActualConfigData ||
          htc920ActualConfigData ||
          ngc20ActualConfigData ||
          elexant5010iActualConfigData ||
          cm2000 ||
          elexant3500iActualConfigData ||
          elexant40x0ActualConfigData ||
          cm2000p) &&
        alarmPulsesData?.begin_at &&
        alarmArticlesData &&
        alarmType !== 'NA'
      ) {
        alarms.push(
          this.mapAlarm(
            alarm.id,
            alarm.attributes,
            alarmType,
            deviceType,
            alarmArticlesData.name,
            alarmPulsesData,
            device,
            ac2000pActualConfigData,
            htcActualConfigData,
            htc3ActualConfigData,
            ioActualConfigData,
            slimActualConfigData,
            htc910ActualConfigData,
            htc920ActualConfigData,
            elexant3500iActualConfigData,
            elexant40x0ActualConfigData,
            ngc20ActualConfigData,
            elexant5010iActualConfigData,
            cm2000,
            cm2000p,
          ),
        );
      }
    });
    return alarms;
  }

  mapAlarm(
    alarmId: string | undefined,
    alarmData: AlarmAttrs | undefined,
    alarmType: string | undefined,
    deviceType: string,
    alarmDetail: string,
    alarmPulsesData: AlarmPulseAttrs,
    device: Device,
    ac2000pActualConfigData: Ac2000p_latest_actual_config,
    htcActualConfigData: Ngc40htc_actual_config,
    htc3ActualConfigData: Ngc40htc3_actual_config,
    ioActualConfigData: Ngc40io_latest_actual_config,
    slimActualConfigData: Ngc40slim_latest_actual_config,
    htc910ActualConfigData: Htc910_latest_actual_config,
    htc920ActualConfigData: Htc920_latest_actual_config,
    elexant3500iActualConfigData: Elexant3500i_latest_actual_config,
    elexant40x0ActualConfigData: Elexant40X0_latest_actual_config,
    ngc20ActualConfigData: Ngc20_latest_actual_config,
    elexant5010iActualConfigData: Elexant5010i_latest_actual_config,
    cm2000: Cm2000,
    cm2000p: Cm2000p,
  ): AlarmUIModel {
    return {
      id: alarmId,
      deviceId: device.id,
      deviceActualConfigId:
        htcActualConfigData?.id ||
        htc3ActualConfigData?.id ||
        ac2000pActualConfigData?.id ||
        ioActualConfigData?.id ||
        slimActualConfigData?.id ||
        htc910ActualConfigData?.id ||
        htc920ActualConfigData?.id ||
        elexant3500iActualConfigData?.id ||
        elexant40x0ActualConfigData?.id ||
        ngc20ActualConfigData?.id ||
        elexant5010iActualConfigData?.id,
      alarmType,
      deviceType: deviceType.slice(0, deviceType.length - 1),
      alarmDetail: alarmDescriptionMapping[alarmDetail],
      articleName: alarmDetail,
      timestamp: alarmPulsesData?.begin_at,
      alarmState: alarmData?.state,
      alarmPriority: alarmData?.priority === 1 ? 'Alarm' : 'Warning',
      devicePriority: device?.attributes?.priority,
      shelveTimeRemaining: alarmData?.shelved_until ? alarmData?.shelved_until : 'NA',
      deviceTag:
        htcActualConfigData?.attributes?.device_tag ||
        htc3ActualConfigData?.attributes?.device_tag ||
        ac2000pActualConfigData?.attributes?.device_tag ||
        ioActualConfigData?.attributes?.device_tag ||
        slimActualConfigData?.attributes?.device_tag ||
        htc910ActualConfigData?.attributes?.device_tag ||
        htc920ActualConfigData?.attributes?.device_tag ||
        elexant3500iActualConfigData?.attributes?.device_tag ||
        elexant40x0ActualConfigData?.attributes?.device_tag ||
        ngc20ActualConfigData?.attributes?.device_tag ||
        elexant5010iActualConfigData?.attributes?.device_tag ||
        cm2000?.attributes?.name ||
        cm2000p?.attributes?.name,
      description: this.getAlarmTypeDescription(alarmType) ?? 'NA',
    };
  }

  getAlarmTypes(alarmName: string, deviceType: string): string {
    const alarmTypesMapping = getAlarmTypesMapping(deviceType);
    for (const [alarmType, articleNames] of Object.entries(alarmTypesMapping))
      if (articleNames.includes(alarmName)) return alarmTypes[alarmType];
    return 'NA';
  }

  getAlarmTypeDescription(alarmType?: string): string {
    for (const [alarmTypeKey, alarmTypeValue] of Object.entries(alarmTypes))
      if (alarmType === alarmTypeValue) return alarmTypeDescription[alarmTypeKey];
    return 'NA';
  }
}
