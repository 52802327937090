import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { defaultPlotlyChartData, defaultPlotlyChartLayout } from '@app/utils/default-chart-options';
import { PlotlyChartModel, PlotlyNewDataPoint, PlotlyTraces, PlotlyUpdateInfo } from '@models/plotly-chart';
import { ChartType, PlotlyUpdateType } from '@utils/enums/trend-chart-enums';
import { cloneDeep, isEmpty } from 'lodash-es';
declare let Plotly: any;

@Component({
  selector: 'app-plotly-chart',
  templateUrl: './plotly-chart.component.html',
  styleUrl: './plotly-chart.component.css',
})
export class PlotlyChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('charts') chartElement!: ElementRef;
  @Input() chartConfig: PlotlyChartModel = { data: [defaultPlotlyChartData], layout: defaultPlotlyChartLayout };
  @Input() update!: PlotlyUpdateInfo | undefined;
  private range: Date[] = [];
  plotly = true;
  config: any;
  plotlyInstance: { layout: any; data: any } = { layout: {}, data: [] };

  ngAfterViewInit() {
    let resetIcon = Plotly.Icons.home;
    resetIcon.width = 800;
    resetIcon.height = 900;
    this.config = {
      responsive: true,
      displayModeBar: true,
      displaylogo: false,
      modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d'],
      modeBarButtonsToAdd: [
        {
          name: 'Reset Axes',
          icon: resetIcon,
          direction: 'up',
          click: (plotlyInstance: any) => {
            const newRange = cloneDeep(this.range as Date[]);
            Plotly.relayout(plotlyInstance, { 'xaxis.range': newRange });
          },
        },
      ],
      showTips: false,
    };
    Plotly.newPlot(this.chartElement.nativeElement, this.chartConfig.data, this.chartConfig.layout, this.config).then(
      (instance: any) => {
        this.plotlyInstance.data = instance.data;
        this.plotlyInstance.layout = instance.layout;
        this.range = cloneDeep(instance.layout.xaxis.range);
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chartElement && this.update) {
      switch (this.update.type) {
        case PlotlyUpdateType.trendDataSelection:
          if ((this.update.data as PlotlyTraces).visible.length)
            Plotly.restyle(
              this.chartElement.nativeElement,
              { visible: true, showlegend: true },
              (this.update.data as PlotlyTraces).visible,
            );
          if ((this.update.data as PlotlyTraces).invisible.length)
            Plotly.restyle(
              this.chartElement.nativeElement,
              { visible: false },
              (this.update.data as PlotlyTraces).invisible,
            );

          let changedLayout = {};
          if ((this.chartConfig.layout.title as any).text === ChartType.temperatureChart) {
            changedLayout = (this.update.data as PlotlyTraces).visible.length
              ? { 'yaxis.visible': true }
              : { 'yaxis.visible': false };
          } else {
            (this.update.data as PlotlyTraces).visible.forEach(plotIndex => {
              const yaxisKey = `yaxis${plotIndex != 0 ? plotIndex + 1 : ''}.visible`;
              changedLayout = { ...changedLayout, [yaxisKey]: true };
            });
            (this.update.data as PlotlyTraces).invisible.forEach(plotIndex => {
              const yaxisKey = `yaxis${plotIndex != 0 ? plotIndex + 1 : ''}.visible`;
              changedLayout = { ...changedLayout, [yaxisKey]: false };
            });
          }
          Plotly.relayout(this.chartElement.nativeElement, cloneDeep(changedLayout));
          break;
        case PlotlyUpdateType.duration:
          Plotly.relayout(this.chartElement.nativeElement, { 'xaxis.range': this.update.data });
          this.range = cloneDeep(this.update.data as Date[]);
          break;
        case PlotlyUpdateType.newDataPoint:
          Plotly.extendTraces(this.chartElement.nativeElement, this.update.data, [
            ...Array((this.update.data as PlotlyNewDataPoint).x.length).keys(),
          ]);
          break;
        case PlotlyUpdateType.setPoint:
          Plotly.deleteTraces(this.chartElement.nativeElement, 3);
          Plotly.addTraces(this.chartElement.nativeElement, this.update.data);
          break;
      }
    } else if (this.chartElement && !isEmpty(changes['chartConfig'])) {
      Plotly.newPlot(this.chartElement.nativeElement, this.chartConfig.data, this.chartConfig.layout, this.config).then(
        (instance: any) => {
          this.plotlyInstance.data = instance.data;
          this.plotlyInstance.layout = instance.layout;
          this.range = cloneDeep(instance.layout.xaxis.range);
        },
      );
    }
  }
}
