import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Ngc40slim_desired_config,
  Ngc40slim_desired_configData,
  Ngc40slim_desired_configsData,
} from '@models/jsonapi-models/desired-configs/ngc40slim-desired-config.model';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root',
})
export class Ngc40slim_desired_configService {
  private uiBaseURL = '';
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40slim_desired_configs(params: HttpParams): Observable<Ngc40slim_desired_configsData> {
    const url = `${this.uiBaseURL}/ngc40slim_desired_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
      }),
      params: params,
    };
    return this.http.get<Ngc40slim_desired_configsData>(url, options);
  }

  getNgc40slim_desired_config(id: string, params: HttpParams): Observable<Ngc40slim_desired_configData> {
    const url = `${this.uiBaseURL}/ngc40slim_desired_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
      }),
      params: params,
    };
    return this.http.get<Ngc40slim_desired_configData>(url, options);
  }

  addNgc40slim_desired_config(
    ngc40slim_desired_config: Ngc40slim_desired_config,
  ): Observable<Ngc40slim_desired_config> {
    const url = `${this.uiBaseURL}/ngc40slim_desired_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40slim_desired_config>(url, { data: ngc40slim_desired_config }, options);
  }

  updateNgc40slim_desired_config(
    ngc40slim_desired_config: Ngc40slim_desired_config,
  ): Observable<Ngc40slim_desired_configData> {
    const url = `${this.uiBaseURL}/ngc40slim_desired_configs/` + ngc40slim_desired_config.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40slim_desired_configData>(url, { data: ngc40slim_desired_config }, options);
  }

  removeNgc40slim_desired_config(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40slim_desired_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
