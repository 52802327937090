import { Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceUIModel, TrendDataUIModel } from '@app/models/device';
import { Duration, DurationRange } from '@app/models/select-durations';
import { TrendSelectionAttribute, TrendSelectionCategories } from '@app/models/trend-selection';
import { UserPreferenceUIModel } from '@app/models/user_preference';
import { ConversionService } from '@app/services/conversion.service';
import { Event, EventService, ResourceObject } from '@app/services/event.service';
import { DeviceMiddlewareService } from '@app/services/jsonapi-services/device-middleware.service';
import { LineChartOptionsService } from '@app/services/line-chart-options.service';
import { Cm2000DeviceDataMapperService } from '@app/services/mapper-services/cm2000-device-data-mapper.service';
import { Cm2000pDeviceDataMapperService } from '@app/services/mapper-services/cm2000p-device-data-mapper.service';
import { Elexant3500iDeviceDataMapperService } from '@app/services/mapper-services/elexant3500i-device-data-mapper.service';
import { Elexant40X0DeviceDataMapperService } from '@app/services/mapper-services/elexant40X0-device-data-mapper.service';
import { Elexant5010iDeviceDataMapperService } from '@app/services/mapper-services/elexant5010i-device-data-mapper.service';
import { Htc910DeviceDataMapperService } from '@app/services/mapper-services/htc910-device-data-mapper.service';
import { Htc920DeviceDataMapperService } from '@app/services/mapper-services/htc920-device-data-mapper.service';
import { Ngc20DeviceDataMapperService } from '@app/services/mapper-services/ngc20-device-data-mapper.service';
import { Ngc40htcDeviceDataMapperService } from '@app/services/mapper-services/ngc40htc-device-data-mapper.service';
import { Ngc40htc3DeviceDataMapperService } from '@app/services/mapper-services/ngc40htc3-device-data-mapper.service';
import { Ngc40ioDeviceDataMapperService } from '@app/services/mapper-services/ngc40io-device-data-mapper.service';
import { Ngc40slimDeviceDataMapperService } from '@app/services/mapper-services/ngc40slim-device-data-mapper.service';
import { TrendsFilterService } from '@app/services/trends-filter.service';
import { UserUtilService } from '@app/services/user-util.service';
import { defaultTrendData, eventDeviceRelationships } from '@app/utils/constants/device-constants';
import { DeviceStateType } from '@app/utils/enums/device-enums';
import { Events } from '@app/utils/enums/event-enum';
import { ChartType, PlotlyUpdateType, TemperatureSeriesAttributes } from '@app/utils/enums/trend-chart-enums';
import { TemperatureUnits } from '@app/utils/enums/unit-enums';
import {
  ChartSeriesData,
  PlotlyChartData,
  PlotlyChartLayout,
  PlotlyNewDataPoint,
  PlotlyTraces,
  PlotlyUpdateInfo,
} from '@models/plotly-chart';
import { initialNewDataPoints } from '@utils/constants/device-trend-constants';
import { emptyPlotlyChart } from '@utils/default-chart-options';
import { cloneDeep, isEqual } from 'lodash-es';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trend',
  templateUrl: './trend.component.html',
  styleUrl: './trend.component.css',
})
export class TrendComponent implements OnInit, OnChanges, OnDestroy {
  @BlockUI('trendBody') trendBlockUI!: NgBlockUI;
  @Input() device!: DeviceUIModel;
  deviceTrendData: TrendDataUIModel = cloneDeep(defaultTrendData);
  preferenceUnit: string = TemperatureUnits.Celsius;
  temperatureChartOptions: { data: PlotlyChartData[]; layout: PlotlyChartLayout } = emptyPlotlyChart;
  currentChartOptions: { data: PlotlyChartData[]; layout: PlotlyChartLayout } = emptyPlotlyChart;
  powerChartOptions: { data: PlotlyChartData[]; layout: PlotlyChartLayout } = emptyPlotlyChart;
  initialized!: Promise<void>;
  destroyRef = inject(DestroyRef);
  trendSelectionCategories: TrendSelectionCategories = {
    plot1Categories: [],
    plot2Categories: [],
    plot3Categories: [],
  };
  private deviceStateUpdateEventId: number = 0;
  private deviceActualConfigUpdateEventId = 0;
  private deviceActualConfigCreateEventId = 0;
  currentDuration: Duration | DurationRange = Duration.oneDay;
  showTrendSelectionCategory: boolean = false;
  updateTemperatureChart!: PlotlyUpdateInfo | undefined;
  updateCurrentChart!: PlotlyUpdateInfo | undefined;
  updatePowerChart!: PlotlyUpdateInfo | undefined;
  trendDataSubscription!: Subscription;

  constructor(
    private lineChartOptionService: LineChartOptionsService,
    private userUtilsService: UserUtilService,
    private conversionService: ConversionService,
    private deviceMiddlewareService: DeviceMiddlewareService,
    private eventService: EventService,
    private htc910DeviceDataMapper: Htc910DeviceDataMapperService,
    private htc920DeviceDataMapper: Htc920DeviceDataMapperService,
    private ngc20DeviceDataMapper: Ngc20DeviceDataMapperService,
    private ngc40htcDeviceDataMapper: Ngc40htcDeviceDataMapperService,
    private ngc40htc3DeviceDataMapper: Ngc40htc3DeviceDataMapperService,
    private ngc40ioDeviceDataMapper: Ngc40ioDeviceDataMapperService,
    private ngc40slimDeviceDataMapper: Ngc40slimDeviceDataMapperService,
    private cm2000DeviceDataMapper: Cm2000DeviceDataMapperService,
    private cm2000pDeviceDataMapper: Cm2000pDeviceDataMapperService,
    private elexant40x0DeviceDataMapper: Elexant40X0DeviceDataMapperService,
    private elexant5010iDeviceDataMapper: Elexant5010iDeviceDataMapperService,
    private elexant3500iDeviceDataMapper: Elexant3500iDeviceDataMapperService,
    private trendFilterService: TrendsFilterService,
  ) {}

  ngOnInit(): void {
    if (this.device) {
      this.getDeviceTrendData();
      this.getCurrentUserPreference();
      this.bindEvents();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.device &&
      changes['device'].previousValue &&
      changes['device'].currentValue.id !== changes['device'].previousValue.id
    ) {
      if (this.trendDataSubscription) {
        this.trendBlockUI.reset();
        this.trendDataSubscription.unsubscribe();
      }
      this.getDeviceTrendData();
    }
  }

  getCurrentUserPreference() {
    this.userUtilsService
      .getUserPreference()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userPreference: UserPreferenceUIModel) => {
        this.preferenceUnit = userPreference.unit;
        if (this.deviceTrendData.temperatureChart?.length) {
          this.updateTempTrendDataUnit();
          this.intialiseTrendDataForChart();
        }
      });
  }

  updateTempTrendDataUnit() {
    this.deviceTrendData.temperatureChart = this.deviceTrendData.temperatureChart.map(tempSeries => {
      tempSeries.y = tempSeries.y.map(point => {
        point = Number(this.conversionService.temperatureUnitConversion(point, this.preferenceUnit));
        return point;
      });
      return tempSeries;
    });
  }

  getDeviceTrendData() {
    this.trendBlockUI.start('Loading Device Trend Data..');
    this.deviceTrendData = cloneDeep(defaultTrendData);
    this.temperatureChartOptions.data = [];
    this.currentChartOptions.data = [];
    this.powerChartOptions.data = [];
    this.updateCurrentChart = undefined;
    this.updatePowerChart = undefined;
    this.updateTemperatureChart = undefined;
    this.trendDataSubscription = this.deviceMiddlewareService
      .getDeviceTrendsDataFromJsonApi(this.device.id!, this.device.deviceType!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: TrendDataUIModel) => {
          this.deviceTrendData = { ...response };
          this.preferenceUnit === TemperatureUnits.Fahrenheit ? this.updateTempTrendDataUnit() : '';
          this.updateCharts(this.trendFilterService.getDefaultTrendSelection(this.device.deviceType!));
          this.intialiseTrendDataForChart();
          this.trendBlockUI.stop();
        },
        error: () => {
          this.trendBlockUI.stop();
          console.error('Error While Fetching trend-data of device', this.device.deviceTag);
        },
      });
  }

  intialiseTrendDataForChart() {
    if (this.deviceTrendData) {
      if (this.deviceTrendData.temperatureChart) {
        const trendSelectionAttributes = this.trendFilterService.getDefaultTrendSelection(this.device.deviceType!)
          .plot1Categories as TrendSelectionAttribute[];
        const response = this.trendFilterService.getTemperatureData(
          this.deviceTrendData,
          this.currentDuration,
          trendSelectionAttributes,
        );
        const chartObject = cloneDeep(emptyPlotlyChart);
        response[0].forEach((chartData: any, i: number) => {
          chartObject.data.push(this.lineChartOptionService.getPlotlyChartSeries(chartData, 'scatter', 'lines', i));
        });
        chartObject.layout = this.lineChartOptionService.getPlotlyChartLayout(
          ChartType.temperatureChart,
          response[1] as Date[],
          this.preferenceUnit,
          undefined,
          trendSelectionAttributes,
        );
        this.temperatureChartOptions = { ...chartObject };
      }
      if (this.deviceTrendData.currentChart) {
        const trendSelectionAttributes = this.trendFilterService.getDefaultTrendSelection(this.device.deviceType!)
          .plot2Categories as TrendSelectionAttribute[];
        const response = this.trendFilterService.getCurrentData(
          this.deviceTrendData,
          this.currentDuration,
          trendSelectionAttributes,
        );
        const chartObject = cloneDeep(emptyPlotlyChart);
        response[0].forEach((chartData: any, i: number) => {
          chartObject.data.push(this.lineChartOptionService.getPlotlyChartSeries(chartData, 'scatter', 'lines', i));
        });
        chartObject.layout = this.lineChartOptionService.getPlotlyChartLayout(
          ChartType.currentChart,
          response[1] as Date[],
          undefined,
          undefined,
          trendSelectionAttributes,
        );
        this.currentChartOptions = { ...chartObject };
      }
      if (this.deviceTrendData.powerChart) {
        const trendSelectionAttributes = this.trendFilterService.getDefaultTrendSelection(this.device.deviceType!)
          .plot3Categories as TrendSelectionAttribute[];
        const response = this.trendFilterService.getPowerData(
          this.deviceTrendData,
          this.currentDuration,
          trendSelectionAttributes,
        );
        const chartObject = cloneDeep(emptyPlotlyChart);
        response[0]?.forEach((chartData: any, i: number) => {
          chartObject.data.push(this.lineChartOptionService.getPlotlyChartSeries(chartData, 'scatter', 'lines', i));
        });
        chartObject.layout = this.lineChartOptionService.getPlotlyChartLayout(
          ChartType.powerChart,
          response[1] as Date[],
          '',
          this.device.deviceType,
          trendSelectionAttributes,
        );
        this.powerChartOptions = { ...chartObject };
      }
    }
  }

  handleDurationChange(duration: Duration | DurationRange): void {
    this.currentDuration = duration;
    const updatedData: PlotlyUpdateInfo = {
      type: PlotlyUpdateType.duration,
      data: this.trendFilterService.getDurationDates(duration),
    };
    this.updateTemperatureChart = this.updateCurrentChart = this.updatePowerChart = cloneDeep(updatedData);
  }

  toggleTrendSelectionOverlay() {
    this.showTrendSelectionCategory = !this.showTrendSelectionCategory;
  }

  updateCharts(updatedTrendSelectionData: TrendSelectionCategories): void {
    this.trendFilterService.setTrendMap(this.device.deviceType!, updatedTrendSelectionData);

    for (const plot in updatedTrendSelectionData) {
      if (
        !isEqual(
          this.trendSelectionCategories[plot as keyof TrendSelectionCategories],
          updatedTrendSelectionData[plot as keyof TrendSelectionCategories],
        )
      ) {
        const updatedData: PlotlyUpdateInfo = {
          type: PlotlyUpdateType.trendDataSelection,
          data: {
            visible: [],
            invisible: [],
          } as PlotlyTraces,
        };
        this.trendSelectionCategories[plot as keyof TrendSelectionCategories] =
          updatedTrendSelectionData[plot as keyof TrendSelectionCategories]!;

        this.trendSelectionCategories[plot as keyof TrendSelectionCategories]!.forEach((plot, index) => {
          plot.plotted
            ? (updatedData.data as PlotlyTraces).visible.push(index)
            : (updatedData.data as PlotlyTraces).invisible.push(index);
        });

        switch (plot) {
          case 'plot1Categories':
            this.updateTemperatureChart = cloneDeep(updatedData);
            break;
          case 'plot2Categories':
            this.updateCurrentChart = cloneDeep(updatedData);
            break;
          case 'plot3Categories':
            this.updatePowerChart = cloneDeep(updatedData);
            break;
        }
      }
    }
    this.trendFilterService.updateTrendMap(this.device.deviceType!);
  }

  onActualConfigCreateorUpdate(deviceConfigEvent: Event) {
    this.initialized
      .then(() => {
        const relationshipType = eventDeviceRelationships[deviceConfigEvent.data.type.replace('_actual_config', '')];
        if (
          this.device?.id ===
          deviceConfigEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]!.data.id
        ) {
          this.appendConfigData(deviceConfigEvent);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  appendConfigData(eventData: Event) {
    const setPoint =
      this.preferenceUnit === TemperatureUnits.Celsius
        ? this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_control_temperature_setpoint)
        : this.conversionService.getDigitFromValue(
            this.conversionService.unitConversion(eventData.data.attributes?.htc_0_control_temperature_setpoint),
          );
    if (this.deviceTrendData) {
      const setPointIndex = this.deviceTrendData.temperatureChart.findIndex(
        chart => chart.name === TemperatureSeriesAttributes.setPoint,
      );
      for (let i = 0; i < this.deviceTrendData.temperatureChart[setPointIndex].x.length; i++) {
        this.deviceTrendData.temperatureChart[setPointIndex].y[i] = setPoint;
      }
      this.deviceTrendData.temperatureChart[setPointIndex].visible = this.trendSelectionCategories.plot1Categories.find(
        selection => selection.name === TemperatureSeriesAttributes.setPoint,
      )?.plotted;
      const updatedDate: PlotlyUpdateInfo = {
        type: PlotlyUpdateType.setPoint,
        data: this.lineChartOptionService.getPlotlyChartSeries(
          this.deviceTrendData.temperatureChart[setPointIndex]!,
          'scatter',
          'lines',
          setPointIndex,
        ),
      };
      this.updateTemperatureChart = cloneDeep(updatedDate);
    }
  }

  appendNewTrendData(newTrendData: TrendDataUIModel) {
    const updatedTemperatureData: PlotlyNewDataPoint = cloneDeep(initialNewDataPoints);
    const updatedCurrentData: PlotlyNewDataPoint = cloneDeep(initialNewDataPoints);
    const updatedPowerData: PlotlyNewDataPoint = cloneDeep(initialNewDataPoints);

    if (this.deviceTrendData) {
      for (const trendCategory in this.deviceTrendData) {
        if (this.deviceTrendData[trendCategory as keyof TrendDataUIModel] && trendCategory !== 'deviceTag') {
          for (const chart of this.deviceTrendData[trendCategory as keyof TrendDataUIModel]! as ChartSeriesData[]) {
            if (newTrendData[trendCategory as keyof TrendDataUIModel]) {
              const value = (newTrendData[trendCategory as keyof TrendDataUIModel]! as ChartSeriesData[]).find(
                z => z.name === chart.name && z.x.length,
              );
              if (value) {
                chart.x.push(value.x[0]);
                chart.y.push(value.y[0]);
                switch (trendCategory) {
                  case 'temperatureChart':
                    updatedTemperatureData.x.push([...value.x]);
                    updatedTemperatureData.y.push([...value.y]);
                    break;
                  case 'currentChart':
                    updatedCurrentData.x.push([...value.x]);
                    updatedCurrentData.y.push([...value.y]);
                    break;
                  case 'powerChart':
                    updatedPowerData.x.push([...value.x]);
                    updatedPowerData.y.push([...value.y]);
                    break;
                }
              }
            }
          }
        }
      }
      this.updateTemperatureChart = {
        type: PlotlyUpdateType.newDataPoint,
        data: cloneDeep(updatedTemperatureData),
      };
      this.updateCurrentChart = {
        type: PlotlyUpdateType.newDataPoint,
        data: cloneDeep(updatedCurrentData),
      };
      this.updatePowerChart = {
        type: PlotlyUpdateType.newDataPoint,
        data: cloneDeep(updatedPowerData),
      };
    }
  }

  onDeviceStateUpdate(deviceStateEvent: Event) {
    this.initialized
      .then(() => {
        const deviceType = deviceStateEvent.data.type;
        const relationshipType = eventDeviceRelationships[deviceStateEvent.data.type.replace('_states', '')];
        if (
          this.device?.id ===
          deviceStateEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]?.data.id
        ) {
          switch (deviceType) {
            case DeviceStateType.Htc910:
              this.appendNewTrendData(
                this.htc910DeviceDataMapper.appendHtc910TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Htc920:
              this.appendNewTrendData(
                this.htc920DeviceDataMapper.appendHtc920TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Ngc40htc:
              this.appendNewTrendData(
                this.ngc40htcDeviceDataMapper.appendHtcTrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Ngc40htc3:
              this.appendNewTrendData(
                this.ngc40htc3DeviceDataMapper.appendHtc3TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Ngc40io:
              this.appendNewTrendData(
                this.ngc40ioDeviceDataMapper.appendNgc40IoTrendData(deviceStateEvent, this.preferenceUnit),
              );
              break;
            case DeviceStateType.Ngc40slim:
              this.appendNewTrendData(
                this.ngc40slimDeviceDataMapper.appendNgc40SlimTrendData(deviceStateEvent, this.preferenceUnit),
              );
              break;
            case DeviceStateType.Cm2000:
              this.appendNewTrendData(
                this.cm2000DeviceDataMapper.appendCM2000TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Cm2000p:
              this.appendNewTrendData(
                this.cm2000pDeviceDataMapper.appendCM2000pTrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Elexant40X0:
              this.appendNewTrendData(
                this.elexant40x0DeviceDataMapper.appendElexant40X0TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Elexant5010i:
              this.appendNewTrendData(
                this.elexant5010iDeviceDataMapper.appendElexant5010iTrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Ngc20:
              this.appendNewTrendData(
                this.ngc20DeviceDataMapper.appendNgc20TrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            case DeviceStateType.Elexant3500i:
              this.appendNewTrendData(
                this.elexant3500iDeviceDataMapper.appendElexant3500iTrendData(
                  deviceStateEvent,
                  this.preferenceUnit,
                  this.device.setPoint!,
                ),
              );
              break;
            default:
              this.appendNewTrendData(defaultTrendData);
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.deviceStateUpdateEventId = this.eventService.register(
        Events.StatesCreate,
        this.onDeviceStateUpdate.bind(this),
      );
      this.deviceActualConfigUpdateEventId = this.eventService.register(
        Events.ActualConfigUpdate,
        this.onActualConfigCreateorUpdate.bind(this),
      );
      this.deviceActualConfigCreateEventId = this.eventService.register(
        Events.ActualConfigCreate,
        this.onActualConfigCreateorUpdate.bind(this),
      );
      resolve();
    });
  }

  ngOnDestroy(): void {
    if (
      this.deviceActualConfigUpdateEventId !== 0 &&
      this.deviceActualConfigCreateEventId !== 0 &&
      this.deviceStateUpdateEventId !== 0
    ) {
      this.eventService.deregister(Events.ActualConfigUpdate, this.deviceActualConfigUpdateEventId);
      this.eventService.deregister(Events.ActualConfigCreate, this.deviceActualConfigCreateEventId);
      this.eventService.deregister(Events.StatesCreate, this.deviceStateUpdateEventId);
    }
  }
}
