import { Pipe, PipeTransform } from '@angular/core';
import { DeviceUIModel } from '@app/models/device';
import { ConversionService } from '@app/services/conversion.service';
import { DeviceAttributeNames, DeviceType } from '@app/utils/enums/device-enums';
import { Precision } from '@app/utils/enums/unit-enums';
import { digit_followed_by_I_regex } from '@utils/patterns';

@Pipe({
  name: 'deviceAttributeValue',
  pure: true,
})
export class DeviceAttributeValuePipe implements PipeTransform {
  constructor(private conversionService: ConversionService) {}

  transform(attribute: string, device: DeviceUIModel, isUnitRequired?: boolean): string {
    const value = device[attribute as keyof DeviceUIModel];
    if (value === 'None') return '-';

    let precision = Precision.Zero;
    if (attribute === DeviceAttributeNames.LineCurrent) precision = Precision.One;

    switch (attribute) {
      case DeviceAttributeNames.DeviceTag:
      case DeviceAttributeNames.Type:
      case DeviceAttributeNames.FirmwareVersion:
      case DeviceAttributeNames.ModbusAddress:
      case DeviceAttributeNames.DeviceVariant:
      case DeviceAttributeNames.SerialNumber:
        return value?.toString() ?? '-';
      case DeviceAttributeNames.AlarmCount:
        return value ? value.toString() : '';
      case DeviceAttributeNames.BridgeName:
        return this.getBridgeName(device.bridgeName, device.deviceConnectionType as string);
      case DeviceAttributeNames.OutputState:
        return this.getOutputState(device.outputState);
      case DeviceAttributeNames.ArticleName:
        return this.getDevicePort(device.articleName);
      case DeviceAttributeNames.DeviceConnectionType:
        return device[DeviceAttributeNames.Type] + ' ' + value?.toString();
      case DeviceAttributeNames.DeviceModel:
        return this.getDeviceModel(device.deviceType, device.deviceVariant);
      default:
        return value ? this.conversionService.sliceUnits(value as string, precision, isUnitRequired) : '-';
    }
  }

  getOutputState(outputState: boolean | null | undefined): string {
    return outputState === true ? 'On' : outputState === false ? 'Off' : '-';
  }

  getDevicePort(value: string | null | undefined): string {
    if (!value) return '-';
    return value.split('_')[1];
  }

  getDeviceModel(deviceModal: string | null | undefined, deviceVarient: string | null | undefined): string {
    let value = deviceModal;
    if (!value) return '-';

    value =
      value === DeviceType.Htc910 || value === DeviceType.Htc920
        ? value.replace('htc', '')
        : value.toUpperCase().replace(digit_followed_by_I_regex, '$1i') +
          (deviceVarient != 'None' ? ' - ' + deviceVarient : '');
    return value;
  }

  getBridgeName(bridgeName: string | null | undefined, connection: string): string {
    if (connection === 'Direct' || !bridgeName) return '-';
    return bridgeName;
  }
}
