import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';

interface Ngc40slimAttributes {
  name: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'ngc40slim_actual_configs',
  bridge: 'ngc40bridges',
  desired_config: 'ngc40slim_desired_configs',
  device: 'devices',
  info: 'ngc40slim_infos',
  latest_actual_config: 'ngc40slim_actual_configs',
  latest_state: 'ngc40slim_latest_states',
  states: 'ngc40slim_states',
};

export interface Ngc40slim_latest_actual_config extends Base {
  id: string;
  attributes?: Ngc40slim_actual_configAttributes;
}

export interface Ngc40slim_actual_configAttributes {
  device_tag: string;
}

export interface Ngc40slim_infosAttribute {
  temperature_0_upper_bound: string;
  temperature_0_lower_bound: string;
}

export interface Ngc40slim_info {
  id: string;
  type: string;
  attributes?: Ngc40slim_infosAttribute;
}

export interface Ngc40slim_latest_state extends Base {
  id: string;
  attributes?: Ngc40slim_actual_configAttributes;
  relationships?: Ngc40slim_latest_stateRelationships;
}

export interface Ngc40slim_latest_stateAttributes {
  alarm_0_value?: boolean | null;
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  temperature_2_value?: string | null;
  temperature_2_value_error?: string | null;
}

interface Ngc40slim_latest_stateRelationships {
  slim?: { data: Ngc40slim };
}

export interface Ngc40slimRelationships {
  bridge?: { data: ResourceIdentifierObject };
  device?: { data: unknown };
  info?: { data: Ngc40slim_info };
  latest_actual_config?: { data: Ngc40slim_latest_actual_config };
  actual_configs?: { data: Ngc40slim_latest_actual_config[] };
  latest_state?: { data: Ngc40slim_latest_state };
  states?: { data: unknown[] };
}

export interface Ngc40slim extends Base {
  id: string;
  type: string;
  attributes: Ngc40slimAttributes;
  relationships?: Ngc40slimRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc40slimData {
  data: Ngc40slim;
  included?: IncludedRelationship[];
}

export interface Ngc40slimsData {
  data: Ngc40slim[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Ngc40slimService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40slims(params: HttpParams): Observable<Ngc40slimsData> {
    const url = `${this.uiBaseURL}/ngc40slims`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40slimsData>(url, options);
  }

  getNgc40slim(id: string, params: HttpParams): Observable<Ngc40slimData> {
    const url = `${this.uiBaseURL}/ngc40slims/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40slimData>(url, options);
  }

  addNgc40slim(Ngc40slim: Ngc40slim): Observable<Ngc40slim> {
    const url = `${this.uiBaseURL}/ngc40slims`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40slim>(url, { data: Ngc40slim }, options);
  }

  updateNgc40slim(Ngc40slim: Ngc40slim): Observable<Ngc40slimData> {
    const url = `${this.uiBaseURL}/ngc40slims/` + Ngc40slim.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40slimData>(url, { data: Ngc40slim }, options);
  }

  removeNgc40slim(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40slims/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getNgc40slimTrendData(params: HttpParams, id: string): Observable<Ngc40slimsData> {
    const url = `${this.uiBaseURL}/ngc40slims/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40slimsData>(url, options);
  }
}
