import { CardInfo, TrendDataUIModel } from '@app/models/device';
import { ChartSeriesData } from '@app/models/plotly-chart';
import {
  CurrentSeriesAttributes,
  PowerSeriesAttributes,
  TemperatureSeriesAttributes,
} from '@app/utils/enums/trend-chart-enums';
import { DeviceAttributeNames } from '@utils/enums/device-enums';

export const overviewContentCards: CardInfo[] = [
  {
    title: 'Device Info',
    attributes: [
      { field: DeviceAttributeNames.DeviceModel, header: 'Model' },
      { field: DeviceAttributeNames.DeviceConnectionType, header: 'Type' },
      { field: DeviceAttributeNames.BridgeName, header: 'Parent' },
      { field: DeviceAttributeNames.ArticleName, header: 'Port' },
      { field: DeviceAttributeNames.ModbusAddress, header: 'Modbus Address' },
      { field: DeviceAttributeNames.SerialNumber, header: 'Serial Number' },
      { field: DeviceAttributeNames.FirmwareVersion, header: 'Firmware Version' },
    ],
    class: 'device-info-attributes',
  },
  {
    title: 'Temperature Data',
    attributes: [
      { field: 'controlTemperature', header: 'Control Temp' },
      { field: 'setPoint', header: 'Set Point' },
      { field: DeviceAttributeNames.Temp1, header: 'Temp Source 1' },
      { field: DeviceAttributeNames.Temp2, header: 'Temp Source 2' },
      { field: DeviceAttributeNames.Temp3, header: 'Temp Source 3' },
      { field: DeviceAttributeNames.Temp4, header: 'Temp Source 4' },
    ],
    class: 'temperature-data-attributes',
  },
  {
    title: 'Electrical Data',
    attributes: [
      { field: 'lineCurrent', header: 'Line Current' },
      { field: 'groundFaultCurrent', header: 'Ground Fault' },
      { field: 'voltage', header: 'Voltage' },
      { field: 'power', header: 'Power' },
    ],
    class: 'electrical-data-attributes',
  },
];

export const eventDeviceRelationships: { [key: string]: string } = {
  htc910s: 'htc910',
  htc920s: 'htc920',
  ngc40htcs: 'htc',
  ngc40htc3s: 'htc3',
  cm2000s: 'cm2000',
  cm2000ps: 'cm2000p',
  ngc40slims: 'slim',
  ngc40ios: 'io',
  ngc20s: 'ngc20',
  ngc40bridges: 'ngc40bridge',
  ac2000ps: 'ac2000p',
  elexant4010s: '',
  elexant3500is: 'elexant3500i',
  elexant40x0s: 'elexant40x0',
  elexant5010is: 'elexant5010i',
  htc910: 'htc910',
  htc920: 'htc920',
  ngc40htc: 'htc',
  ngc40htc3: 'htc3',
  cm2000: 'cm2000',
  cm2000p: 'cm2000p',
  ngc40slim: 'slim',
  ngc40io: 'io',
  ngc20: 'ngc20',
  ngc40bridge: 'ngc40bridge',
  ac2000p: 'ac2000p',
  elexant3500i: 'elexant3500i',
  elexant40x0: 'elexant40x0',
  elexant5010i: 'elexant5010i',
  hosts: 'host',
};

export const temperatureSeriesAttributes: string[] = [
  TemperatureSeriesAttributes.controlTemperature,
  TemperatureSeriesAttributes.sensorTemperature1,
  TemperatureSeriesAttributes.sensorTemperature2,
  TemperatureSeriesAttributes.sensorTemperature3,
  TemperatureSeriesAttributes.sensorTemperature4,
  TemperatureSeriesAttributes.setPoint,
];

export const currentSeriesAttributes: string[] = [
  CurrentSeriesAttributes.lineCurrent,
  CurrentSeriesAttributes.groundFaultCurrent,
];

export const powerSeriesAttributes: string[] = [
  PowerSeriesAttributes.lineVoltage,
  PowerSeriesAttributes.power,
  PowerSeriesAttributes.outputState,
];

export const cm2000xPowerSeriesAttributes: string[] = [PowerSeriesAttributes.lineVoltage, PowerSeriesAttributes.power];

export const initialCurrentSeriesData: ChartSeriesData[] = [
  { name: CurrentSeriesAttributes.lineCurrent, x: [], y: [] },
  { name: CurrentSeriesAttributes.groundFaultCurrent, x: [], y: [] },
];

export const initialPowerSeriesData: ChartSeriesData[] = [
  { name: PowerSeriesAttributes.lineVoltage, x: [], y: [] },
  { name: PowerSeriesAttributes.power, x: [], y: [] },
  { name: PowerSeriesAttributes.outputState, x: [], y: [] },
];

export const initialCm2000xPowerSeriesData: ChartSeriesData[] = [
  { name: PowerSeriesAttributes.lineVoltage, x: [], y: [] },
  { name: PowerSeriesAttributes.power, x: [], y: [] },
];

export const defaultTrendData: TrendDataUIModel = {
  temperatureChart: [],
  powerChart: [],
  currentChart: [],
};

export const deviceTypes = [
  '4010i/4020i',
  'CM2000',
  'CM2000P',
  'HTC910',
  'HTC920',
  'NGC40HTC',
  'NGC40HTC3',
  'NGC40IO',
  'NGC40SLIM',
];

export const siteHierarchy = [
  'Plant',
  'Area',
  'Unit',
  'System',
  'Line Number',
  'Equipment',
  'Breaker Panel',
  'Control Panel',
];

export const defaultColumns = [
  DeviceAttributeNames.ControlTemp,
  DeviceAttributeNames.OutputState,
  DeviceAttributeNames.DeviceTag,
];

export const plainDeviceAttributeFields = [
  DeviceAttributeNames.AlarmCount,
  DeviceAttributeNames.OutputState,
  DeviceAttributeNames.DeviceTag,
  DeviceAttributeNames.FirmwareVersion,
];

export const numericalDevieAttributeFields = [
  DeviceAttributeNames.ControlTemp,
  DeviceAttributeNames.Voltage,
  DeviceAttributeNames.Temp1,
  DeviceAttributeNames.Temp2,
  DeviceAttributeNames.Temp3,
  DeviceAttributeNames.Temp4,
  DeviceAttributeNames.Power,
  DeviceAttributeNames.GroundFault,
  DeviceAttributeNames.LineCurrent,
  DeviceAttributeNames.AlarmCount,
];
