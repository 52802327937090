import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';
import { BehaviorSubject, throwError } from 'rxjs';

export interface Message {
  event: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  dataSource: BehaviorSubject<any>;
  ws!: WebSocket;
  wsConnected = false;
  cacheEventsURL: string;

  constructor(private appConfigService: AppConfigService) {
    this.cacheEventsURL = appConfigService.getCacheEventsURL();
    this.dataSource = new BehaviorSubject({});
  }

  initializeWebSocket(devicesRequired: boolean): void {
    this.ws = new WebSocket(this.cacheEventsURL);
    this.websocketEvents(devicesRequired);
  }

  websocketEvents(devicesRequired: boolean): void {
    this.ws.onopen = () => {
      this.wsConnected = true;
      if (devicesRequired) {
        this.getDevicesData();
      }
      this.getAlarmsData();
      this.ws.onmessage = (event: any) => {
        if (event.data) {
          const eventData = JSON.parse(event.data);
          eventData.data
            ? this.dataSource.next(eventData)
            : this.dataSource.error(throwError(() => new Error(eventData.error)));
        }
      };
    };
    this.ws.onerror = () => {
      this.dataSource.error({});
      this.wsConnected = false;
    };
    this.ws.onclose = () => (this.wsConnected = false);
  }

  sendMessage(message: Message): void {
    if (this.wsConnected && this.ws.readyState === WebSocket.OPEN) this.ws.send(JSON.stringify(message));
  }

  getDevicesData(): void {
    this.sendMessage({
      event: 'getDevicesData',
    });
  }

  getBridgesData(): void {
    this.sendMessage({
      event: 'getBridgesData',
    });
  }

  getAlarmsData(): void {
    this.sendMessage({
      event: 'getAlarmsData',
    });
  }
}
