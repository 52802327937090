import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Group } from '@models/group-option';
import { GroupTypes } from '@utils/enums/device-enums';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.css',
})
export class AccordionComponent {
  @Input({ required: true }) menus: Group[] = [];
  @Input({ required: true }) selectedGroup: GroupTypes = GroupTypes.Parent;
  @Output() onGroupSelect = new EventEmitter<GroupTypes>();

  toggle(index: number) {
    this.menus[index].active = !this.menus[index].active;
  }

  onSelect(value: string, menuIndex: number) {
    this.menus = this.menus.map((menu, menuI) => {
      menu.active = menuI == menuIndex;
      return menu;
    });
    this.onGroupSelect.emit(value as GroupTypes);
  }
}
