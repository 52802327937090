import { Injectable } from '@angular/core';
import { Seconds } from '@app/utils/enums/time-enum';
import {
  energy_consumption_regex,
  line_current_regex,
  temp_max_regex,
  temp_min_regex,
  trace_current_regex,
  units_pattern,
} from '@app/utils/patterns';
import { DeviceUIModel } from '@models/device';
import { TableContents } from '@models/table-items';
import { DeviceType } from '@utils/enums/device-enums';
import { MeasurementUnits, Precision, TemperatureUnits } from '@utils/enums/unit-enums';

@Injectable({
  providedIn: 'root',
})
export class ConversionService {
  preferenceUnit: string = TemperatureUnits.Celsius;
  timeDiff: number = 0;
  sliceUnits(data: string | null | undefined, decimalPlaces?: number, isUnitRequired?: boolean): string {
    const value = this.removeUnit(data);
    if (value === '') return 'NA';
    if (isUnitRequired) {
      const unit = data ? data.substring(value.length) : '';
      const result = Number(value).toFixed(decimalPlaces);
      return result == 'NaN' ? 'NA' : result + unit;
    }
    return Number(value).toFixed(decimalPlaces);
  }

  setPrecision(data: string) {
    const value = this.removeUnit(data);
    const unit = data ? data.substring(value.length) : '';
    switch (unit) {
      case MeasurementUnits.Ohm:
      case MeasurementUnits.Celsius:
      case MeasurementUnits.Fahrenheit:
      case MeasurementUnits.DeltaCelsius:
      case MeasurementUnits.DeltaFahrenheit:
        return this.sliceUnits(value, Precision.Two);
      case MeasurementUnits.Ampere:
        return this.sliceUnits(value, Precision.One);
      default:
        return this.sliceUnits(value);
    }
  }

  removeUnit(data: string | null | undefined): string {
    return data ? data.replace(units_pattern, '') : '';
  }

  getDigitFromValue(data: string | null | undefined): number {
    return Number(this.removeUnit(data));
  }

  unitConversion(data: string | null | undefined): string | null | undefined {
    if (data === 'None' || data === null || data === undefined) return data;
    const value = this.temperatureUnitConversion(this.getDigitFromValue(data));
    return this.preferenceUnit === TemperatureUnits.Celsius
      ? value + MeasurementUnits.Celsius
      : value + MeasurementUnits.Fahrenheit;
  }

  temperatureUnitConversion(data: number | null, unit?: string, fromConfig?: boolean): string {
    const value =
      unit === TemperatureUnits.Celsius || this.preferenceUnit === TemperatureUnits.Celsius
        ? ((Number(data) - 32) * 5) / 9
        : (Number(data) * 9) / 5 + 32;
    const convertedValue = fromConfig ? this.sliceUnits(value.toString(), Precision.Two) : Math.round(value).toString();
    return convertedValue;
  }

  deltaTemperatureUnitConversion(data: number | null, unit?: string, fromConfig?: boolean): string {
    const value =
      unit === TemperatureUnits.Celsius || this.preferenceUnit === TemperatureUnits.Celsius
        ? (Number(data) * 5) / 9
        : (Number(data) * 9) / 5;
    const convertedValue = fromConfig ? this.sliceUnits(value.toString(), Precision.Two) : Math.round(value).toString();
    return convertedValue;
  }

  // isHourToDate is used to convert timestamp to `15:44, Apr 26, 2024` this time format
  convertDateTimeStringToDate(
    dateTimeString?: string,
    fullTimestamp: boolean = true,
    isHourToDate: boolean = false,
  ): string {
    const date = new Date(String(dateTimeString));
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let formattedDate = !isHourToDate
      ? `${month} ${day.toString().padStart(2, '0')}, ${year}`
      : `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}, ${month} ${day
          .toString()
          .padStart(2, '0')}, ${year}`;
    if (fullTimestamp)
      formattedDate = `${formattedDate} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedDate;
  }

  calculateTimeDiffInStandardFormat(date: string): string {
    this.timeDiff = Math.floor((new Date(date.toString()).getTime() - new Date().getTime()) / 1000);
    const days: number = Math.floor(this.timeDiff / Seconds.DAY);
    const hours: number = Math.floor((this.timeDiff - days * Seconds.DAY) / Seconds.HOUR);
    const minutes: number = Math.floor((this.timeDiff - days * Seconds.DAY - hours * Seconds.HOUR) / Seconds.MIN);
    const seconds: number = this.timeDiff - days * Seconds.DAY - hours * Seconds.HOUR - minutes * Seconds.MIN;
    const defaultTime = '00:00:00';

    return this.timeDiff === undefined || this.timeDiff <= 0
      ? defaultTime
      : `${days ? days + 'd' : ''} ${hours ? ('00' + hours).slice(-2) + 'h' : ''} ${
          minutes ? ('00' + minutes).slice(-2) + 'm' : ''
        } ${seconds ? ('00' + seconds).slice(-2) + 's' : ''}`.trim();
  }

  getDevicesDataOnPreferenceChange(devices: DeviceUIModel[]): DeviceUIModel[] {
    devices = devices.map((device: DeviceUIModel) => {
      if (this.isTrendOrConfigDataPresent(device)) {
        device.sensorTemperature1 = this.unitConversion(device.sensorTemperature1);
        device.sensorTemperature2 = this.unitConversion(device.sensorTemperature2);

        switch (device.deviceType) {
          case DeviceType.Ngc40io: {
            device.sensorTemperature3 = this.unitConversion(device.sensorTemperature3);
            device.sensorTemperature4 = this.unitConversion(device.sensorTemperature4);
            break;
          }
          case DeviceType.Ngc40slim: {
            device.sensorTemperature3 = this.unitConversion(device.sensorTemperature3);
            break;
          }
          default: {
            device.controlTemperature = this.unitConversion(device.controlTemperature);
            device.setPoint = this.unitConversion(device.setPoint);
            break;
          }
        }
      }
      return device;
    });
    return devices;
  }

  getFormattedTracingControlStatus(data: TableContents) {
    return data.deviceData?.tracingControlStatus !== undefined
      ? '(' + data.deviceData.tracingControlStatus!.replaceAll('_', ' ') + ')'
      : '';
  }

  isTrendOrConfigDataPresent(device: DeviceUIModel): boolean {
    const sensorTemperature1InCelsius = device.sensorTemperature1?.includes(MeasurementUnits.Celsius);
    const sensorTemperature1InFarenheit = device.sensorTemperature1?.includes(MeasurementUnits.Fahrenheit);
    const setPointInCelsius = device.setPoint?.includes(MeasurementUnits.Celsius);
    const setPointInFarenheit = device.setPoint?.includes(MeasurementUnits.Fahrenheit);
    const preferenceInCelsius = this.preferenceUnit === TemperatureUnits.Celsius;
    const preferenceInFarenheit = this.preferenceUnit === TemperatureUnits.Fahrenheit;

    return ((sensorTemperature1InCelsius || setPointInCelsius) && preferenceInFarenheit) ||
      ((sensorTemperature1InFarenheit || setPointInFarenheit) && preferenceInCelsius)
      ? true
      : false;
  }

  setTemperature(temperature: string): string | null | undefined {
    return this.preferenceUnit === TemperatureUnits.Fahrenheit
      ? this.unitConversion(temperature)
      : this.sliceUnits(temperature, Precision.Zero, true);
  }

  convertStringToBoolean(state: string): boolean | null {
    return state === 'True' ? true : state === 'False' ? false : !!Number(state);
  }

  removeExtraCharactersFromUnit(unit: string): string {
    return unit.replace(/(<|>|_)/, '');
  }

  convertDateToTimestampWithTimezone(date: Date): string {
    return date.toISOString().replace('T', ' ').replace('Z', '+00:00');
  }

  addSecondsToDate(date: Date, seconds: number): Date {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
  }

  separateNumericalandUnit(attr: any) {
    const attrStr = String(attr);
    let responseArray: string[] = [];
    let digit = String(this.getDigitFromValue(attrStr));
    digit = digit === 'NaN' ? '-' : digit;
    const regAr = attrStr.match(new RegExp(units_pattern, 'g'));
    let unit: string = '';
    if (regAr) {
      unit = regAr[0];
    }
    responseArray.push(String(digit));
    responseArray.push(unit);
    return responseArray;
  }

  matchAttribute(attribute: string, pattern: RegExp) {
    const match: RegExpMatchArray | null = attribute.match(pattern);
    if (match != null) {
      let num1 = parseInt(match[1]);
      return (num1 = num1 + 1);
    }
    return '';
  }

  maintenanceAttribute(attribute: string) {
    if (attribute.match(temp_max_regex)) {
      let index = this.matchAttribute(attribute, temp_max_regex);
      return 'TS ' + index + ' Temperature Max';
    }
    if (attribute.match(temp_min_regex)) {
      let index = this.matchAttribute(attribute, temp_min_regex);
      return 'TS ' + index + ' Temperature Min';
    }
    if (attribute.match(energy_consumption_regex)) {
      let index = this.matchAttribute(attribute, energy_consumption_regex);
      return 'Power Accumulator ' + index;
    }
    if (attribute.match(trace_current_regex)) {
      let index = this.matchAttribute(attribute, trace_current_regex);
      return 'Highest Instantaneous Trace Current Ever Measured ' + index;
    }
    if (attribute.match(line_current_regex)) {
      let index = this.matchAttribute(attribute, line_current_regex);
      return 'Highest Instantaneous Line Current Ever Measured ' + index;
    }
    return '';
  }

  appendUnit(data: string, unit: string) {
    return data.concat(unit);
  }

  setPreferenceUnit(preferenceUnit: string) {
    this.preferenceUnit = preferenceUnit;
  }
}
