import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';
import { Ngc40io_latest_state } from '@services/jsonapi-services/ngc40io_latest_state.service';

interface Ngc40ioAttributes {
  name: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'ngc40io_actual_configs',
  bridge: 'ngc40bridges',
  desired_config: 'ngc40io_desired_configs',
  device: 'devices',
  info: 'ngc40io_infos',
  latest_actual_config: 'ngc40io_actual_configs',
  latest_state: 'ngc40io_latest_states',
  states: 'ngc40io_states',
};

export interface Ngc40io_latest_actual_config extends Base {
  id: string;
  attributes?: Ngc40io_actual_configAttributes;
}

export interface Ngc40io_actual_configAttributes {
  device_tag: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
  temperature_2_high_alarm_setpoint?: string;
  temperature_2_low_alarm_setpoint?: string;
  temperature_3_high_alarm_setpoint?: string;
  temperature_3_low_alarm_setpoint?: string;
}

export interface Ngc40ioRelationships {
  bridge?: { data: ResourceIdentifierObject };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Ngc40io_latest_actual_config };
  actual_configs?: { data: Ngc40io_latest_actual_config[] };
  latest_state?: { data: Ngc40io_latest_state };
  states?: { data: unknown[] };
}

export interface Ngc40io extends Base {
  id: string;
  type: string;
  attributes: Ngc40ioAttributes;
  relationships?: Ngc40ioRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc40ioData {
  data: Ngc40io;
  included?: IncludedRelationship[];
}

export interface Ngc40iosData {
  data: Ngc40io[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Ngc40ioService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40ios(params: HttpParams): Observable<Ngc40iosData> {
    const url = `${this.uiBaseURL}/ngc40ios`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40iosData>(url, options);
  }

  getNgc40io(id: string, params: HttpParams): Observable<Ngc40ioData> {
    const url = `${this.uiBaseURL}/ngc40ios/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40ioData>(url, options);
  }

  addNgc40io(Ngc40io: Ngc40io): Observable<Ngc40io> {
    const url = `${this.uiBaseURL}/ngc40ios`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40io>(url, { data: Ngc40io }, options);
  }

  updateNgc40io(Ngc40io: Ngc40io): Observable<Ngc40ioData> {
    const url = `${this.uiBaseURL}/ngc40ios/` + Ngc40io.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40ioData>(url, { data: Ngc40io }, options);
  }

  removeNgc40io(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40ios/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getNgc40ioTrendData(params: HttpParams, id: string): Observable<Ngc40iosData> {
    const url = `${this.uiBaseURL}/ngc40ios/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40iosData>(url, options);
  }
}
