import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from '@app/services/keycloak.service';
import { environment } from '@env/environment';
import { AuthService } from '@services/auth.service';
import { UserActivityService } from '@services/user-activity.service';
import { firstValueFrom, tap } from 'rxjs';

export interface AppConfig {
  idleUserTimeout: number;
  serverName: string;
}

export interface ProviderConfig {
  clientId?: string;
  authorizationEndpoint?: string;
  endSessionEndpoint?: string;
  tokenEndpoint?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  config: AppConfig = { serverName: '', idleUserTimeout: 0 };
  serverName: string = '';
  isProd = false;
  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService,
    private auth: AuthService,
    private userActivity: UserActivityService,
  ) {
    this.isProd = environment.production;
  }

  async loadConfig() {
    if (environment.e2e == true) {
      await firstValueFrom(
        this.auth.fetchIdToken().pipe(
          tap(token => {
            this.serverName = environment.serverName;
            this.auth.processIdTokenResponse(token);
          }),
        ),
      );
    } else {
      await firstValueFrom(
        this.http.get<AppConfig>('./assets/app.config.json').pipe(
          tap(data => {
            this.config = data!;
            this.serverName = this.isProd ? data.serverName! : environment.serverName;
            if (data.idleUserTimeout && data.idleUserTimeout >= 10)
              this.userActivity.setIdleTimer(data.idleUserTimeout);
          }),
        ),
      );
      await this.keycloak.initializeKeycloak(this.serverName);
    }
  }

  getJsonApiUrl(): string {
    return `https://${this.serverName}/jsonapi/v1`;
  }

  getJsonApiEventsUrl(): string {
    return `wss://${this.serverName}/jsonapi/v1/event-socket/`;
  }

  getCacheEventsURL(): string {
    return `wss://${this.serverName}/ws/`;
  }

  getKeycloakURL(): string {
    return this.keycloak.getKeycloakUrl();
  }
}
