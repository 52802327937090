import { Component, DestroyRef, Inject, inject, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserService } from '@app/services/jsonapi-services/user.service';
import { KeycloakService } from '@app/services/keycloak.service';
import { UserMapperService } from '@app/services/mapper-services/user-mapper.service';
import { environment } from '@env/environment';
import { IdTokenPayload } from '@models/id-token';
import { AuthService } from '@services/auth.service';
import { RoleService } from '@services/jsonapi-services/role.service';
import { UserManagementService } from '@services/user-management.service';
import { userParam, userPermissionsParams } from '@utils/constants/params';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent {
  authId!: string;
  userId!: string;
  idProviderId!: string;
  destroyRef = inject(DestroyRef);

  constructor(
    @Inject(Router) private router: Router,
    private userService: UserService,
    private userMapperService: UserMapperService,
    private auth: AuthService,
    private keycloakService: KeycloakService,
    private ngZone: NgZone,
    private roleService: RoleService,
    private userManagementService: UserManagementService,
  ) {}

  redirectToLogin() {
    this.keycloakService.signOut();
  }

  onAccept() {
    const token = environment.e2e ? this.auth.parseIdToken() : this.keycloakService.getToken();
    this.userService
      .getMulti(userParam(token))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (response.data.length === 0) {
            this.handleNewUser(token);
          } else {
            this.handleExistingUser(token);
          }
        },
      });
  }

  private handleNewUser(token: IdTokenPayload) {
    this.roleService
      .getMulti(userPermissionsParams(token.role!))
      .pipe(
        mergeMap(response => {
          const roleId = response.data[0].id;
          return this.userManagementService.addNewUserToJsonApi(token.sub, roleId, true);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        void this.ngZone.run(() => {
          void this.router.navigateByUrl('/dashboard');
        });
      });
  }

  private handleExistingUser(token: IdTokenPayload) {
    this.userService
      .getMulti(userParam(token))
      .pipe(
        mergeMap(userData => {
          this.userId = userData.data[0].id!;
          let userDataToBePosted = this.userMapperService.mapToBackEnd({
            eula_accepted: true,
          });
          userDataToBePosted = { ...userDataToBePosted, id: this.userId };
          return this.userService.update(userDataToBePosted);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        void this.ngZone.run(() => {
          void this.router.navigateByUrl('/dashboard');
        });
      });
  }
}
