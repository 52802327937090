<div class="terms-approval-bg">
  <div class="approval-card">
    <img src="./assets/images/login-sidebar.png" />
    <div class="card-rightbox">
      <p>
        Thank you for submitting your registration request. Your local systems administrator will notify you upon
        acceptance of your submission.
      </p>
      <button class="redirect-btn" (click)="redirectToLogin()">« Back to Login</button>
    </div>
  </div>
</div>
