import { Component } from '@angular/core';
import { KeycloakService } from '@app/services/keycloak.service';

@Component({
  selector: 'app-wait-for-approval',
  templateUrl: './wait-for-approval.component.html',
  styleUrls: ['./wait-for-approval.component.css'],
})
export class WaitForApprovalComponent {
  constructor(private keycloakService: KeycloakService) {}

  redirectToLogin() {
    this.keycloakService.signOut();
  }
}
