import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';

export interface PreferenceAttrs {
  unit?: string;
}

export interface PreferenceRels {
  user: { data: ResourceIdentifierObject };
}

export interface Preference {
  type: string;
  id?: string;
  attributes?: PreferenceAttrs;
  relationships?: PreferenceRels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface PreferenceData {
  data: Preference;
  included?: ResourceIdentifierObject[];
}

export interface MultiPreferenceData {
  data: Preference[];
  included?: ResourceIdentifierObject[];
}
@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  get(id: string, params: HttpParams): Observable<PreferenceData> {
    const url = `${this.uiBaseURL}/preferences/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<PreferenceData>(url, options);
  }

  addWithoutId(preference: Preference): Observable<Preference> {
    const url = `${this.uiBaseURL}/preferences`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Preference>(url, { data: preference }, options);
  }

  update(preference: Preference): Observable<PreferenceData> {
    const url = `${this.uiBaseURL}/preferences/${String(preference.id)}`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<PreferenceData>(url, { data: preference }, options);
  }
}
