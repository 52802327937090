import { Injectable } from '@angular/core';
import { TrendDataUIModel } from '@app/models/device';
import { ChartSeriesData } from '@app/models/plotly-chart';
import { Duration, DurationRange } from '@app/models/select-durations';
import { TrendSelectionAttribute, TrendSelectionCategories } from '@app/models/trend-selection';
import { DeviceTypeTrends } from '@app/utils/enums/device-enums';
import { PowerSeriesAttributes, TemperatureSeriesAttributes } from '@app/utils/enums/trend-chart-enums';

@Injectable({
  providedIn: 'root',
})
export class TrendsFilterService {
  private selectionMap = new Map<string, TrendSelectionCategories>();

  constructor() {
    this.setSelectionMap();
  }

  setTrendMap(deviceType: string, data: TrendSelectionCategories) {
    this.selectionMap.set(deviceType, data);
  }

  updateTrendMap(deviceType: string): void {
    const data = this.selectionMap.get(deviceType);
    this.selectionMap.forEach((value, mapKey) => {
      if (mapKey !== deviceType) {
        Object.keys(value).forEach((objKey: string) => {
          if (
            data![objKey as keyof TrendSelectionCategories] !== undefined &&
            data![objKey as keyof TrendSelectionCategories] !== undefined
          )
            value[objKey as keyof TrendSelectionCategories]!.forEach(plot => {
              plot.plotted = data![objKey as keyof TrendSelectionCategories]!.find(lot => lot.name === plot.name)
                ? (data![objKey as keyof TrendSelectionCategories]!.find(lot => lot.name === plot.name)
                    ?.plotted as boolean)
                : plot.plotted;
            });
        });
      }
    });
  }

  getDurationDates(duration: Duration | DurationRange): Date[] {
    if ((duration as DurationRange).start !== undefined) {
      const durationRange = duration as DurationRange;
      return [durationRange.start!, durationRange.end!];
    }
    switch (duration) {
      case Duration.oneDay:
        return [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()];
      case Duration.threeDay:
        return [new Date(new Date().setDate(new Date().getDate() - 3)), new Date()];
      case Duration.week:
        return [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()];
      case Duration.month:
        return [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()];
      case Duration.year:
        return [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()];
      default:
        return [];
    }
  }

  getChartDataByPlottedAttribute(
    chartSeriesData: ChartSeriesData[],
    trendSelectionAttributes: TrendSelectionAttribute[],
  ): ChartSeriesData[] {
    if (trendSelectionAttributes && chartSeriesData) {
      const plottedAttributes = trendSelectionAttributes
        .filter(attribute => attribute.plotted === true)
        .map(item => {
          return item.name;
        });
      chartSeriesData.forEach(data => {
        if (plottedAttributes.includes(data.name)) {
          data.visible = true;
        } else {
          data.visible = false;
        }
      });
    }
    return chartSeriesData;
  }

  getPlot1TemperatureCategories = (): TrendSelectionAttribute[] => {
    return [
      {
        name: 'Control Temp',
        plotted: true,
      },
      {
        name: 'Sensor Temp 1',
        plotted: true,
      },
      {
        name: 'Sensor Temp 2',
        plotted: true,
      },
      {
        name: 'Sensor Temp 3',
        plotted: true,
      },
      {
        name: 'Sensor Temp 4',
        plotted: true,
      },
      {
        name: 'Set Point',
        plotted: true,
      },
    ];
  };

  getPlot2Categories = (): TrendSelectionAttribute[] => {
    return [
      {
        name: 'Line Current',
        plotted: true,
      },
      {
        name: 'Ground Fault',
        plotted: true,
      },
    ];
  };

  getPlot3Categories = (): TrendSelectionAttribute[] => {
    return [
      {
        name: 'Line Voltage',
        plotted: true,
      },
      {
        name: 'Power',
        plotted: true,
      },
      {
        name: 'Output State',
        plotted: true,
      },
    ];
  };

  getDefaultTrendSelection = (deviceType: string): TrendSelectionCategories => {
    return this.selectionMap.get(deviceType) as TrendSelectionCategories;
  };

  private setSelectionMap() {
    const defaultPlot1Categories = this.getPlot1TemperatureCategories();
    const defaultPlot2Categories = this.getPlot2Categories();
    const defaultPlot3Categories = this.getPlot3Categories();
    Object.values(DeviceTypeTrends).forEach(deviceType => {
      switch (deviceType) {
        case DeviceTypeTrends.Ngc40io:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature3)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature4)!,
            ],
            plot2Categories: [],
            plot3Categories: [],
          });
          break;
        case DeviceTypeTrends.Ngc40slim:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature3)!,
            ],
            plot2Categories: [],
            plot3Categories: [],
          });
          break;
        case DeviceTypeTrends.Cm2000:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.controlTemperature)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.setPoint)!,
            ],
            plot2Categories: defaultPlot2Categories,
            plot3Categories: defaultPlot3Categories.filter(
              category => category.name !== PowerSeriesAttributes.outputState,
            ),
          });
          break;
        case DeviceTypeTrends.Cm2000p:
        case DeviceTypeTrends.Elexant40X0:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.controlTemperature)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.setPoint)!,
            ],
            plot2Categories: defaultPlot2Categories,
            plot3Categories: defaultPlot3Categories.filter(
              category => category.name !== PowerSeriesAttributes.outputState,
            ),
          });
          break;
        case DeviceTypeTrends.Elexant3500i:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.controlTemperature)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.setPoint)!,
            ],
            plot2Categories: defaultPlot2Categories,
            plot3Categories: [],
          });
          break;
        default:
          this.selectionMap.set(deviceType, {
            plot1Categories: [
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.controlTemperature)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature1)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.sensorTemperature2)!,
              defaultPlot1Categories.find(data => data.name === TemperatureSeriesAttributes.setPoint)!,
            ],
            plot2Categories: defaultPlot2Categories,
            plot3Categories: defaultPlot3Categories,
          });
          break;
      }
    });
  }

  getTemperatureData(
    seriesData: TrendDataUIModel,
    duration: Duration | DurationRange,
    trendSelection: TrendSelectionAttribute[],
  ) {
    const chartData = this.getChartDataByPlottedAttribute(seriesData.temperatureChart!, trendSelection);
    const dateRange = this.getDurationDates(duration);
    return [chartData, dateRange];
  }

  getCurrentData(
    seriesData: TrendDataUIModel,
    duration: Duration | DurationRange,
    trendSelection: TrendSelectionAttribute[],
  ) {
    const chartData = this.getChartDataByPlottedAttribute(seriesData.currentChart!, trendSelection);
    const dateRange = this.getDurationDates(duration);
    return [chartData, dateRange];
  }

  getPowerData(
    seriesData: TrendDataUIModel,
    duration: Duration | DurationRange,
    trendSelection: TrendSelectionAttribute[],
  ) {
    const chartData = this.getChartDataByPlottedAttribute(seriesData.powerChart!, trendSelection);
    const dateRange = this.getDurationDates(duration);
    return [chartData, dateRange];
  }
}
