import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakGroup } from '@app/models/keycloak';
import { AppConfigService } from '@services/app-config.service';
import { KeycloakService } from '@services/keycloak.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeycloakGroupService {
  private url = '';

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    appConfigService: AppConfigService,
  ) {
    this.url = appConfigService.getKeycloakURL();
  }

  getAllGroups(): Observable<KeycloakGroup[]> {
    const path = this.url + `/groups`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.get<KeycloakGroup[]>(path, options);
  }

  updateUserGroup(userId: string, groupId: string) {
    const url = this.url + `/users/${userId}/groups/${groupId}`;
    const requestBody = {
      realm: 'RES',
      userId: userId,
      groupId: groupId,
    };
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.put<any>(url, requestBody, options);
  }

  removeUserGroup(userId: string, groupId: string | null | undefined): Observable<never> {
    const url = this.url + `/users/${userId}/groups/${groupId}`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
