import { Injectable } from '@angular/core';
import { defaultPlotlyChartData, defaultPlotlyChartLayout } from '@app/utils/default-chart-options';
import {
  ChartColor,
  CurrentSeriesAttributes,
  PowerSeriesAttributes,
  TemperatureChartLabels,
  TemperatureSeriesAttributes,
} from '@app/utils/enums/trend-chart-enums';
import { ChartSeriesData, PlotlyChartData, PlotlyChartLayout } from '@models/plotly-chart';
import { TrendSelectionAttribute } from '@models/trend-selection';
import { DeviceType } from '@utils/enums/device-enums';
import { MeasurementUnits, UnitName } from '@utils/enums/unit-enums';
import { cloneDeep } from 'lodash-es';

export const temperatureYAxisLabel = 'Temperature';

@Injectable({
  providedIn: 'root',
})
export class LineChartOptionsService {
  constructor() {}
  offsetValue = 10;
  private temperatureAttributeList: string[] = [
    TemperatureSeriesAttributes.controlTemperature,
    TemperatureSeriesAttributes.sensorTemperature1,
    TemperatureSeriesAttributes.sensorTemperature2,
    TemperatureSeriesAttributes.sensorTemperature3,
    TemperatureSeriesAttributes.sensorTemperature4,
    TemperatureSeriesAttributes.setPoint,
  ];

  getTemperatureAttributeList(): string[] {
    return this.temperatureAttributeList;
  }

  getPlotlyChartSeries(
    chartDataPoints: ChartSeriesData,
    chartType: string,
    chartMode: string,
    index: number,
  ): PlotlyChartData {
    let chartData = cloneDeep(defaultPlotlyChartData);
    chartData.name = chartDataPoints.name;
    chartData.type = chartType;
    chartData.mode = chartMode;
    chartData.line.color = getColorForChart(chartDataPoints.name);
    chartData.visible = chartDataPoints.visible ? chartDataPoints.visible : false;
    if (index >= 1 && !this.temperatureAttributeList.includes(chartDataPoints.name)) chartData.yaxis = `y${index + 1}`;
    chartData.x = cloneDeep(chartDataPoints.x) as Date[];
    chartData.y = cloneDeep(chartDataPoints.y) as number[];
    return chartData;
  }

  getPlotlyChartLayout(
    title: string,
    duration: Date[],
    preferenceUnit?: string,
    deviceType?: string,
    trendSelectionAttributes?: TrendSelectionAttribute[],
  ): PlotlyChartLayout {
    let result: PlotlyChartLayout = cloneDeep(defaultPlotlyChartLayout);
    result.title = title;
    result.autosize = true;
    result.hovermode = 'x unified';
    result.xaxis = {
      range: duration,
      type: 'timestamp',
      title: 'Timestamp',
      zeroline: false,
    };
    switch (title) {
      case 'Temperature Charts':
        result.yaxis = {
          title: `${TemperatureChartLabels.temperature}(${
            preferenceUnit === UnitName.Celsius ? MeasurementUnits.Celsius : MeasurementUnits.Fahrenheit
          })`,
          zeroline: false,
          fixedrange: true,
          visible: trendSelectionAttributes?.find(attribute => attribute.plotted) ? true : false,
        };
        break;
      case 'Current Charts':
        result.yaxis = {
          title: `${CurrentSeriesAttributes.lineCurrent}(${MeasurementUnits.Ampere})`,
          zeroline: false,
          fixedrange: true,
          color: getColorForChart(CurrentSeriesAttributes.lineCurrent),
          visible:
            trendSelectionAttributes?.find(attribute => attribute.name === CurrentSeriesAttributes.lineCurrent)
              ?.plotted ?? false,
        };
        result.yaxis2 = {
          title: `${CurrentSeriesAttributes.groundFaultCurrent}(${MeasurementUnits.MilliAmpere})`,
          anchor: 'x',
          overlaying: 'y',
          side: 'right',
          position: 0,
          zeroline: false,
          fixedrange: true,
          color: getColorForChart(CurrentSeriesAttributes.groundFaultCurrent),
          visible:
            trendSelectionAttributes?.find(attribute => attribute.name === CurrentSeriesAttributes.groundFaultCurrent)
              ?.plotted ?? false,
        };
        break;
      default:
        result.xaxis.domain = [0, 0.9];
        result.yaxis = {
          title: `${PowerSeriesAttributes.lineVoltage}(${MeasurementUnits.Voltage})`,
          zeroline: false,
          fixedrange: true,
          color: getColorForChart(PowerSeriesAttributes.lineVoltage),
          visible:
            trendSelectionAttributes?.find(attribute => attribute.name === PowerSeriesAttributes.lineVoltage)
              ?.plotted ?? false,
        };
        result.yaxis2 = {
          title: `${PowerSeriesAttributes.power}(${MeasurementUnits.Watt})`,
          anchor: 'x',
          overlaying: 'y',
          side: 'right',
          zeroline: false,
          fixedrange: true,
          color: getColorForChart(PowerSeriesAttributes.power),
          visible:
            trendSelectionAttributes?.find(attribute => attribute.name === PowerSeriesAttributes.power)?.plotted ??
            false,
        };
        // Temporary fix to handle output state for Elexant40x0 devices coming in string form
        if (deviceType !== DeviceType.Elexant40X0i) {
          result.yaxis3 = {
            title: PowerSeriesAttributes.outputState,
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 0.98,
            range: [-0.1, 1.1],
            type: 'category',
            categoryorder: 'array',
            categoryarray: [0, 1],
            labelalias: {
              0: 'Off',
              1: 'On',
            },
            zeroline: false,
            fixedrange: true,
            color: getColorForChart(PowerSeriesAttributes.outputState),
            visible:
              trendSelectionAttributes?.find(attribute => attribute.name === PowerSeriesAttributes.outputState)
                ?.plotted ?? false,
          };
        }
        break;
    }
    result.plot_bgcolor = '#f7f7f7cc';
    result.legend = {
      x: 0.5,
      y: 1,
      orientation: 'h',
      yanchor: 'bottom',
      xanchor: 'center',
    };
    return result;
  }
}

export const getColorForChart = (attributeName: string): string => {
  switch (attributeName) {
    case TemperatureSeriesAttributes.controlTemperature:
      return ChartColor.controlTemperature;
    case TemperatureSeriesAttributes.sensorTemperature1:
      return ChartColor.sensorTemperature1;
    case TemperatureSeriesAttributes.sensorTemperature2:
      return ChartColor.sensorTemperature2;
    case TemperatureSeriesAttributes.sensorTemperature3:
      return ChartColor.sensorTemperature3;
    case TemperatureSeriesAttributes.sensorTemperature4:
      return ChartColor.sensorTemperature4;
    case TemperatureSeriesAttributes.setPoint:
      return ChartColor.setPoint;
    case CurrentSeriesAttributes.groundFaultCurrent:
      return ChartColor.groundFaultCurrent;
    case CurrentSeriesAttributes.lineCurrent:
      return ChartColor.lineCurrent;
    case PowerSeriesAttributes.lineVoltage:
      return ChartColor.lineVoltage;
    case PowerSeriesAttributes.outputState:
      return ChartColor.outputState;
    case PowerSeriesAttributes.power:
      return ChartColor.power;
    default:
      return ChartColor.default;
  }
};
