import { Injectable } from '@angular/core';
import { temperatureSeriesAttributes } from '@app/utils/constants/device-constants';
import { TemperatureSeriesAttributes } from '@app/utils/enums/trend-chart-enums';
import { TemperatureUnits } from '@app/utils/enums/unit-enums';
import { DeviceUIModel, IncludedRelationship, TrendDataUIModel } from '@models/device';
import { ConversionService } from '@services/conversion.service';
import { Event } from '@services/event.service';
import { Ngc40io } from '@services/jsonapi-services/ngc40io.service';
import { Ngc40io_latest_stateAttributes } from '@services/jsonapi-services/ngc40io_latest_state.service';
import { getChartSeriesData } from '@utils/constants/device-trend-constants';
import { DeviceType } from '@utils/enums/device-enums';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class Ngc40ioDeviceDataMapperService {
  constructor(private conversionService: ConversionService) {}

  mapNgc40IoDeviceLatestState(
    device: DeviceUIModel,
    deviceLatestStateData: Ngc40io_latest_stateAttributes,
  ): DeviceUIModel {
    device.alarmState = deviceLatestStateData.alarm_0_value || false;
    device.digitalInput = deviceLatestStateData.digital_input_0_value || null;
    device.sensorTemperature1 =
      this.conversionService.setTemperature(deviceLatestStateData.temperature_0_value!) || null;
    device.sensorTemperature1Error = deviceLatestStateData?.temperature_0_value_error;
    device.sensorTemperature2 =
      this.conversionService.setTemperature(deviceLatestStateData.temperature_1_value!) || null;
    device.sensorTemperature2Error = deviceLatestStateData?.temperature_1_value_error;
    device.sensorTemperature3 =
      this.conversionService.setTemperature(deviceLatestStateData.temperature_2_value!) || null;
    device.sensorTemperature3Error = deviceLatestStateData?.temperature_2_value_error;
    device.sensorTemperature4 =
      this.conversionService.setTemperature(deviceLatestStateData.temperature_3_value!) || null;
    device.sensorTemperature4Error = deviceLatestStateData?.temperature_3_value_error;
    return device;
  }

  mapNgc40IoTrendData(device: Ngc40io, includedData: IncludedRelationship[] = []): TrendDataUIModel {
    const result = cloneDeep(getChartSeriesData(DeviceType.Ngc40io));

    device.relationships?.states?.data.forEach((state: any) => {
      const latestStateAttributes = includedData.find(x => x.id === state.id)
        ?.attributes as Ngc40io_latest_stateAttributes;
      if (
        latestStateAttributes.temperature_0_value &&
        latestStateAttributes.temperature_1_value &&
        latestStateAttributes.temperature_2_value &&
        latestStateAttributes.temperature_3_value
      ) {
        const ioTemperatureSeriesAttributes: string[] = [];
        temperatureSeriesAttributes.map(attribute => {
          if (
            attribute !== TemperatureSeriesAttributes.controlTemperature &&
            attribute !== TemperatureSeriesAttributes.setPoint
          ) {
            ioTemperatureSeriesAttributes.push(attribute);
          }
        });
        ioTemperatureSeriesAttributes.forEach((attribute, index) => {
          const dataObj = {
            x: latestStateAttributes.created_at ? new Date(latestStateAttributes.created_at) : new Date(),
            y:
              attribute === TemperatureSeriesAttributes.sensorTemperature1
                ? this.conversionService.getDigitFromValue(latestStateAttributes.temperature_0_value)
                : attribute === TemperatureSeriesAttributes.sensorTemperature2
                  ? this.conversionService.getDigitFromValue(latestStateAttributes.temperature_1_value)
                  : attribute === TemperatureSeriesAttributes.sensorTemperature3
                    ? this.conversionService.getDigitFromValue(latestStateAttributes.temperature_2_value)
                    : this.conversionService.getDigitFromValue(latestStateAttributes.temperature_3_value),
          };
          result.temperatureChart[index].x.push(dataObj.x);
          result.temperatureChart[index].y.push(dataObj.y);
        });
      }
    });
    return result;
  }

  appendNgc40IoTrendData(eventData: Event, preferenceUnit: string | undefined): TrendDataUIModel {
    const result = cloneDeep(getChartSeriesData(DeviceType.Ngc40io));

    if (
      eventData.data.attributes?.temperature_0_value &&
      eventData.data.attributes?.temperature_1_value &&
      eventData.data.attributes?.temperature_2_value &&
      eventData.data.attributes?.temperature_3_value
    ) {
      const ioTemperatureSeriesAttributes: string[] = [];
      temperatureSeriesAttributes.map(attribute => {
        if (
          attribute !== TemperatureSeriesAttributes.controlTemperature &&
          attribute !== TemperatureSeriesAttributes.setPoint
        ) {
          ioTemperatureSeriesAttributes.push(attribute);
        }
      });
      ioTemperatureSeriesAttributes.forEach((attribute, index) => {
        const dataObj = {
          x: eventData.data.attributes?.created_at ? new Date(eventData.data.attributes?.created_at) : new Date(),
          y:
            attribute === TemperatureSeriesAttributes.sensorTemperature1
              ? preferenceUnit === TemperatureUnits.Celsius
                ? this.conversionService.getDigitFromValue(eventData.data.attributes?.temperature_0_value)
                : this.conversionService.getDigitFromValue(
                    this.conversionService.unitConversion(eventData.data.attributes?.temperature_0_value),
                  )
              : attribute === TemperatureSeriesAttributes.sensorTemperature2
                ? preferenceUnit === TemperatureUnits.Celsius
                  ? this.conversionService.getDigitFromValue(eventData.data.attributes?.temperature_1_value)
                  : this.conversionService.getDigitFromValue(
                      this.conversionService.unitConversion(eventData.data.attributes?.temperature_1_value),
                    )
                : attribute === TemperatureSeriesAttributes.sensorTemperature3
                  ? preferenceUnit === TemperatureUnits.Celsius
                    ? this.conversionService.getDigitFromValue(eventData.data.attributes?.temperature_2_value)
                    : this.conversionService.getDigitFromValue(
                        this.conversionService.unitConversion(eventData.data.attributes?.temperature_2_value),
                      )
                  : preferenceUnit === TemperatureUnits.Celsius
                    ? this.conversionService.getDigitFromValue(eventData.data.attributes?.temperature_3_value)
                    : this.conversionService.getDigitFromValue(
                        this.conversionService.unitConversion(eventData.data.attributes?.temperature_3_value),
                      ),
        };
        result.temperatureChart[index].x.push(dataObj.x);
        result.temperatureChart[index].y.push(dataObj.y);
      });
    }
    return result;
  }
}
