import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';

export interface IdProviderAttributes {
  name: string;
  issuer: string;
  audience: string;
}

export interface IdProviderRelationships {
  organizations: { data: ResourceIdentifierObject[] };
  users: { data: ResourceIdentifierObject[] };
  id_tokens: { data: ResourceIdentifierObject[] };
}

export interface IdProvider<Attrs = Partial<IdProviderAttributes>, Rels = Partial<IdProviderRelationships>> {
  type: string;
  id: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface IdProviderData {
  data: IdProvider;
  included?: ResourceIdentifierObject[];
}

export interface MultiIdProviderData {
  data: IdProvider[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class IdProviderService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<MultiIdProviderData> {
    const url = `${this.uiBaseURL}/id_providers`;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<MultiIdProviderData>(url, options);
  }
}
