import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakRole } from '@app/models/keycloak';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakRoleService {
  private url = '';
  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    appConfigService: AppConfigService,
  ) {
    this.url = appConfigService.getKeycloakURL();
  }

  getRoles(clientId: string): Observable<KeycloakRole[]> {
    const path = this.url + `/clients/${clientId}/roles`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.get<KeycloakRole[]>(path, options);
  }

  getClientRoles(userId: string, clientId: string): Observable<KeycloakRole[]> {
    const path = this.url + `/users/${userId}/role-mappings/clients/${clientId}`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.get<KeycloakRole[]>(path, options);
  }

  addUserRole(userId: string, clientId: string, role: KeycloakRole): Observable<never> {
    const path = this.url + `/users/${userId}/role-mappings/clients/${clientId}`;
    const requestBody = [role];
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.post<never>(path, requestBody, options);
  }

  removeUserRoles(userId: string, clientId: string, roles: KeycloakRole[]): Observable<any> {
    const path = this.url + `/users/${userId}/role-mappings/clients/${clientId}`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
      body: roles,
    };
    return this.http.delete<any>(path, options);
  }
}
