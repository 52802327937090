<div class="trend-content" *blockUI="'trendBody'">
  <app-trend-duration
    (durationChange)="handleDurationChange($event)"
    (toggleCategorySelection)="toggleTrendSelectionOverlay()" />
  @if (temperatureChartOptions.data.length) {
    <app-plotly-chart [chartConfig]="temperatureChartOptions" [update]="updateTemperatureChart" />
  }
  @if (currentChartOptions.data.length) {
    <app-plotly-chart [chartConfig]="currentChartOptions" [update]="updateCurrentChart" />
  }
  @if (powerChartOptions.data.length) {
    <app-plotly-chart [chartConfig]="powerChartOptions" [update]="updatePowerChart" />
  }
</div>

@if (showTrendSelectionCategory) {
  <app-trend-selection
    [data]="trendSelectionCategories"
    (closeTrendSelection)="toggleTrendSelectionOverlay()"
    (saveSelectedCategory)="updateCharts($event); toggleTrendSelectionOverlay()" />
}
