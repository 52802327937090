import { Injectable } from '@angular/core';
import { IncludedRelationship } from '@models/device';
import { HostConfigurationUIModel } from '@models/host';
import { Host_actual_config } from '@models/jsonapi-models/actual-configs/host-actual-config.model';
import { Host, MultiHostData } from '@models/jsonapi-models/host.model';

@Injectable({
  providedIn: 'root',
})
export class HostConfigMapperService {
  constructor() {}

  mapHostActualConfigToBackend(hostConfig: HostConfigurationUIModel, hostId: string): Host_actual_config {
    return {
      type: 'host_actual_configs',
      attributes: {
        serial_0_baud_rate: hostConfig.baudRate,
        serial_0_data_bits: hostConfig.dataBits,
        serial_0_parity: hostConfig.parity,
        serial_0_path: hostConfig.path,
        serial_0_timeout: hostConfig.timeout,
        serial_0_stop_bits: hostConfig.stopBits,
        serial_0_tx_delay: hostConfig.txDelay,
        serial_1_baud_rate: hostConfig.baudRate,
        serial_1_data_bits: hostConfig.dataBits,
        serial_1_parity: hostConfig.parity,
        serial_1_path: hostConfig.path,
        serial_1_timeout: hostConfig.timeout,
        serial_1_stop_bits: hostConfig.stopBits,
        serial_1_tx_delay: hostConfig.txDelay,
        serial_2_baud_rate: hostConfig.baudRate,
        serial_2_data_bits: hostConfig.dataBits,
        serial_2_parity: hostConfig.parity,
        serial_2_path: hostConfig.path,
        serial_2_timeout: hostConfig.timeout,
        serial_2_stop_bits: hostConfig.stopBits,
        serial_2_tx_delay: hostConfig.txDelay,
        serial_3_baud_rate: hostConfig.baudRate,
        serial_3_data_bits: hostConfig.dataBits,
        serial_3_parity: hostConfig.parity,
        serial_3_path: hostConfig.path,
        serial_3_timeout: hostConfig.timeout,
        serial_3_stop_bits: hostConfig.stopBits,
        serial_3_tx_delay: hostConfig.txDelay,
        serial_4_baud_rate: hostConfig.baudRate,
        serial_4_data_bits: hostConfig.dataBits,
        serial_4_parity: hostConfig.parity,
        serial_4_path: hostConfig.path,
        serial_4_timeout: hostConfig.timeout,
        serial_4_stop_bits: hostConfig.stopBits,
        serial_4_tx_delay: hostConfig.txDelay,
        serial_5_baud_rate: hostConfig.baudRate,
        serial_5_data_bits: hostConfig.dataBits,
        serial_5_parity: hostConfig.parity,
        serial_5_path: hostConfig.path,
        serial_5_timeout: hostConfig.timeout,
        serial_5_stop_bits: hostConfig.stopBits,
        serial_5_tx_delay: hostConfig.txDelay,
        serial_6_baud_rate: hostConfig.baudRate,
        serial_6_data_bits: hostConfig.dataBits,
        serial_6_parity: hostConfig.parity,
        serial_6_path: hostConfig.path,
        serial_6_timeout: hostConfig.timeout,
        serial_6_stop_bits: hostConfig.stopBits,
        serial_6_tx_delay: hostConfig.txDelay,
        serial_7_baud_rate: hostConfig.baudRate,
        serial_7_data_bits: hostConfig.dataBits,
        serial_7_parity: hostConfig.parity,
        serial_7_path: hostConfig.path,
        serial_7_timeout: hostConfig.timeout,
        serial_7_stop_bits: hostConfig.stopBits,
        serial_7_tx_delay: hostConfig.txDelay,
      },
      relationships: {
        host: {
          data: {
            id: hostId,
            type: 'hosts',
          },
        },
        latest: {
          data: {
            id: hostId,
            type: 'hosts',
          },
        },
      },
    };
  }

  mapHostToBackend(host: HostConfigurationUIModel): Host {
    return {
      type: 'hosts',
      id: host.id,
      attributes: {
        name: host.name,
      },
    };
  }

  mapHostsDataFromBackend(
    hostsData: MultiHostData,
    hostsActualConfigData: IncludedRelationship[],
  ): HostConfigurationUIModel[] {
    const hostsUiData: HostConfigurationUIModel[] = [];
    hostsData.data.forEach(host => {
      let hostActualConfiguration = hostsActualConfigData.find(
        configData => host.id == configData.relationships.latest.data.id,
      );
      let hostUiData = this.mapHostDataFromBackend(hostActualConfiguration, host.id, host.attributes?.name);
      hostsUiData.push(hostUiData);
    });

    return hostsUiData;
  }

  mapHostDataFromBackend(hostActualConfigData: any, hostId?: string, hostName?: string | null) {
    let hostUiData: HostConfigurationUIModel = {
      baudRate: 0,
      dataBits: 0,
      parity: 'NONE',
      path: '',
      txDelay: '',
      stopBits: 0,
      timeout: '',
      name: '',
      id: '',
    };
    const hostActualConfigurationAttributes = hostActualConfigData?.attributes;
    hostUiData.baudRate = hostActualConfigurationAttributes?.serial_0_baud_rate;
    hostUiData.dataBits = hostActualConfigurationAttributes?.serial_0_data_bits;
    hostUiData.parity = hostActualConfigurationAttributes?.serial_0_parity;
    hostUiData.path = hostActualConfigurationAttributes?.serial_0_path;
    hostUiData.stopBits = hostActualConfigurationAttributes?.serial_0_stop_bits;
    hostUiData.txDelay = hostActualConfigurationAttributes?.serial_0_tx_delay;
    hostUiData.timeout = hostActualConfigurationAttributes?.serial_0_timeout;
    hostUiData.id = hostActualConfigData?.relationships?.host?.data.id
      ? hostActualConfigData?.relationships?.host?.data.id
      : hostId;
    if (hostName) hostUiData.name = hostName;
    return hostUiData;
  }
}
