import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';
import { Ngc40htc3_actual_config } from '@services/jsonapi-services/ngc40htc3_actual_config.service';
import { Ngc40htc3_desired_config } from '@services/jsonapi-services/ngc40htc3_desired_config.service';
import { Ngc40htc3_latest_state } from '@services/jsonapi-services/ngc40htc3_latest_state.service';

interface Ngc40htc3Attributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'ngc40htc3_actual_configs',
  bridge: 'ngc40bridges',
  desired_config: 'ngc40htc3_desired_configs',
  device: 'devices',
  info: 'ngc40htc3_infos',
  latest_actual_config: 'ngc40htc3_actual_configs',
  latest_state: 'ngc40htc3_latest_states',
  states: 'ngc40htc3_states',
};

export interface Ngc40htc3Relationships {
  actual_configs?: { data: Ngc40htc3_actual_config[] };
  bridge?: { data: ResourceIdentifierObject };
  desired_config?: { data: Ngc40htc3_desired_config[] };
  device?: { data: unknown };
  info?: { data: ResourceIdentifierObject };
  latest_actual_config?: { data: Ngc40htc3_actual_config };
  latest_state?: { data: Ngc40htc3_latest_state };
  states?: { data: unknown[] };
}

export interface Ngc40htc3 extends Base {
  id: string;
  type: string;
  attributes?: Ngc40htc3Attributes;
  relationships?: Ngc40htc3Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc40htc3Data {
  data: Ngc40htc3;
  included?: IncludedRelationship[];
}

// TODO {.Plural | pascal}
export interface Ngc40htc3sData {
  data: Ngc40htc3[];
  included?: IncludedRelationship[];
}

export interface Ngc40htc3_infosAttributes {
  device_firmware_version?: string;
  device_serial?: string;
}

@Injectable({
  providedIn: 'root',
})
export class Ngc40htc3Service {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40htc3TrendData(params: HttpParams, id: string): Observable<Ngc40htc3sData> {
    const url = `${this.uiBaseURL}/ngc40htc3s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htc3sData>(url, options);
  }

  getNgc40htc3s(params: HttpParams): Observable<Ngc40htc3sData> {
    const url = `${this.uiBaseURL}/ngc40htc3s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htc3sData>(url, options);
  }

  getNgc40htc3(id: string, params: HttpParams): Observable<Ngc40htc3Data> {
    const url = `${this.uiBaseURL}/ngc40htc3s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htc3Data>(url, options);
  }

  addNgc40htc3(ngc40htc3: Ngc40htc3): Observable<Ngc40htc3> {
    const url = `${this.uiBaseURL}/ngc40htc3s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40htc3>(url, { data: ngc40htc3 }, options);
  }

  updateNgc40htc3(ngc40htc3: Ngc40htc3): Observable<Ngc40htc3Data> {
    const url = `${this.uiBaseURL}/ngc40htc3s/` + ngc40htc3.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40htc3Data>(url, { data: ngc40htc3 }, options);
  }

  removeNgc40htc3(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40htc3s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
