import { Component, computed, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { KeycloakService } from '@app/services/keycloak.service';
import { UserActivityService } from '@app/services/user-activity.service';
import { UserUtilService } from '@app/services/user-util.service';
import { UtilsService } from '@app/services/utils.service';
import { NavMenuItem } from '@models/menu-item';
import { DataStoreService } from '@services/data-store.service';
import { LocalStorageService } from '@services/local-storage.service';
import { STORAGE_KEYS } from '@utils/constants/local-storage-constants';
import { navMenuItems } from '@utils/constants/nav-menu-items';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent implements OnInit {
  isCollapsed = signal(true);
  state = computed(() => (this.isCollapsed() ? 'collapsed' : 'expanded'));
  sidenavWidth = computed(() => (this.isCollapsed() ? '4rem' : '12rem'));
  nextState = computed(() => (this.isCollapsed() ? 'expand' : 'collapse'));
  userName!: string;
  siteName!: string;
  alarmCount: number = 0;
  menuItems: NavMenuItem[] = navMenuItems;
  currPage!: string;
  isAuthenticated?: boolean;

  constructor(
    private dataStoreService: DataStoreService,
    private userUtilService: UserUtilService,
    private keycloak: KeycloakService,
    private utilsService: UtilsService,
    private userActivityService: UserActivityService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated = this.keycloak.isAuthenticated();
    this.userName = this.userUtilService.userName;
    this.siteName = 'Mumbai';
    this.getAlarmsCount();
    this.userActivityService.interruptTimer();
    this.userActivityService.getUserLoggedOut().subscribe(isIdle => {
      if (isIdle) this.signOut();
    });
  }

  getAlarmsCount() {
    this.dataStoreService.getAlarmsData().subscribe(alarmData => {
      this.alarmCount = alarmData.data.filter(a => a.alarmState !== 'reset').length;
    });
  }

  openSettingModal(): void {}

  signOut(): void {
    this.keycloak.signOut();
    this.localStorageService.removeItem(STORAGE_KEYS.dashboardView);
  }

  toggleNav() {
    this.isCollapsed.set(!this.isCollapsed());
    this.utilsService.setNavToggle(this.isCollapsed());
  }

  signIn() {
    this.keycloak.loadKeycloakLogin();
  }
}
