import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ac2000p } from '@models/ac2000';
import { AppConfigService } from '@services/app-config.service';
import { Cm2000 } from '@services/jsonapi-services/cm2000.service';
import { Cm2000p } from '@services/jsonapi-services/cm2000p.service';
import { Htc910 } from '@services/jsonapi-services/htc910.service';
import { Htc920 } from '@services/jsonapi-services/htc920.service';
import { Ngc40htc } from '@services/jsonapi-services/ngc40htc.service';
import { Ngc40htc3 } from '@services/jsonapi-services/ngc40htc3.service';
import { Ngc40io } from '@services/jsonapi-services/ngc40io.service';
import { Ngc40slim } from '@services/jsonapi-services/ngc40slim.service';
import { Elexant3500i } from '@services/jsonapi-services/elexant3500i.service';
import { Elexant40X0 } from '@services/jsonapi-services/elexant40X0.service';
import { Ngc20 } from '@services/jsonapi-services/ngc20.service';
import { Elexant5010i } from '@services//jsonapi-services/elexant5010i.service';

export interface AlarmAttrs {
  shelve_comments?: string | null;
  shelve_reason?: string | null;
  shelved_until?: string | null;
  state: string;
  priority?: number;
}

export interface AlarmRels {
  pulses: { data: ResourceIdentifierObject[] };
  device: { data: Device };
  article: { data: ResourceIdentifierObject };
}

export interface AlarmPulseRels {
  alarm: { data: ResourceIdentifierObject };
}

export interface Alarm {
  type?: string;
  id?: string;
  attributes?: AlarmAttrs;
  relationships?: AlarmRels;
}
export interface AlarmPulse {
  type: string;
  id: string;
  attributes?: AlarmPulseAttrs;
  relationships?: AlarmPulseRels;
}

export interface ArticleAttrs {
  name: string;
  description: string | null;
}

export interface AlarmPulseAttrs {
  begin_at: string;
  end_at: string | null;
  latest: boolean;
}

export interface DeviceAttrs {
  priority: number;
}

export interface DeviceRels {
  ngc40htc: { data: Ngc40htc };
  ngc40htc3: { data: Ngc40htc3 };
  ngc40io: { data: Ngc40io };
  ngc40slim: { data: Ngc40slim };
  htc910: { data: Htc910 };
  htc920: { data: Htc920 };
  ngc20: { data: Ngc20 };
  elexant5010i: { data: Elexant5010i };
  cm2000: { data: Cm2000 };
  cm2000p: { data: Cm2000p };
  ac2000p: { data: Ac2000p };
  elexant3500i: { data: Elexant3500i };
  elexant40x0: { data: Elexant40X0 };
}

export interface Device {
  type?: string;
  id: string;
  attributes?: DeviceAttrs;
  relationships?: DeviceRels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
  attributes?: unknown;
}

export interface AlarmData {
  data: Alarm;
  included?: ResourceIdentifierObject[];
}

export interface MultiAlarmData {
  data: Alarm[];
  included?: ResourceIdentifierObject[];
}

export interface MultiAlarmPulseData {
  data: AlarmPulse[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<MultiAlarmData> {
    const url = `${this.uiBaseURL}/alarms`;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<MultiAlarmData>(url, options);
  }

  getAlarmPulses(params: HttpParams): Observable<MultiAlarmPulseData> {
    const url = `${this.uiBaseURL}/alarm_pulses`;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<MultiAlarmPulseData>(url, options);
  }

  getAlarm(id: string, params: HttpParams): Observable<AlarmData> {
    const url = `${this.uiBaseURL}/alarms/${id}`;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<AlarmData>(url, options);
  }

  updateAlarm(alarm: Alarm): Observable<AlarmData> {
    const url = `${this.uiBaseURL}/alarms/${alarm.id!}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<AlarmData>(url, { data: alarm }, options);
  }
}
