import { Injectable } from '@angular/core';
import { IdTokenPayload } from '@app/models/id-token';
import { UserPreferenceUIModel } from '@app/models/user_preference';
import { environment } from '@env/environment';
import { AuthService } from '@services/auth.service';
import { KeycloakService } from '@services/keycloak.service';
import { idTokenPayload } from '@utils/constants/roles';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserUtilService {
  token: IdTokenPayload | undefined;
  authId: string;
  userName: string;
  activeUserPreference: UserPreferenceUIModel = { id: '', unit: '' };
  public userPreferenceSubject = new ReplaySubject<UserPreferenceUIModel>(1);
  private userIdSubject = new ReplaySubject<string>(1);

  constructor(
    private keycloak: KeycloakService,
    private authService: AuthService,
  ) {
    if (keycloak.isAuthenticated()) {
      const token = this.keycloak.getToken();
      this.authId = token.sub;
      this.userName = token.preferred_username!;
    } else {
      const token = environment.e2e ? this.authService.parseIdToken() : idTokenPayload;
      this.authId = token.sub;
      this.userName = environment.e2e ? token.name! : token.preferred_username!;
    }
  }

  setUserPreference(userPreferenceData: UserPreferenceUIModel) {
    this.activeUserPreference = userPreferenceData;
    this.userPreferenceSubject.next(userPreferenceData);
  }

  getUserPreference() {
    return this.userPreferenceSubject.asObservable();
  }

  setUserId(userId: string) {
    this.userIdSubject.next(userId);
  }

  getUserId() {
    return this.userIdSubject.asObservable();
  }

  getCurrentUserPreference() {
    return this.activeUserPreference;
  }
}
