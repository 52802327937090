import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AttributesInfo } from '@models/table-items';
import { customizeColumnDetails } from '@utils/constants/customize-columns';
import { imgBase64 } from '@utils/constants/logo-base64';
import { paragraph } from '@utils/constants/manual-content';
import { DeviceType } from '@utils/enums/device-enums';
import { TemperatureUnits } from '@utils/enums/unit-enums';
import { isNullOrUndefined, isObject } from 'core-util-is';
import jsPDF from 'jspdf';
import { isEqual } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

const enum columnGroups {
  General = 'General',
  Alarm = 'Alarm',
  Temperature = 'Temperature',
  Load = 'Load',
  Maintenance = 'Maintenance',
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  pageCount: number = 0;
  allowDownload: boolean = true;
  isNavCollapsed = new BehaviorSubject<boolean>(true);
  constructor(private snackBar: MatSnackBar) {}

  setNavToggle(value: boolean) {
    this.isNavCollapsed.next(value);
  }

  getNavToggle(): BehaviorSubject<boolean> {
    return this.isNavCollapsed;
  }

  getDeviceListColumns(preferenceUnit: string): AttributesInfo[] {
    const temperatureUnit = preferenceUnit == TemperatureUnits.Celsius ? '(°C)' : '(°F)';
    return [
      {
        field: 'deviceTag',
        header: 'Device Tag',
        group: columnGroups.General,
        isDisplayedByDefault: true,
        isStickyStart: true,
      },
      {
        field: 'alarmsCount',
        header: 'Alarms',
        group: columnGroups.Alarm,
        isDisplayedByDefault: true,
        isStickyStart: true,
      },
      {
        field: 'outputState',
        header: 'Device State',
        group: columnGroups.General,
        isDisplayedByDefault: true,
        isStickyStart: true,
      },
      {
        field: 'controlTemperature',
        header: 'Control Temp',
        unit: temperatureUnit,
        group: columnGroups.Temperature,
        isDisplayedByDefault: true,
        isStickyStart: true,
      },
      { field: 'sensorTemperature1', header: 'Temp Source 1', unit: temperatureUnit, group: columnGroups.Temperature },
      { field: 'sensorTemperature2', header: 'Temp Source 2', unit: temperatureUnit, group: columnGroups.Temperature },
      { field: 'sensorTemperature3', header: 'Temp Source 3', unit: temperatureUnit, group: columnGroups.Temperature },
      { field: 'sensorTemperature4', header: 'Temp Source 4', unit: temperatureUnit, group: columnGroups.Temperature },
      { field: 'lineCurrent', header: 'Current', unit: '(A)', group: columnGroups.Load, isDisplayedByDefault: true },
      { field: 'groundFaultCurrent', header: 'GF', unit: '(mA)', group: columnGroups.Load, isDisplayedByDefault: true },
      { field: 'voltage', header: 'Voltage', unit: '(V)', group: columnGroups.Load, isDisplayedByDefault: true },
      { field: 'power', header: 'Power', unit: '(W)', group: columnGroups.Load, isDisplayedByDefault: true },
      {
        field: 'firmwareVersion',
        header: 'Firmware Version',
        group: columnGroups.General,
        isDisplayedByDefault: false,
      },
      customizeColumnDetails,
    ];
  }

  downloadFile(pdfUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = fileName;
    link.click();
  }

  generatePdf(title: string, content: paragraph[], fileName: string) {
    const doc = new jsPDF();
    this.pageCount = 1;
    const logoImg: string = imgBase64;
    const logoWidth = 35; // Adjust the logo width as needed
    const logoHeight = 16;

    // Add header logo
    this.addHeader(doc, logoImg, logoHeight, logoWidth);

    // Add heading title
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(196, 38, 46);
    const headingWidth = doc.getTextWidth(title);
    const headingX = (doc.internal.pageSize.getWidth() - headingWidth) / 2; // Centered horizontally
    doc.text(title, headingX, logoHeight + 18);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0);
    const fontSize = 12;
    doc.setFontSize(11);
    const lineHeight = fontSize * 0.5;
    const contentMargin = 10;
    const contentWidth = doc.internal.pageSize.getWidth() - 2 * contentMargin;
    let y = logoHeight + 26;

    // Loop through paragraphs
    content.forEach(paragraph => {
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text(paragraph.header, 10, y);
      y = y + 7;
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(11);
      const words = paragraph.text.split(' ');
      let line = '';
      words.forEach((word: string) => {
        const lineLength = doc.getTextWidth(line + ' ' + word);
        if (lineLength > contentWidth) {
          doc.text(line.trim(), contentMargin, y);
          y += lineHeight;
          line = word;
        } else {
          line += ' ' + word;
          // Check if the remaining content exceeds the page height
          if (y + lineHeight > doc.internal.pageSize.getHeight() - 14) {
            this.addFooter(doc);
            this.pageCount++;
            doc.addPage();
            y = 10;
            this.addHeader(doc, logoImg, logoHeight, logoWidth);
            y = logoHeight + 18;
            doc.text(line.trim(), contentMargin, y);
          }
        }
      });

      doc.text(line.trim(), contentMargin, y);
      // Add extra spacing between paragraphs
      y += lineHeight * 2;
    });
    this.addFooter(doc);
    this.allowDownload && doc.save(fileName);
  }

  private addHeader(doc: jsPDF, logoImg: string, logoHeight: number, logoWidth: number) {
    // Add logo to the header
    doc.addImage(logoImg, 'PNG', 10, 5, logoWidth, logoHeight);
    doc.setFontSize(10.9);
    doc.text('Elexant Supervisor Software', logoWidth + 12, 21);
    // Add a thin line below the logo
    doc.setDrawColor(196, 38, 46);
    doc.setLineWidth(0.5);
    doc.line(10, logoHeight + 8, doc.internal.pageSize.getWidth() - 10, logoHeight + 8);
  }

  private addFooter(doc: jsPDF) {
    doc.line(
      10,
      doc.internal.pageSize.getHeight() - 15,
      doc.internal.pageSize.getWidth() - 10,
      doc.internal.pageSize.getHeight() - 15,
    );
    doc.setFontSize(12);
    doc.text(this.pageCount.toString(), doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.height - 10);
  }

  compare(
    valueA: string | number | boolean | undefined | null,
    valueB: string | number | boolean | undefined | null,
    sortDirection: string,
  ) {
    let sortOrder = sortDirection === 'asc' ? 1 : -1;
    if (valueA === valueB) return 0;
    if (valueA === '' || valueA === 'None' || valueA === undefined || valueA === null) return 1;
    if (valueB === '' || valueB === 'None' || valueB === undefined || valueB === null) return -1;
    if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) return (Number(valueA) - Number(valueB)) * sortOrder;
    return (valueA < valueB ? -1 : 1) * sortOrder;
  }

  camelCaseConverter(title: string): string {
    let titlesList = title.split('_');
    titlesList = titlesList.map((val, index) => {
      return index === 0 ? val.toLowerCase() : val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    });
    return titlesList
      .join('')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .trim();
  }

  titleCaseConverter(title: string): string {
    let titlesList = title.split(' ');
    titlesList = titlesList.map(val => {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    });
    return titlesList
      .join(' ')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .trim();
  }

  getDeviceTypeName(deviceType: string): string {
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return DeviceType.Htc3;
      case DeviceType.Ngc40htc:
        return DeviceType.Htc;
      case DeviceType.Ngc40io:
        return DeviceType.Io;
      case DeviceType.Ngc40slim:
        return DeviceType.Slim;
      default:
        return deviceType;
    }
  }

  getKeyWithPattern(inputValue: Record<string, string | boolean | number>, pattern: RegExp): string {
    const regex = new RegExp(pattern);
    for (const key in inputValue) {
      if (regex.test(key)) return key;
    }
    return '';
  }

  showSnackBar(message: string, state: string = '') {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'end';
    config.verticalPosition = 'bottom';
    config.panelClass = [`snackbar-${state}`, 'config-snackbar'];
    this.snackBar.open(message, '', config);
  }

  createDeepCopy(objectToBeCopied: any) {
    return JSON.parse(JSON.stringify(objectToBeCopied));
  }

  areDifferent(sourceObject: any, updatedObject: any) {
    return isEqual(sourceObject, updatedObject);
  }

  objectComparer(firstObject: any, secondObject: any) {
    const firstObjectCopy = this.createDeepCopy(firstObject);
    const secondObjectCopy = this.createDeepCopy(secondObject);
    return this.areDifferent(
      this.objectPropertiesToStringConverter(firstObjectCopy),
      this.objectPropertiesToStringConverter(secondObjectCopy),
    );
  }
  objectPropertiesToStringConverter(object: any) {
    Object.keys(object).forEach(key => {
      if (!isNullOrUndefined(object[key]) && isObject(object[key])) {
        return this.objectPropertiesToStringConverter(object[key]);
      }
      object[key] = '' + object[key];
    });
    return object;
  }
}
