<div [formGroup]="formGroup">
  <div class="mat-form-field-container">
    <p class="input-label">{{ label }}</p>
    <mat-form-field appearance="outline" class="field-input">
      <mat-select
        [placeholder]="placeholder"
        class="sort-type-selector"
        panelClass="overlay-container select-panel"
        [formControlName]="name"
        id="{{ name }}-ctrl"
        (selectionChange)="handleSelectionChange($event)"
        (openedChange)="handleDropdown($event)"
        [value]="defaultValue"
        [multiple]="multiple">
        @for (option of options; track $index) {
          <mat-option [value]="option.value" [disabled]="option.disabled">{{ option.displayText }} </mat-option>
        }
      </mat-select>
      @if (formGroup.controls[name].touched && formGroup.controls[name].errors?.required) {
        <mat-error> {{ label }} is required </mat-error>
      }
    </mat-form-field>
  </div>
</div>
