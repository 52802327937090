import { DestroyRef, inject, Injectable } from '@angular/core';
import { KeycloakGroup, KeycloakRole } from '@models/keycloak';
import { UserManagement } from '@models/table-items';
import { IdProviderService } from '@services/jsonapi-services/id_provider.service';
import { UserService } from '@services/jsonapi-services/user.service';
import { idProviderParam } from '@utils/constants/params';
import { UserGroups } from '@utils/enums/keycloak-enums';
import { TemperatureUnits } from '@utils/enums/unit-enums';
import { BehaviorSubject, concatMap, forkJoin, map, mergeMap, switchMap } from 'rxjs';
import { IdTokenService } from './jsonapi-services/id_token.service';
import { PreferenceService } from './jsonapi-services/preference.service';
import { KeycloakGroupService } from './keycloak-group.service';
import { KeycloakRoleService } from './keycloak-role.service';
import { KeycloakUserService } from './keycloak-user.service';
import { UserMapperService } from './mapper-services/user-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  userId!: string;
  idProviderId!: string;
  destroyRef = inject(DestroyRef);
  keycloakRoles: BehaviorSubject<KeycloakRole[]> = new BehaviorSubject<KeycloakGroup[]>([]);
  keycloakGroups: BehaviorSubject<KeycloakGroup[]> = new BehaviorSubject<KeycloakGroup[]>([]);

  constructor(
    private idProviderService: IdProviderService,
    private userService: UserService,
    private userMapperService: UserMapperService,
    private idTokenService: IdTokenService,
    private preferenceService: PreferenceService,
    private keycloakGroupService: KeycloakGroupService,
    private keycloakUserService: KeycloakUserService,
    private keycloakRoleService: KeycloakRoleService,
  ) {}

  getUsersData(clientId: string) {
    return this.keycloakRoleService.getRoles(clientId).pipe(
      concatMap(keycloakRoles => {
        this.keycloakRoles.next(keycloakRoles);
        return this.keycloakGroupService.getAllGroups();
      }),
      switchMap(groups => {
        this.keycloakGroups.next(groups);
        return forkJoin(groups.map(group => this.keycloakUserService.getUsersFromGroup(group.id!)));
      }),
      map((users: any) => {
        let mappedUser: { [key: string]: UserManagement } = {};
        this.keycloakGroups.getValue().forEach((groups, index) => {
          users[index].forEach((user: any) => {
            if (mappedUser[user.id!] && mappedUser[user.id!].group?.name?.includes(UserGroups.approved)) return;
            mappedUser[user.id!] = {
              user: user,
              group: groups,
              role: {},
            };
          });
        });
        return mappedUser;
      }),
      switchMap(userData => {
        return forkJoin(
          Object.keys(userData).map((keycloakUser: string) => {
            return this.keycloakRoleService.getClientRoles(keycloakUser, clientId).pipe(
              map(data => {
                userData[keycloakUser].role = data[0];
                return userData[keycloakUser];
              }),
            );
          }),
        );
      }),
    );
  }

  getKeycloakRoles() {
    return this.keycloakRoles;
  }

  getKeycloakGroups() {
    return this.keycloakGroups;
  }

  addNewUserToJsonApi(keyCloakUserId: string, selectedJsonRoleId: string, eulaAccepted: boolean = false) {
    return this.idProviderService
      .getMulti(idProviderParam)
      .pipe(
        mergeMap(idProviderdata => {
          this.idProviderId = idProviderdata.data[0].id;
          return this.userService.addWithoutId(
            this.userMapperService.mapToBackEnd({
              eula_accepted: eulaAccepted,
            }),
          );
        }),
      )
      .pipe(
        mergeMap(userData => {
          this.userId = userData.data.id!;
          const idTokenData = this.userMapperService.mapIdTokenToBackEnd(
            keyCloakUserId,
            this.userId,
            this.idProviderId,
          );
          return this.idTokenService.addWithoutId(idTokenData);
        }),
      )
      .pipe(
        mergeMap(() => {
          const userPreference = this.userMapperService.mapUserPreferenceToBackEnd(
            TemperatureUnits.Celsius,
            this.userId,
          );
          return forkJoin([
            this.preferenceService.addWithoutId(userPreference),
            this.userService.updateToManyRelationship(this.userId, 'roles', [selectedJsonRoleId]),
          ]);
        }),
      );
  }
}
