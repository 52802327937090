export interface paragraph {
  header: string;
  text: string;
}

export const content: paragraph[] = [
  {
    header: 'Welcome',
    text: 'nVent nVent RAYCHEM Elexant Supervisor Software (henceforth referred to as the nVent RAYCHEM Elexant Supervisor Software) is a web browser based, Graphical User Interface (GUI), that consolidates all alarm related information from a nVent RAYCHEM Elexant Supervisor Software Parent Server, into a single dashboard. nVent RAYCHEM Elexant Supervisor Software consolidates all alarm related information into a single Graphical User Interface (GUI), summarizing data for easy viewing, sorting, filtering, and reporting, through graphs and tables. With improved access to alarm information, users can easily understand what alarm types are prevalent in their system, and can access additional information applicable to the devices that are in alarm, assisting in Alarm Management and other related activities.',
  },
  {
    header: 'Continuous Improvement',
    text: 'nVent will strive to provide continuous improvements for the nVent RAYCHEM Elexant Supervisor Software by way of Bug fixes, Enhancements, and general Quality of Life improvements. Please refer to nVent’s nVent RAYCHEM Elexant Supervisor Software landing page for access to information pertaining to the nVent RAYCHEM Elexant Supervisor Software, as well as links to related products. Further, additional information pertaining to software releases can be found within the application’s ‘About’ perspective.',
  },
  {
    header: 'Vital Information',
    text: 'All information, including illustrations, is believed to be reliable. Users, however, should independently evaluate the suitability of the product for their particular application. nVent makes no warranties as to the accuracy or completeness of this information, and disclaims any liability regarding its use. nVents only obligations are those in the nVent Standard Terms and Conditions of Sale for this product, and in no case will nVent or its distributors be liable for any incidental, indirect, or consequential damages arising from the sale, resale, use, or misuse of the product. Specifications are subject to change without notice. In addition, nVent reserves the right to make changes, without notification to Buyer, to processing or materials that do not affect compliance with any applicable specification.',
  },
  {
    header: 'User Responsibilities',
    text: 'The performance, reliability, and safety of a heat-tracing system depends on proper design, selection, and installation. The nVent RAYCHEM Elexant Supervisor Software will help you view alarms, but it is only a tool. It assumes that your input is accurate, that you are familiar with heattracing system design and configuration, and that you will ensure that all components of the heat-tracing system are installed, maintained, and used as intended. The configuration of the system should be reviewed by a knowledgeable person to ensure it is appropriate for your application. Additional information relating to safety, design, and installation is contained in other supporting documents such as Design Guides, Installation Manuals, Data Sheets, etc., and are available from nVent. Please consult all related documents as needed, and contact an nVent representative for assistance.',
  },
  {
    header: 'Safety Warnings',
    text: 'There are important safety warnings shipped with our products and printed in our literature. Be sure to read and follow them to reduce the risk of fire, shock, or personal injury. If you have any questions, contact an nVent representative for assistance.',
  },
  {
    header: 'Search',
    text: 'Search the data for results that apply to a specific ‘Device Tag’.',
  },
  {
    header: 'Filter',
    text: 'Many filtering options are possible in the nVent RAYCHEM Elexant Supervisor Software. As a filter is applied, all data represented in the views of the nVent RAYCHEM Elexant Supervisor Software will update accordingly.',
  },
];
