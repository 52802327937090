import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client, KeycloakUser } from '@app/models/keycloak';
import { AppConfigService } from '@services/app-config.service';
import { KeycloakService } from '@services/keycloak.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeycloakUserService {
  private url = '';

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    appConfigService: AppConfigService,
  ) {
    this.url = appConfigService.getKeycloakURL();
  }

  getClients(): Observable<Client[]> {
    const path = this.url + `/clients`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.get<Client[]>(path, options);
  }

  getUsersFromGroup(groupId: string) {
    const url = this.url + `/groups/${groupId}/members`;
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + `${this.keycloakService.getAccessToken()}`,
        Accept: 'application/json',
      }),
    };
    return this.http.get<KeycloakUser[]>(url, options);
  }
}
