import { Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdTokenPayload } from '@app/models/id-token';
import { environment } from '@env/environment';
import { ProdMode } from '@utils/enums/prod-mode-enums';
import Keycloak, { KeycloakInitOptions, KeycloakLoginOptions } from 'keycloak-js';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  _instance: Keycloak = new Keycloak();
  isProd = false;
  url = signal<string>('');
  private initOptions!: KeycloakInitOptions;
  private keycloakAuthenticated!: boolean;

  constructor(private router: Router) {
    this.isProd = environment.production;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._instance.redirectUri = window.location.origin + environment.redirectURI;
      }
    });
  }

  async initializeKeycloak(serverName: string) {
    this.url.set(`https://${this.isProd ? serverName : 'rsikeycloak.thermalrsidev.com'}:8443/auth/`);
    const config: any = {
      realm: 'RES',
      url: this.url(),
      clientId: 'rsi-client',
    };
    this.initOptions = {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + environment.redirectURI + 'assets/silent-check-sso.html',
      redirectUri: window.location.origin + environment.redirectURI,
    };
    this._instance = new Keycloak(config);
    this.bindsKeycloakEvents();
    this.keycloakAuthenticated = await this._instance.init(this.initOptions);
    if (environment.prodMode !== ProdMode.HmiPanel) await this.loadKeycloakLogin();
  }

  async loadKeycloakLogin() {
    if (!this.keycloakAuthenticated) await this._instance.login(this.initOptions as KeycloakLoginOptions);
  }

  getToken() {
    return this.parseIdToken();
  }

  getAccessToken() {
    return this._instance.token;
  }

  getClientId() {
    return this._instance.clientId;
  }

  getKeycloakUrl() {
    return this.url() + 'admin/realms/RES';
  }

  isAuthenticated() {
    return this._instance.authenticated;
  }

  signOut() {
    this._instance.logout();
  }

  getUserRoles() {
    let clientId = this._instance.clientId;
    if (this._instance.resourceAccess && clientId && this._instance.resourceAccess[clientId]) {
      return this._instance.resourceAccess[clientId]?.roles;
    }
    return [];
  }

  private bindsKeycloakEvents(): void {
    this._instance.onTokenExpired = () => {
      this._instance.updateToken();
    };
  }

  private parseIdToken(): IdTokenPayload {
    const idToken = this._instance.token;
    const limit = 3;
    const index = { one: 1 };
    let idTokenBody = idToken!.split('.', limit)[index.one];
    idTokenBody = idTokenBody.replace(/-/g, '+').replace(/_/g, '/');
    const role = JSON.parse(atob(idTokenBody)).resource_access['rsi-client'];
    var idTokenPayload: IdTokenPayload = JSON.parse(atob(idTokenBody)) as IdTokenPayload;
    idTokenPayload = { ...idTokenPayload, ...(role && { role: role.roles[0] }) };
    return idTokenPayload;
  }
}
