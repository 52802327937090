import { Injectable } from '@angular/core';
import {
  currentSeriesAttributes,
  powerSeriesAttributes,
  temperatureSeriesAttributes,
} from '@app/utils/constants/device-constants';
import {
  CurrentSeriesAttributes,
  PowerSeriesAttributes,
  TemperatureSeriesAttributes,
} from '@app/utils/enums/trend-chart-enums';
import { TemperatureUnits } from '@app/utils/enums/unit-enums';
import { DeviceUIModel, IncludedRelationship, TrendDataUIModel } from '@models/device';
import { ConversionService } from '@services/conversion.service';
import { Ngc40htc3_latest_stateAttributes } from '@services/jsonapi-services/ngc40htc3_latest_state.service';
import { getChartSeriesData } from '@utils/constants/device-trend-constants';
import { DeviceType } from '@utils/enums/device-enums';
import { cloneDeep } from 'lodash-es';
import { Event } from '../event.service';
import { Ngc40htc3 } from '../jsonapi-services/ngc40htc3.service';
import { Ngc40htc3_actual_configAttributes } from '../jsonapi-services/ngc40htc3_actual_config.service';

@Injectable({
  providedIn: 'root',
})
export class Ngc40htc3DeviceDataMapperService {
  constructor(private conversionService: ConversionService) {}

  mapNgc40htc3DeviceLatestState(
    device: DeviceUIModel,
    deviceLatestStateData: Ngc40htc3_latest_stateAttributes,
  ): DeviceUIModel {
    device.alarmState = deviceLatestStateData.alarm_0_value || false;
    device.controlTemperature =
      this.conversionService.setTemperature(deviceLatestStateData.htc_0_control_temperature!) || null;
    device.controlTemperatureError = deviceLatestStateData?.htc_0_control_temperature_error;
    device.groundFaultCurrent = deviceLatestStateData.htc_0_ground_fault_current || null;
    device.lineCurrent = deviceLatestStateData.htc_0_line_current_0_effective || null;
    device.outputState = deviceLatestStateData.htc_0_switch_state || false;
    device.power = deviceLatestStateData.htc_0_power_consumption_0 || null;
    device.sensorTemperature1 =
      this.conversionService.setTemperature(deviceLatestStateData.htc_0_temperature_source_0_value!) || null;
    device.sensorTemperature1Error = deviceLatestStateData?.htc_0_temperature_source_0_value_error;
    device.sensorTemperature2 =
      this.conversionService.setTemperature(deviceLatestStateData.htc_0_temperature_source_1_value!) || null;
    device.sensorTemperature2Error = deviceLatestStateData?.htc_0_temperature_source_1_value_error;
    device.voltage = deviceLatestStateData.htc_0_line_voltage || null;
    return device;
  }

  mapNgc40Htc3TrendData(device: Ngc40htc3, includedData: IncludedRelationship[] = []): TrendDataUIModel {
    const result = cloneDeep(getChartSeriesData(DeviceType.Ngc40htc3));
    const deviceLatestActualConfigData = includedData.find(
      x =>
        device.relationships?.latest_actual_config?.data &&
        x.id === device.relationships?.latest_actual_config?.data?.id,
    )?.attributes as Ngc40htc3_actual_configAttributes;

    device.relationships?.states?.data.forEach((state: any) => {
      const latestStateAttributes = includedData.find(x => x.id === state.id)
        ?.attributes as Ngc40htc3_latest_stateAttributes;
      if (
        latestStateAttributes.htc_0_control_temperature &&
        latestStateAttributes.htc_0_temperature_source_0_value &&
        latestStateAttributes.htc_0_temperature_source_1_value &&
        deviceLatestActualConfigData.htc_0_control_temperature_setpoint
      ) {
        const htc3TemperatureSeriesAttributes: string[] = [];
        temperatureSeriesAttributes.map(attribute => {
          if (
            attribute !== TemperatureSeriesAttributes.sensorTemperature3 &&
            attribute !== TemperatureSeriesAttributes.sensorTemperature4
          ) {
            htc3TemperatureSeriesAttributes.push(attribute);
          }
        });
        htc3TemperatureSeriesAttributes.forEach((attribute, index) => {
          const dataObj = {
            x: latestStateAttributes.created_at ? new Date(latestStateAttributes.created_at) : new Date(),
            y:
              attribute === TemperatureSeriesAttributes.controlTemperature
                ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_control_temperature)
                : attribute === TemperatureSeriesAttributes.sensorTemperature1
                  ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_temperature_source_0_value)
                  : attribute === TemperatureSeriesAttributes.sensorTemperature2
                    ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_temperature_source_1_value)
                    : this.conversionService.getDigitFromValue(
                        deviceLatestActualConfigData.htc_0_control_temperature_setpoint,
                      ),
          };
          result.temperatureChart[index].x.push(dataObj.x);
          result.temperatureChart[index].y.push(dataObj.y);
        });
      }
      if (latestStateAttributes.htc_0_line_current_0_effective && latestStateAttributes.htc_0_ground_fault_current) {
        currentSeriesAttributes.forEach((attribute, index) => {
          const dataObj = {
            x: latestStateAttributes.created_at ? new Date(latestStateAttributes.created_at) : new Date(),
            y:
              attribute === CurrentSeriesAttributes.lineCurrent
                ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_line_current_0_effective)
                : this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_ground_fault_current),
          };
          result.currentChart![index].x.push(dataObj.x);
          result.currentChart![index].y.push(dataObj.y);
        });
      }

      if (
        latestStateAttributes.htc_0_line_voltage &&
        latestStateAttributes.htc_0_power_consumption_0 &&
        latestStateAttributes.htc_0_switch_state !== undefined &&
        latestStateAttributes.htc_0_switch_state !== null
      ) {
        powerSeriesAttributes.forEach((attribute, index) => {
          const dataObj = {
            x: latestStateAttributes.created_at ? new Date(latestStateAttributes.created_at) : new Date(),
            y:
              attribute === PowerSeriesAttributes.lineVoltage
                ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_line_voltage)
                : attribute === PowerSeriesAttributes.power
                  ? this.conversionService.getDigitFromValue(latestStateAttributes.htc_0_power_consumption_0)
                  : Number(latestStateAttributes.htc_0_switch_state),
          };
          result.powerChart![index].x.push(dataObj.x);
          result.powerChart![index].y.push(dataObj.y);
        });
      }
    });
    return result;
  }

  appendHtc3TrendData(eventData: Event, preferenceUnit: string | undefined, setPoint: string | null): TrendDataUIModel {
    const result = cloneDeep(getChartSeriesData(DeviceType.Ngc40htc3));
    if (
      eventData.data.attributes?.htc_0_control_temperature &&
      eventData.data.attributes?.htc_0_temperature_source_0_value &&
      eventData.data.attributes?.htc_0_temperature_source_1_value
    ) {
      const htc3TemperatureSeriesAttributes: string[] = [];
      temperatureSeriesAttributes.map(attribute => {
        if (
          attribute !== TemperatureSeriesAttributes.sensorTemperature3 &&
          attribute !== TemperatureSeriesAttributes.sensorTemperature4
        ) {
          htc3TemperatureSeriesAttributes.push(attribute);
        }
      });
      htc3TemperatureSeriesAttributes.forEach((attribute, index) => {
        const dataObj = {
          x: eventData.data.attributes?.created_at ? new Date(eventData.data.attributes?.created_at) : new Date(),
          y:
            attribute === TemperatureSeriesAttributes.controlTemperature
              ? preferenceUnit === TemperatureUnits.Celsius
                ? this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_control_temperature)
                : this.conversionService.getDigitFromValue(
                    this.conversionService.unitConversion(eventData.data.attributes?.htc_0_control_temperature),
                  )
              : attribute === TemperatureSeriesAttributes.sensorTemperature1
                ? preferenceUnit === TemperatureUnits.Celsius
                  ? this.conversionService.getDigitFromValue(
                      eventData.data.attributes?.htc_0_temperature_source_0_value,
                    )
                  : this.conversionService.getDigitFromValue(
                      this.conversionService.unitConversion(
                        eventData.data.attributes?.htc_0_temperature_source_0_value,
                      ),
                    )
                : attribute === TemperatureSeriesAttributes.sensorTemperature2
                  ? preferenceUnit === TemperatureUnits.Celsius
                    ? this.conversionService.getDigitFromValue(
                        eventData.data.attributes?.htc_0_temperature_source_1_value,
                      )
                    : this.conversionService.getDigitFromValue(
                        this.conversionService.unitConversion(
                          eventData.data.attributes?.htc_0_temperature_source_1_value,
                        ),
                      )
                  : preferenceUnit === TemperatureUnits.Celsius
                    ? this.conversionService.getDigitFromValue(setPoint)
                    : this.conversionService.getDigitFromValue(this.conversionService.unitConversion(setPoint)),
        };
        result.temperatureChart[index].x.push(dataObj.x);
        result.temperatureChart[index].y.push(dataObj.y);
      });
    }
    if (
      eventData.data.attributes?.htc_0_line_current_0_effective &&
      eventData.data.attributes?.htc_0_ground_fault_current
    ) {
      currentSeriesAttributes.forEach((attribute, index) => {
        const dataObj = {
          x: eventData.data.attributes?.created_at ? new Date(eventData.data.attributes?.created_at) : new Date(),
          y:
            attribute === CurrentSeriesAttributes.lineCurrent
              ? this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_line_current_0_effective)
              : this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_ground_fault_current),
        };
        result.currentChart![index].x.push(dataObj.x);
        result.currentChart![index].y.push(dataObj.y);
      });
    }

    if (
      eventData.data.attributes?.htc_0_line_voltage &&
      eventData.data.attributes?.htc_0_power_consumption_0 &&
      eventData.data.attributes?.htc_0_switch_state !== undefined &&
      eventData.data.attributes?.htc_0_switch_state !== null
    ) {
      powerSeriesAttributes.forEach((attribute, index) => {
        const dataObj = {
          x: eventData.data.attributes?.created_at ? new Date(eventData.data.attributes?.created_at) : new Date(),
          y:
            attribute === PowerSeriesAttributes.lineVoltage
              ? this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_line_voltage)
              : attribute === PowerSeriesAttributes.power
                ? this.conversionService.getDigitFromValue(eventData.data.attributes?.htc_0_power_consumption_0)
                : Number(eventData.data.attributes?.htc_0_switch_state),
        };

        result.powerChart![index].x.push(dataObj.x);
        result.powerChart![index].y.push(dataObj.y);
      });
    }

    return result;
  }
}
