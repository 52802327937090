import { Injectable } from '@angular/core';
import { KeycloakService } from '@services/keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class AuthUtilityService {
  isAuthenticated!: boolean;
  constructor(private keycloakService: KeycloakService) {}

  isUserAuthenticated() {
    this.isAuthenticated = this.keycloakService.isAuthenticated()!;
    return this.isAuthenticated;
  }
}
