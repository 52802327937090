import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';
import { ResourceIdentifierObject } from '@services/jsonapi-services/preference.service';
import { EventTypes } from '@utils/enums/event-enum';
import { webSocket } from 'rxjs/webSocket';

export interface ResourceObject {
  type: string;
  id: string;
  attributes?: Record<string, null>;
  relationships: {
    latest: {
      data: ResourceIdentifierObject;
    };
    alarm: {
      data: any;
    };
    device?: {
      data: ResourceIdentifierObject;
    };
    htc?: {
      data: ResourceIdentifierObject;
    };
    htc3?: {
      data: ResourceIdentifierObject;
    };
    slim?: {
      data: ResourceIdentifierObject;
    };
    io?: {
      data: ResourceIdentifierObject;
    };
    cm2000?: {
      data: ResourceIdentifierObject;
    };
    cm2000p?: {
      data: ResourceIdentifierObject;
    };
    htc910?: {
      data: ResourceIdentifierObject;
    };
    htc920?: {
      data: ResourceIdentifierObject;
    };
    ngc20?: {
      data: ResourceIdentifierObject;
    };
    elexant5010i?: {
      data: ResourceIdentifierObject;
    };
    ac2000p?: {
      data: ResourceIdentifierObject;
    };
    elexant40x0?: {
      data: ResourceIdentifierObject;
    };
    elexant3500i?: {
      data: ResourceIdentifierObject;
    };
    latest_pulse?: {
      data: ResourceIdentifierObject;
    };
    user: {
      data: ResourceIdentifierObject;
    };
    parent_note: {
      data: ResourceIdentifierObject;
    };
    host?: {
      data: ResourceIdentifierObject;
    };
  };
}

export interface Event {
  op: string;
  data: ResourceObject;
}

export type EventCallback = (event: Event) => void;

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public static eventCallbacks: {
    [op: string]: { [eventId: number]: EventCallback };
  } = {};

  constructor(private appConfigService: AppConfigService) {
    this.initialiseEvent();
  }

  initialiseEvent() {
    const url = this.appConfigService.getJsonApiEventsUrl();
    const ws = webSocket(url);
    ws.subscribe({
      next: msg => {
        const event = msg as Event;
        const type = event.data.type;
        let eventName = '';
        switch (true) {
          case type.includes(EventTypes.LatestStates):
            eventName = EventTypes.LatestStates + '_' + event.op;
            break;
          case type.includes(EventTypes.ActualConfigs):
            eventName = EventTypes.ActualConfigs + '_' + event.op;
            break;
          case type.includes(EventTypes.DesiredConfigs):
            eventName = EventTypes.DesiredConfigs + '_' + event.op;
            break;
          case type.includes(EventTypes.Devices):
            eventName = EventTypes.Devices + '_' + event.op;
            break;
          case type.includes(EventTypes.Preferences):
            eventName = EventTypes.Preferences + '_' + event.op;
            break;
          case type.includes(EventTypes.AlarmPulses):
            eventName = EventTypes.AlarmPulses + '_' + event.op;
            break;
          case type.includes(EventTypes.Alarms):
            eventName = EventTypes.Alarms + '_' + event.op;
            break;
          case type.includes(EventTypes.States) && !type.includes(EventTypes.LatestStates):
            eventName = EventTypes.States + '_' + event.op;
            break;
          case type.includes(EventTypes.Cm2000s):
            eventName = EventTypes.Cm2000s + '_' + event.op;
            break;
          case type.includes(EventTypes.Cm2000ps):
            eventName = EventTypes.Cm2000ps + '_' + event.op;
            break;
          case type.includes(EventTypes.Commands):
            eventName = EventTypes.Commands + '_' + event.op;
            break;
          case type.includes(EventTypes.Notes):
            eventName = EventTypes.Notes;
            break;
          case type.includes(EventTypes.Hosts):
            eventName = EventTypes.Hosts;
            break;
          default:
            break;
        }

        if (eventName in EventService.eventCallbacks) {
          for (const key in EventService.eventCallbacks[eventName]) {
            if (Object.prototype.hasOwnProperty.call(EventService.eventCallbacks[eventName], key)) {
              const callback = EventService.eventCallbacks[eventName][key];
              callback(event);
            }
          }
        }
      },
      error: (err: unknown) => {
        console.error(err);
      },
    });
  }

  register(op: string, callback: EventCallback): number {
    if (!(op in EventService.eventCallbacks)) {
      EventService.eventCallbacks[op] = {};
    }
    const eventId: number = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    EventService.eventCallbacks[op][eventId] = callback;
    return eventId;
  }

  deregister(op: string, eventId: number): void {
    delete EventService.eventCallbacks[op][eventId];
  }
}
