<div class="terms-approval-bg">
  <div class="terms-card">
    <div class="terms-text">
      <h1 class="terms-title">Terms of Use</h1>
      <p>
        THESE NVENT RAYCHEM ELEXANT SUPERVISOR (“RES”) TERMS OF USE (“TERMS”) GOVERN YOUR USE OF RES, INCLUDING ALL USER
        MANUALS, TECHNICAL MANUALS, AND ANY OTHER MATERIALS PROVIDED BY NVENT, IN PRINTED, ELECTRONIC, OR OTHER FORM,
        THAT DESCRIBE THE SOFTWARE OR ITS USE OR SPECIFICATIONS PROVIDED TO YOU FOR USE PURSUANT TO AND SUBJECT TO A
        SOFTWARE LICENSE AGREEMENT (THE “SOFTWARE LICENSE AGREEMENT”) BETWEEN NVENT THERMAL, LLC AND/OR ITS AFFILIATE
        (COLLECTIVELY “NVENT”) AND YOUR EMPLOYER OR OTHER PERSON OR ENTITY WHO OWNS OR OTHERWISE LAWFULLY CONTROLS THE
        COMPUTER OR EQUIPMENT ON WHICH RES IS INSTALLED.
      </p>

      <p>
        NVENT PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION
        THAT YOU ACCEPT AND COMPLY WITH THEM. BY CLICKING THE “ACCEPT” BUTTON OR CHECKING THE “ACCEPT” BOX ON THE ORDER
        FORM, AS THE CASE MAY BE, YOU (A) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS; AND
        (B) REPRESENT AND WARRANT THAT: (I) YOU ARE 18 YEARS OF AGE OR OLDER; AND (II) IF YOU ARE A CORPORATION,
        GOVERNMENTAL ORGANIZATION, OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS
        AGREEMENT ON BEHALF OF YOUR ORGANIZATION OR ENTITY AND BIND IT TO ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS,
        NVENT WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO YOU AND YOU MUST NOT ACCESS, DOWNLOAD OR INSTALL THE
        SOFTWARE.
      </p>

      <p>
        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS OR YOUR ACCEPTANCE OF THEM, NO LICENSE IS GRANTED
        (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER THESE TERMS, AND THESE TERMS EXPRESSLY EXCLUDES ANY
        RIGHT CONCERNING ANY SOFTWARE THAT YOU DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED COPY OF
        NVENT’S SOFTWARE.
      </p>

      <ol type="1">
        <li>
          <b>Proprietary Rights.</b> Subject to these Terms, nVent grants to you a personal, non-exclusive,
          nontransferable, limited and revocable license (without the right to sublicense others) to use nVent RAYCHEM
          Elexant Supervisor (“RES”) software as installed on the equipment provided to you. You acknowledge and agree
          that RES is provided under license, and not sold, to you. Your license confers no title or ownership in RES
          and should not be construed as a sale of any rights in RES. nVent reserves and shall retain all and sole
          right, title, and interest, including the copyright, in and to RES. nVent reserves any rights not expressly
          granted herein.
        </li>
        <br />
        <li>
          <b>Your Responsibilities.</b> You agree that (i) you are solely responsible for any use of RES, including any
          other user that you permit to use RES; and (ii) nVent shall not be liable to any user that you permit to use
          RES. Specifically, and without limiting the generality of the foregoing, you are responsible and liable for
          all actions and failures to take required actions with respect to RES by you or by any other person to whom
          you may provide access to or use of RES, whether such access or use is permitted by or in violation of these
          Terms. <br /><br />
          You shall use commercially reasonable efforts to safeguard RES (including all copies thereof) from
          infringement, misappropriation, theft, misuse, or unauthorized access. You acknowledge and agree that: (i)
          these Terms apply to RES including any updates or supplements to RES unless such updates or supplements come
          with separate terms, in which case those terms apply; and (ii) RES is only to be used as a tool to assist with
          monitoring your temperature-critical assets and you must separately monitor (and continue to monitor) your
          temperature-critical assets in accordance with current procedures.
          <br /><br />
          nVent does not oversee your use or entity's use of RES. Accordingly, you are responsible for your use of RES,
          including but not limited to: (i) using a secure and non-obvious password; (ii) not sharing your password with
          others; (iii) periodically changing your password; (iv) logging out after each session; (v) granting access
          only to appropriate users; (vi) not altering, corrupting, hacking, or violating any RES computer code
          affecting the security and access controls of RES; and (vii) complying with all applicable laws, rules, and
          regulations. You will be automatically logged out after a default period of 15 minutes of inactivity. This
          default period can be modified in the RES settings by system administrators.
          <br /><br />
          In addition, nVent does not verify the identity of users entered into RES by system administrators.
        </li>
        <br />
        <li>
          <b>Restrictions.</b> RES is protected by law, including, without limitation, the copyright laws of the United
          States and other countries and by international treaty provisions. The structure, organization and code of RES
          are valuable trade secrets and confidential information of nVent. To that end, when using RES you must not (i)
          use RES in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms, or
          act fraudulently; (ii) take any activity which infringes upon (or has the risk of infringing upon) the
          intellectual property rights (such as the design rights) of nVent or any third party in relation to your use
          of RES; (iii) not carry out any activity which unjustly prejudices and/or slanders nVent or any third party
          and/or any activity which damages the reputation and/or trustworthiness of nVent or any third party; (iv) not
          carry out any activity connected to (or has the risk of being connected to fraud or any other criminal
          activity; or (v) not carry out any other activity which is against the law, or is a violation of public order.
          <br /><br />
          Furthermore, you agree not to modify, alter, merge, adapt, duplicate, distribute, translate, decompile,
          disassemble, reverse engineer (except to the extent that applicable laws prohibit reverse engineering
          restrictions, and then only as permitted by such laws), create derivative works, copy for use on any other
          computer or at any other location, or otherwise make RES available to any person or entity, in whole or in
          part. Further, you may not combine RES or any part thereof with, or incorporate RES or any part thereof in,
          any other programs. In no event may you transfer, sell, sublicense, rent, assign or transfer rights, lease, or
          otherwise dispose of or make available RES on a temporary or permanent basis, whether or not over a network or
          on a hosted basis, including in connection with the internet or any web hosting, wide area network (WAN),
          virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud, or
          other technology or service, without the prior written consent of nVent. Moreover, you may not use RES for
          purposes of competitive analysis of RES, the development of a competing software product or service, or any
          other purpose that is to nVent’s commercial disadvantage. You agree to use reasonable efforts to protect
          against the unauthorized copying and use of RES by others.
          <br /><br />
          You agree not to remove, disable or circumvent any proprietary notices or labels contained on or within RES.
          You agree not to copy any ideas, features, functions or graphics of RES. Additionally, RES may contain
          technological copy protection or other security features designed to prevent unauthorized use, including
          features to protect against any use that is prohibited by these Terms. You shall not, and shall not attempt
          to, remove, disable, circumvent, or otherwise create or implement any workaround to, any such copy protection
          or security feature.
        </li>
        <br />
        <li>
          <b>DISCLAIMER OF WARRANTY.</b> RES (INCLUDING ANY ACCOMPANYING WRITTEN MATERIALS OR FILES) IS PROVIDED “AS IS”
          AND “AS AVAILABLE” WITHOUT WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING WITHOUT LIMITATION, WARRANTIES
          OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF NON-INFRINGEMENT AND OF FITNESS FOR A PARTICULAR PURPOSE (EVEN
          IF NVENT HAS BEEN ADVISED OF SUCH PURPOSE). NVENT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN RES WILL
          MEET YOUR REQUIREMENTS, OR THAT THE OPERATION OF RES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT RES IS
          VIRUS-FREE, OR THAT DEFECTS WILL BE CORRECTED, OR THAT RES WILL BE COMPATIBLE WITH ALL OR ANY HARDWARE AND
          PROGRAMS WHICH YOU MAY USE. NVENT MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND RELATED TO THE INTEGRITY,
          TIMELINESS, RELIABILITY AND ACCURACY OF THE SOFTWARE. <br /><br />
          You acknowledge that RES has not been developed to meet Your individual requirements, and that it is therefore
          Your responsibility to ensure that the facilities and functions of RES meet Your requirements.
        </li>
        <br />
        <li>
          <b>LIMITATION OF LIABILITY.</b> IN NO EVENT SHALL NVENT, ITS AFFILIATES, DIRECTORS, OFFICERS, SHAREHOLDERS,
          EMPLOYEES OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DELAY; LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE
          FAILURE, MALFUNCTION, OR SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT INFORMATION; FAILURE TO
          UPDATE OR PROVIDE CORRECT INFORMATION; SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY
          INFORMATION; BREACHES IN SYSTEM SECURITY; LOST PROFITS; LOSS OF USE OR SERVICES; LOSS OR CORRUPTION OF DATA;
          LOST GOODWILL; INTERRUPTION OR LOSS OF BUSINESS; COSTS OF SUBSTITUTE GOODS OR SERVICES; OR FOR ANY SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, PUNITIVE, INDIRECT DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THE USE
          OF RES, OR THE INABILITY TO USE RES, OR FOR ANY DATA ENTERED OR OBTAINED FROM OR THROUGH RES, EVEN IF NVENT
          HAD BEEN ADVISED OF SUCH DAMAGES. FURTHER, IN NO EVENT SHALL NVENT, ITS AFFILIATES, DIRECTORS, OFFICERS,
          SHAREHOLDERS, EMPLOYEES OR OTHER REPRESENTATIVES BE LIABLE TO YOU IN AN AMOUNT GREATER THE AMOUNTS ACTUALLY
          PAID BY AND/OR DUE FROM YOU IN THE TWELVE MONTHS PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH
          LIABILITY OR $100.00, WHICHEVER IS GREATER. THE FOREGOING LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS SHALL APPLY
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.
        </li>
        <br />
        <li>
          <b>Indemnity.</b> To the extent allowed under federal and state law, you agree to indemnify and hold nVent,
          its parents, subsidiaries, affiliates, officers, employees, sponsors and partners harmless from any claim,
          loss, cost, expense, demand, or damage, including reasonable attorneys' fees, arising directly or indirectly
          out of (i) a violation of any provision of these Terms, or (ii) your use or misuse of RES.
        </li>
        <br />
        <li>
          <b>Submissions.</b> nVent welcomes your comments, feedback, and suggestions about how to improve RES, or about
          any "bugs," defects, or seemingly incorrect or anomalous results discovered or produced through the use of
          RES. However, you agree that by sending nVent any idea, suggestion, information, know-how, material, data,
          comment or observation about an incident, action, event, or process or any other content in relation to RES
          (collectively, "Submissions"), such Submission becomes the property of nVent and nVent may, at its option, use
          any Submission (or any modification or derivative thereof) without any obligation to you. nVent shall
          exclusively own all rights, title and interest, including all intellectual property rights, in the
          Submissions. In the event that any intellectual property rights in Submissions is deemed for any reason not to
          be exclusively owned by nVent, you agree to assign, transfer and convey to nVent, and hereby assign, transfer
          and convey to nVent, all right, title and interest, including intellectual property rights, in such
          Submissions, and agree to provide reasonable cooperation to nVent to perfect such rights.
        </li>
        <br />
        <li>
          <b>Notices.</b> Notices to you may be made via email, or certified or registered mail, return receipt
          requested, to the applicable address on record in the RES account information. RES may also provide notices of
          changes to these Terms of Use or other matters by providing a click through screen on RES. You may give notice
          to nVent by email to
          <a href="mailto: ElexantSupervisorSupport@nVent.com">ElexantSupervisorSupport&#64;nVent.com</a>
        </li>
        <br />
        <li>
          <b>Modification.</b> nVent reserves the right, at any time and from time to time, to update, revise,
          supplement, and otherwise modify these Terms. Such updates, revisions, supplements and other modifications
          will be effective immediately upon publication. Your continued use of RES after publication of updated version
          of the Terms will be deemed to constitute your acceptance of such updates, revisions, supplements, and/or
          other modifications.
        </li>
        <br />
        <li>
          <b>Privacy.</b> For more information on how we use your personal data, please read our Privacy Statement
          available at
          <a href="https://www.nvent.com/en-us/privacy-policy"> https://www.nvent.com/en-us/privacy-policy </a>
          or provided in writing to you by nVent.
        </li>
        <br />
        <li>
          <b>Duration of Agreement.</b> These Terms are effective from the earliest date you install or use RES. The
          license granted herein and these Terms will terminate immediately on the earlier to occur of: (i) the
          expiration or earlier termination of the Software License Agreement between nVent and your employer; or (b)
          your ceasing to be authorized by your employer to use RES for any or no reason. <br /><br />

          Further, nVent may terminate these Terms (in whole or in part) at any time with immediate effect on written
          notice to you. In addition, these Terms will terminate automatically (i) if you fail to comply with any
          provision of these Terms; or (ii) if you give nVent false information or withhold from nVent important
          information in relation to your use of RES.
          <br /><br />
          Upon termination of these Terms for any reason, the license granted hereunder shall also terminate, and you
          shall immediately cease using and uninstall RES and destroy all copies of RES in your possession (including
          all written materials or files, if any). Termination will not limit any of nVent's rights or remedies at law
          or in equity.
        </li>
        <br />
        <li>
          <b>Export Laws.</b> If RES is for use outside of the United States, you agree to comply fully with all
          relevant regulations of the United States Department of Commerce and with the United States Export
          Administration Act to ensure that RES is not exported in violation of United States law. You agree that RES
          will not be exported, shipped, transmitted, or re-exported in violation of any applicable law or regulation,
          including without limitation Export Administration regulations issued by the U.S. Department of Commerce, or
          any other export laws, restrictions or regulations (collectively, the “Export Laws”). In addition, if RES is
          identified as an export-controlled item under the Export Laws, you represent and warrant that you are not a
          citizen, or otherwise located within, any U.S. embargoed nation and that you are not otherwise prohibited
          under the Export Laws from receiving RES. All rights to use RES are granted on condition that such rights are
          forfeited if you fail to comply with these Terms. You agree to indemnify nVent and assume all financial
          responsibility for any losses it may suffer if you do not comply with the terms of this Section 12.
        </li>
        <br />
        <li>
          <b> US Government Rights.</b> Each of the software components and documentation that constitute the software
          is a “commercial product” as that term is defined at 48 C.F.R. 2.101, consisting of “commercial computer
          software” and “commercial computer software documentation” as such terms are used in 48 C.F.R. 12.212.
          Accordingly, if you are an agency of the US Government or any contractor therefor, you only receive those
          rights with respect to the software and documentation as are granted to all other end users under license, in
          accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the Department of Defense
          and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other US Government licensees and their
          contractors.
        </li>
        <br />
        <li>
          <b> Third-Party Materials.</b> RES includes software, content, data, or other materials, including related
          documentation, that are owned by persons other than nVent and that are provided to you on licensee terms that
          are in addition to and/or different from those contained herein (“Third-Party Licenses”). A list of all such
          materials included in RES and provided under Third-Party Licenses can be found in the software under the About
          tab located in the navigation bar, and the applicable Third-Party Licenses are accessible via links therefrom.
          You are bound by and shall comply with all Third-Party Licenses. Any breach by you of any Third-Party License
          is also a breach of these Terms.
        </li>
        <br />
        <li>
          <b> General Terms.</b> These Terms, along with the Software License Agreement, are the entire agreement
          between you and your employer and nVent and govern your use of RES. Any affiliate of nVent may seek to enforce
          these Terms to the extent they confer a benefit upon it. nVent in its sole discretion may terminate your
          account without notice if you breach or otherwise fail to comply with these Terms. The failure of a party to
          exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or
          provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the
          court should endeavor to give effect to the parties' intentions to the maximum extent permitted by law, and
          the other provisions of these Terms remain in full force and effect. These Terms, your use of RES, and any
          dispute of any sort that might arise between you and nVent shall be governed by applicable federal law and the
          laws of the state of Texas, USA, without regard to the choice or conflicts of law provisions of any
          jurisdiction. Any dispute or claim relating in any way to your use of RES will be adjudicated in the state or
          federal courts in Harris County, Texas, and you consent to exclusive jurisdiction and venue in these courts.
          We each waive any right to a jury trial. You agree that regardless of any statute or law to the contrary, any
          claim or cause of action arising out of or related to use of RES or these Terms must be filed within one year
          after such claim or cause of action arose or be forever barred. With respect to an unauthorized user, nVent
          may bring any action against you in the state of Texas, and there are no time limitations with respect to any
          action by nVent except under the applicable statute of limitations.
        </li>
      </ol>

      <p>Rev. 08/2023</p>
      <div class="center">
        <button id="accept-button" class="btn btn-red" (click)="onAccept()">Accept and Continue</button>
        <button class="btn btn-black" (click)="redirectToLogin()">Decline</button>
      </div>
    </div>
  </div>
</div>
