import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';

interface Cm2000pAttributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'cm2000p_actual_configs',
  desired_config: 'cm2000p_desired_configs',
  device: 'devices',
  info: 'cm2000p_infos',
  latest_actual_config: 'cm2000p_actual_configs',
  latest_state: 'cm2000p_latest_states',
  states: 'cm2000p_states',
};

export interface Cm2000p_actual_configAttributes {
  htc_0_control_temperature_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
  htc_0_maximum_power_setpoint?: string;
}

export interface Cm2000p_latest_stateAttributes {
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_line_voltage?: string | null;
  htc_0_power_consumption_0?: string | null;
  htc_0_load_current?: string | null;
}

export interface Cm2000p_latest_stateRelationships {
  Cm2000p?: { data: Cm2000p };
}

export interface Cm2000p_latest_actual_config extends Base {
  id: string;
  attributes?: Cm2000p_actual_configAttributes;
}

export interface Cm2000p_latest_state extends Base {
  id: string;
  attributes?: Cm2000p_latest_stateAttributes;
  relationships?: Cm2000p_latest_stateRelationships;
}

export interface Cm2000pRelationships {
  actual_configs?: { data: Cm2000p_latest_actual_config[] };
  desired_config?: { data: ResourceIdentifierObject[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Cm2000p_latest_actual_config };
  latest_state?: { data: Cm2000p_latest_state };
  states?: { data: unknown[] };
}

export interface Cm2000p extends Base {
  id: string;
  type: string;
  attributes?: Cm2000pAttributes;
  relationships?: Cm2000pRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Cm2000pData {
  data: Cm2000p;
  included?: IncludedRelationship[];
}

export interface Cm2000psData {
  data: Cm2000p[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Cm2000pService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getCm2000ps(params: HttpParams): Observable<Cm2000psData> {
    const url = `${this.uiBaseURL}/cm2000ps`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000psData>(url, options);
  }

  getCm2000p(id: string, params: HttpParams): Observable<Cm2000pData> {
    const url = `${this.uiBaseURL}/cm2000ps/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000pData>(url, options);
  }

  addCm2000p(cm2000p: Cm2000p): Observable<Cm2000p> {
    const url = `${this.uiBaseURL}/cm2000ps`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Cm2000p>(url, { data: cm2000p }, options);
  }

  updateCm2000p(cm2000p: Cm2000p): Observable<Cm2000pData> {
    const url = `${this.uiBaseURL}/cm2000ps/` + cm2000p.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Cm2000pData>(url, { data: cm2000p }, options);
  }

  removeCm2000p(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/cm2000ps/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getCm2000pTrendData(params: HttpParams, id: string): Observable<Cm2000psData> {
    const url = `${this.uiBaseURL}/cm2000ps/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000psData>(url, options);
  }
}
