import { ChartOptions } from '@models/apex-chart';
import { PlotlyChartData, PlotlyChartLayout, PlotlyChartModel } from '@models/plotly-chart';

export const defaultChartOptions: ChartOptions = {
  chart: {
    type: 'line',
    height: 350,
    stacked: false,
  },
  title: {
    text: '',
  },
  dataLabels: {},
  legend: {},
  markers: {},
  series: [],
  stroke: {},
  tooltip: {},
  xAxis: {},
  yAxis: [],
};

export const defaultPlotlyChartData: PlotlyChartData = {
  type: '',
  x: [],
  y: [],
  mode: '',
  name: '',
  yaxis: '',
  visible: true,
  showlegend: true,
  transforms: [
    {
      type: 'sort',
      target: 'x',
      order: 'ascending',
    },
  ],
  line: {},
};

export const defaultPlotlyChartLayout: PlotlyChartLayout = {
  title: '',
  hovermode: '',
  connectgaps: false,
};

export const defaultPlotlyLayoutOptions = {
  width: 1000,
  height: 500,
};

export const emptyPlotlyChart: PlotlyChartModel = {
  data: [],
  layout: {},
};

export const defaultPlotlyChart: PlotlyChartModel = {
  data: [defaultPlotlyChartData],
  layout: defaultPlotlyChartLayout,
};
