import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Elexant5010i_actual_config,
  Elexant5010i_actual_configData,
  MultiElexant5010i_actual_configData,
} from '@app/jsonapi-models/actual-configs/elexant5010i-actual-config-model';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';

@Injectable({
  providedIn: 'root',
})
export class Elexant5010i_actual_configService {
  uiBaseUrl = '';

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseUrl = this.appConfigService.getJsonApiUrl();
  }

  getElexant5010i_actual_configs(params: HttpParams): Observable<MultiElexant5010i_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant5010i_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiElexant5010i_actual_configData>(url, options);
  }

  getElexant5010i_actual_config(id: string, params: HttpParams): Observable<Elexant5010i_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant5010i_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Elexant5010i_actual_configData>(url, options);
  }

  addElexant5010i_actual_config(
    elexant5010i_actual_config: Elexant5010i_actual_config,
  ): Observable<Elexant5010i_actual_config> {
    const url = `${this.uiBaseUrl}/elexant5010i_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<never>(url, { data: elexant5010i_actual_config }, options);
  }

  updateElexant5010i_actual_config(
    elexant5010i_actual_config: Elexant5010i_actual_config,
  ): Observable<Elexant5010i_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant5010i_actual_configs/` + elexant5010i_actual_config.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.patch<Elexant5010i_actual_configData>(url, { data: elexant5010i_actual_config }, options);
  }

  removeElexant5010i_actual_config(id: string): Observable<never> {
    const url = `${this.uiBaseUrl}/elexant5010i_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
