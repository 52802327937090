import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base } from '@models/device';
import { AppConfigService } from '@services/app-config.service';
import { Ngc40htc } from '@services/jsonapi-services/ngc40htc.service';
import { Ngc40htc_desired_config } from '@services/jsonapi-services/ngc40htc_desired_config.service';

export type Ngc40htc_actual_configAttributes = {
  alarm_0_mode?: string;
  alarm_0_toggle_time?: string;
  created_at?: string;
  device_broadcast_timeout?: string;
  device_reset_mask?: boolean;
  device_tag?: string;
  digital_input_0_path?: string | null;
  digital_input_source_0_alarm_latch?: boolean;
  digital_input_source_0_failure_latch?: boolean;
  digital_input_source_0_failure_mask?: boolean;
  digital_input_source_0_mode?: string;
  htc_0_auto_cycle_interval?: string;
  htc_0_circuit_breaker_0_current_rating?: string;
  htc_0_circuit_breaker_0_limiting_latch?: boolean;
  htc_0_circuit_breaker_0_limiting_mask?: boolean;
  htc_0_circuit_breaker_0_type?: string;
  htc_0_configured_line_frequency?: string;
  htc_0_configured_line_voltage?: string;
  htc_0_contactor_cycle_count_limit_exceeded_mask?: boolean;
  htc_0_contactor_cycle_count_setpoint?: number;
  htc_0_control_temperature_failure_latch?: boolean;
  htc_0_control_temperature_high_alarm_filter?: string;
  htc_0_control_temperature_high_alarm_setpoint?: string;
  htc_0_control_temperature_high_latch?: boolean;
  htc_0_control_temperature_high_mask?: boolean;
  htc_0_control_temperature_low_alarm_filter?: string;
  htc_0_control_temperature_low_alarm_setpoint?: string;
  htc_0_control_temperature_low_latch?: boolean;
  htc_0_control_temperature_low_mask?: boolean;
  htc_0_control_temperature_setpoint?: string;
  htc_0_deadband?: string;
  htc_0_energize?: string;
  htc_0_ground_fault_current_high_alarm_filter?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_high_latch?: boolean;
  htc_0_ground_fault_current_high_mask?: boolean;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_ground_fault_transformer_failure_latch?: boolean;
  htc_0_ground_fault_transformer_failure_mask?: boolean;
  htc_0_ground_fault_trip_mask?: boolean;
  htc_0_heater_time_limit_exceeded_mask?: boolean;
  htc_0_heater_time_setpoint?: string;
  htc_0_high_limit_cutout_latch?: boolean;
  htc_0_high_limit_cutout_mask?: boolean;
  htc_0_high_limit_cutout_setpoint?: boolean;
  htc_0_line_current_0_high_alarm_filter?: string;
  htc_0_line_current_0_high_alarm_setpoint?: string;
  htc_0_line_current_0_high_latch?: boolean;
  htc_0_line_current_0_high_mask?: boolean;
  htc_0_line_current_0_low_alarm_filter?: string;
  htc_0_line_current_0_low_alarm_setpoint?: string;
  htc_0_line_current_0_low_latch?: boolean;
  htc_0_line_current_0_low_mask?: boolean;
  htc_0_line_current_0_maximum?: string;
  htc_0_line_current_0_over_mask?: boolean;
  htc_0_load_shed_enabled?: boolean;
  htc_0_load_shed_fail_safe_enabled?: boolean;
  htc_0_load_shed_source_failure_latch?: boolean;
  htc_0_load_shed_source_failure_mask?: boolean;
  htc_0_output_0_limiting_latch?: boolean;
  htc_0_output_0_limiting_mask?: boolean;
  htc_0_output_limit_mode?: string;
  htc_0_pasc_min_ambient_temperature?: string;
  htc_0_pasc_min_pipe_size?: string;
  htc_0_pasc_power_adjust?: string;
  htc_0_power_0_maximum_limit_setpoint?: string;
  htc_0_proportional_band?: string;
  htc_0_ssr_rating?: string;
  htc_0_switch_0_failure_latch?: boolean;
  htc_0_switch_0_failure_mask?: boolean;
  htc_0_switch_0_limiting_latch?: boolean;
  htc_0_switch_0_limiting_mask?: boolean;
  htc_0_switch_control_mode?: string;
  htc_0_temperature_control_mode?: string;
  htc_0_temperature_fail_mode?: string;
  htc_0_temperature_fail_mode_percentage?: string;
  htc_0_temperature_source_0_control_enabled?: boolean;
  htc_0_temperature_source_0_failure_latch?: boolean;
  htc_0_temperature_source_0_high_cutout_enabled?: boolean;
  htc_0_temperature_source_0_path?: string;
  htc_0_temperature_source_1_control_enabled?: boolean;
  htc_0_temperature_source_1_failure_latch?: boolean;
  htc_0_temperature_source_1_high_cutout_enabled?: boolean;
  htc_0_temperature_source_1_path?: string;
  htc_0_temperature_source_2_control_enabled?: boolean;
  htc_0_temperature_source_2_failure_latch?: boolean;
  htc_0_temperature_source_2_high_cutout_enabled?: boolean;
  htc_0_temperature_source_2_path?: string;
  htc_0_temperature_source_3_control_enabled?: boolean;
  htc_0_temperature_source_3_failure_latch?: boolean;
  htc_0_temperature_source_3_high_cutout_enabled?: boolean;
  htc_0_temperature_source_3_path?: string;
  htc_0_temperature_source_4_control_enabled?: boolean;
  htc_0_temperature_source_4_failure_latch?: boolean;
  htc_0_temperature_source_4_high_cutout_enabled?: boolean;
  htc_0_temperature_source_4_path?: string;
  htc_0_temperature_source_5_control_enabled?: boolean;
  htc_0_temperature_source_5_failure_latch?: boolean;
  htc_0_temperature_source_5_high_cutout_enabled?: boolean;
  htc_0_temperature_source_5_path?: string;
  htc_0_temperature_source_6_control_enabled?: boolean;
  htc_0_temperature_source_6_failure_latch?: boolean;
  htc_0_temperature_source_6_high_cutout_enabled?: boolean;
  htc_0_temperature_source_6_path?: string;
  htc_0_temperature_source_7_control_enabled?: boolean;
  htc_0_temperature_source_7_failure_latch?: boolean;
  htc_0_temperature_source_7_high_cutout_enabled?: boolean;
  htc_0_temperature_source_7_path?: string;
  htc_0_tracing_resistance_0_high_alarm_filter?: string;
  htc_0_tracing_resistance_0_high_deviation?: string;
  htc_0_tracing_resistance_0_high_latch?: boolean;
  htc_0_tracing_resistance_0_high_mask?: boolean;
  htc_0_tracing_resistance_0_low_alarm_filter?: string;
  htc_0_tracing_resistance_0_low_deviation?: string;
  htc_0_tracing_resistance_0_low_latch?: boolean;
  htc_0_tracing_resistance_0_low_mask?: boolean;
  htc_0_tracing_resistance_0_nominal?: string;
  limiter_0_communication_failure_latch?: boolean;
  limiter_0_communication_failure_mask?: boolean;
  limiter_0_trip_latch?: boolean;
  limiter_0_trip_mask?: boolean;
  nvram_0_factory_configuration_data_lost_mask?: boolean;
  nvram_0_user_configuration_data_lost_mask?: boolean;
  temperature_0_failure_latch?: boolean;
  temperature_0_high_alarm_filter?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_high_latch?: boolean;
  temperature_0_high_mask?: boolean;
  temperature_0_lead_resistance?: string;
  temperature_0_low_alarm_filter?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_0_low_latch?: boolean;
  temperature_0_low_mask?: boolean;
  temperature_0_tag?: string;
  temperature_0_type?: string;
};

interface Ngc40htc_actual_configRelationships {
  bases?: { data: Ngc40htc_desired_config[] };
  complete?: { data: Ngc40htc_desired_config[] };
  htc?: { data: Ngc40htc };
  latest?: { data: Ngc40htc };
}

export interface Ngc40htc_actual_config extends Base {
  id: string;
  attributes?: Ngc40htc_actual_configAttributes;
  relationships?: Ngc40htc_actual_configRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc40htc_actual_configData {
  data: Ngc40htc_actual_config;
  included?: ResourceIdentifierObject[];
}

// TODO {.Plural | pascal}
export interface Ngc40htc_actual_configsData {
  data: Ngc40htc_actual_config[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class Ngc40htc_actual_configService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40htc_actual_configs(params: HttpParams): Observable<Ngc40htc_actual_configsData> {
    const url = `${this.uiBaseURL}/ngc40htc_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htc_actual_configsData>(url, options);
  }

  getNgc40htc_actual_config(id: string, params: HttpParams): Observable<Ngc40htc_actual_configData> {
    const url = `${this.uiBaseURL}/ngc40htc_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htc_actual_configData>(url, options);
  }

  addNgc40htc_actual_config(ngc40htc_actual_config: Ngc40htc_actual_config): Observable<Ngc40htc_actual_config> {
    const url = `${this.uiBaseURL}/ngc40htc_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40htc_actual_config>(url, { data: ngc40htc_actual_config }, options);
  }

  updateNgc40htc_actual_config(ngc40htc_actual_config: Ngc40htc_actual_config): Observable<Ngc40htc_actual_configData> {
    const url = `${this.uiBaseURL}/ngc40htc_actual_configs/` + ngc40htc_actual_config.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40htc_actual_configData>(url, { data: ngc40htc_actual_config }, options);
  }

  removeNgc40htc_actual_config(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40htc_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
