import { cloneDeep } from 'lodash-es';
import { DeviceType } from '@utils/enums/device-enums';

export const alarmTypes: { [key: string]: string } = {
  HighTemperature: 'High Temperature',
  LowTemperature: 'Low Temperature',
  TemperatureInput: 'Temperature Input',
  HighLoadCurrent: 'High Load Current',
  LowLoadCurrent: 'Low Load Current',
  GroundFault: 'Ground Fault',
  Communications: 'Communications',
  LimitingOrTripsOrSwitchFailure: 'Limiting, Trips or Switch Failure',
  Other: 'Other',
  LoadVoltage: 'Load Voltage',
  LoadResistance: 'Load Resistance',
};

export const alarmTypeDescription: { [key: string]: string } = {
  HighTemperature:
    'High Temperature Alarm Type indicates the measured temperature(s) from connected temperature sensor(s) exceeds the High Temperature Alarm Setpoint.',
  LowTemperature:
    'Low Temperature Alarm Type indicates the measured temperature(s) from connected temperature sensor(s) drops below the Low Temperature Alarm Setpoint.',
  TemperatureInput:
    'Temperature Input Alarm Type is an indication of a temperature sensor failure due to either an open or shorted condition, or regular alarm status information cannot be obtained if an external temperature source is used.',
  HighLoadCurrent:
    'High Load Current Alarm Type indicates the measured output current exceeds the High Load Current Alarm Setpoint.',
  LowLoadCurrent:
    'Low Load Current Alarm Type indicates the measured output current falls below the Low Load Current Alarm Setpoint.',
  GroundFault:
    'Ground Fault Alarm Type indicates the measured ground fault leakage current levels are higher than the High Ground Fault Current Alarm Setpoint or the Ground Fault Trip Alarm Setpoint.',
  Communications:
    'Communication Alarm Type indicates communications with field devices has failed due to the device going offline or lost of connectivity due to wiring issues.',
  LimitingOrTripsOrSwitchFailure:
    'Limiting, Trips or Switch Failures Alarm Types include alarms for fault monitoring, trip and switch protection of heat-tracing circuit.',
  Other:
    'Other Alarm Type covers a broad range of conditions relating to the operating status and conditon of a heat trace controller, for example Device has reset, Contactor count exceed maximum, Factory configuration lost, ..etc.  Any alarm/warning conditions not already covered in the existing Alarm Types will fall into this category.',
  LoadVoltage:
    'Load Voltage Alarm Type indicates the measured Load Voltage exceeds the High Load Voltage Alarm Setpoint or the measured Load Voltage drops below the Low Voltage Alarm Setpoint.',
  LoadResistance:
    'Load Resistance Alarm Type indicates the measured Load Resistance exceeds the High Load Resistance Alarm Setpoint or the measured Load Resistance drops below the Low Load Resistance Alarm Setpoint.',
};

const alarmTypesMapping = {
  HighTemperature: [
    'htc_0_high_limit_cutout',
    'htc_0_control_temperature_high',
    'temperature_0_high',
    'temperature_1_high',
    'temperature_2_high',
    'temperature_3_high',
  ],
  LowTemperature: [
    'htc_0_control_temperature_low',
    'temperature_0_low',
    'temperature_1_low',
    'temperature_2_low',
    'temperature_3_low',
  ],
  TemperatureInput: [
    'htc_0_control_temperature_failure',
    'htc_0_temperature_source_0_failure',
    'htc_0_temperature_source_1_failure',
    'htc_0_temperature_source_2_failure',
    'htc_0_temperature_source_3_failure',
    'htc_0_temperature_source_4_failure',
    'htc_0_temperature_source_5_failure',
    'htc_0_temperature_source_6_failure',
    'htc_0_temperature_source_7_failure',
    'temperature_3_failure',
    'alarm_source_0_failure',
    'alarm_source_1_failure',
    'alarm_source_2_failure',
    'alarm_source_3_failure',
    'alarm_source_4_failure',
    'alarm_source_5_failure',
    'alarm_source_6_failure',
    'alarm_source_7_failure',
    'alarm_source_8_failure',
    'alarm_source_9_failure',
    'alarm_source_10_failure',
    'alarm_source_11_failure',
    'alarm_source_12_failure',
    'alarm_source_13_failure',
    'alarm_source_14_failure',
    'alarm_source_15_failure',
    'alarm_source_16_failure',
    'alarm_source_17_failure',
    'alarm_source_18_failure',
    'alarm_source_19_failure',
    'alarm_source_20_failure',
    'alarm_source_21_failure',
    'alarm_source_22_failure',
    'alarm_source_23_failure',
    'alarm_source_24_failure',
    'alarm_source_25_failure',
    'alarm_source_26_failure',
    'alarm_source_27_failure',
    'alarm_source_28_failure',
    'alarm_source_29_failure',
    'alarm_source_30_failure',
    'alarm_source_31_failure',
    'alarm_source_32_failure',
    'alarm_source_33_failure',
    'alarm_source_34_failure',
    'alarm_source_35_failure',
    'alarm_source_36_failure',
    'alarm_source_37_failure',
    'alarm_source_38_failure',
    'alarm_source_39_failure',
    'alarm_source_40_failure',
    'alarm_source_41_failure',
    'alarm_source_42_failure',
    'alarm_source_43_failure',
    'alarm_source_44_failure',
    'alarm_source_45_failure',
    'alarm_source_46_failure',
    'alarm_source_47_failure',
    'alarm_source_48_failure',
    'alarm_source_49_failure',
    'alarm_source_50_failure',
    'alarm_source_51_failure',
    'alarm_source_52_failure',
    'alarm_source_53_failure',
    'alarm_source_54_failure',
    'alarm_source_55_failure',
    'alarm_source_56_failure',
    'alarm_source_57_failure',
    'alarm_source_58_failure',
    'alarm_source_59_failure',
    'alarm_source_60_failure',
    'alarm_source_61_failure',
    'alarm_source_62_failure',
    'alarm_source_63_failure',
    'alarm_source_64_failure',
    'alarm_source_65_failure',
    'alarm_source_66_failure',
    'alarm_source_67_failure',
    'alarm_source_68_failure',
    'alarm_source_69_failure',
    'alarm_source_70_failure',
    'alarm_source_71_failure',
    'alarm_source_72_failure',
    'alarm_source_73_failure',
    'alarm_source_74_failure',
    'alarm_source_75_failure',
    'alarm_source_76_failure',
    'alarm_source_77_failure',
    'alarm_source_78_failure',
    'alarm_source_79_failure',
  ],
  HighLoadCurrent: [
    'htc_0_line_current_0_high',
    'htc_0_line_current_1_high',
    'htc_0_line_current_2_high',
    'htc_0_load_current_high',
  ],
  LowLoadCurrent: [
    'htc_0_line_current_0_low',
    'htc_0_line_current_1_low',
    'htc_0_line_current_2_low',
    'htc_0_load_current_low',
  ],
  GroundFault: ['htc_0_ground_fault_current_high', 'htc_0_ground_fault_trip', 'htc_0_ground_fault_transformer_failure'],
  Communications: ['limiter_0_communication_failure', 'htc_0_communication_failure'],
  LimitingOrTripsOrSwitchFailure: [
    'htc_0_output_0_limiting',
    'htc_0_output_1_limiting',
    'htc_0_output_2_limiting',
    'htc_0_circuit_breaker_0_limiting',
    'htc_0_circuit_breaker_1_limiting',
    'htc_0_circuit_breaker_2_limiting',
    'htc_0_switch_0_failure',
    'htc_0_switch_1_failure',
    'htc_0_switch_2_failure',
    'htc_0_line_current_0_over',
    'htc_0_line_current_1_over',
    'htc_0_line_current_2_over',
    'limiter_0_trip',
    'htc_0_power_limiting',
    'htc_0_switch_0_limiting',
  ],
  Other: [
    'device_reset',
    'htc_0_contactor_cycle_count_limit_exceeded',
    'digital_input_source_0_alarm',
    'digital_input_source_0_failure',
    'nvram_0_user_configuration_data_lost',
    'nvram_0_factory_configuration_data_lost',
    'htc_0_load_shed_source_failure',
    'htc_0_heater_time_limit_exceeded',
    'nvram_0_total_failure',
    'nvram_0_not_responding',
    'serial_0_hardware_failure',
  ],
  LoadResistance: [
    'htc_0_tracing_resistance_0_high',
    'htc_0_tracing_resistance_1_high',
    'htc_0_tracing_resistance_2_high',
    'htc_0_tracing_resistance_0_low',
    'htc_0_tracing_resistance_1_low',
    'htc_0_tracing_resistance_2_low',
  ],
  LoadVoltage: ['htc_0_line_voltage_high', 'htc_0_line_voltage_low'],
};

const tempFailureArticles = ['temperature_0_failure', 'temperature_1_failure', 'temperature_2_failure'];

export const getAlarmTypesMapping = (deviceType: string): { [key: string]: string[] } => {
  const alarmMapping = cloneDeep(alarmTypesMapping);
  if (deviceType === DeviceType.Ngc40slims) {
    alarmMapping.HighTemperature = [...alarmTypesMapping.HighTemperature, ...tempFailureArticles];
  } else {
    alarmMapping.TemperatureInput = [...alarmTypesMapping.TemperatureInput, ...tempFailureArticles];
  }
  return alarmMapping;
};
