import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { LocalStorageService } from '@services/local-storage.service';
import { NavComponent } from '@shared/nav/nav.component';

@Directive({
  selector: '[column-resizer]',
})
export class ColumnResizerDirective implements OnInit {
  @Output() setTable = new EventEmitter<string>();
  @Input() isResizingOn!: boolean;
  @Input() tableName!: string;
  @Input() columnName!: string;
  private tableContainer!: HTMLElement;
  private column: HTMLElement;
  private table!: HTMLElement;
  private pressed!: boolean;
  private lastResizerHelperX: number | undefined;
  private leftOffsetAfterResize: number = 0;
  private resizeHelper!: HTMLElement;
  private columnInitialWidth: number = 140;
  private lastLeftOffset = 0;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private navComponent: NavComponent,
    private localStorageService: LocalStorageService,
  ) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.isResizingOn) this.initializeResizer();
  }

  ngAfterViewInit() {
    const storedColumnsWidth = this.localStorageService.getItem(`${this.tableName}ColumnsWidth`);
    if (storedColumnsWidth && this.columnName in storedColumnsWidth)
      this.setColumnWidth(storedColumnsWidth[this.columnName]);
    const storedTableWidth = this.localStorageService.getItem(`${this.tableName}Width`);
    if (storedTableWidth) this.setTableWidth(storedTableWidth);
    this.setTable.emit();
  }

  resizerStart(pageX: number) {
    this.pressed = true;
    const containerLeft =
      this.tableContainer.offsetLeft + (document.documentElement.scrollLeft || document.body.scrollLeft || 0);
    this.lastResizerHelperX = pageX - containerLeft + this.tableContainer.scrollLeft;
    this.resizeHelper = this.renderer.selectRootElement('.column-resizer-helper');
  }

  resizerMove(pageX: number) {
    const containerLeft =
      this.tableContainer.offsetLeft + (document.documentElement.scrollLeft || document.body.scrollLeft || 0);
    this.leftOffsetAfterResize = pageX - containerLeft + this.tableContainer.scrollLeft;
    const newColumnWidth = this.column.offsetWidth + this.leftOffsetAfterResize - <number>this.lastResizerHelperX;
    if (newColumnWidth < this.columnInitialWidth || newColumnWidth > this.columnInitialWidth * 2) {
      this.renderer.setStyle(this.resizeHelper, 'cursor', 'not-allowed');
      this.renderer.setStyle(this.resizeHelper, 'background-color', 'red');
      this.leftOffsetAfterResize = this.lastLeftOffset;
    } else {
      const resizeHelperView: number = this.navComponent.isCollapsed() ? pageX - 64 : pageX - 240;
      this.renderer.addClass(this.tableContainer, 'unselectable-text');
      const resizeHelperStyle: { [key: string]: string } = {
        height: this.table.offsetHeight + 'px',
        top: this.table.offsetTop + 'px',
        left: resizeHelperView + 'px',
        cursor: 'col-resize',
        'background-color': 'black',
        display: 'block',
      };
      Object.keys(resizeHelperStyle).forEach(style => {
        this.renderer.setStyle(this.resizeHelper, `${style}`, resizeHelperStyle[style]);
      });
      this.lastLeftOffset = this.leftOffsetAfterResize;
    }
  }

  resizerEnd = () => {
    if (this.pressed) {
      const delta = this.leftOffsetAfterResize - <number>this.lastResizerHelperX;
      const newColumnWidth = this.column.offsetWidth + delta;
      const newTableWidth = this.table.offsetWidth + delta;
      if (newColumnWidth >= this.columnInitialWidth && newColumnWidth <= this.columnInitialWidth * 2) {
        this.setTableWidth(newTableWidth);
        this.setColumnWidth(newColumnWidth);
        let storedColumnsWidth = this.localStorageService.getItem(`${this.tableName}ColumnsWidth`);
        storedColumnsWidth = { ...storedColumnsWidth, [this.columnName]: newColumnWidth };
        this.localStorageService.setItem(`${this.tableName}ColumnsWidth`, storedColumnsWidth);
        this.localStorageService.setItem(`${this.tableName}Width`, newTableWidth);
      }
      this.pressed = false;
      this.renderer.removeClass(this.tableContainer, 'unselectable-text');
      this.renderer.setStyle(this.resizeHelper, 'display', 'none');
      this.setTable.emit();
    }
  };

  onMouseDown = (event: MouseEvent) => {
    this.resizerStart(event.pageX);
    event.preventDefault();
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.pressed && event.buttons) {
      this.resizerMove(event.pageX);
    }
  };

  onTouchStart = (event: TouchEvent) => {
    this.resizerStart(event.touches[0].pageX);
    event.preventDefault();
  };

  onTouchMove = (event: TouchEvent) => {
    if (this.pressed && event.touches[0]) {
      this.resizerMove(event.touches[0].pageX);
    }
  };

  initializeResizer() {
    const row = this.renderer.parentNode(this.column);
    const thead = this.renderer.parentNode(row);
    this.table = this.renderer.parentNode(thead);
    this.tableContainer = this.renderer.parentNode(this.table);
    const resizer = this.renderer.createElement('span');
    this.renderer.addClass(resizer, 'resize-holder');
    this.renderer.setStyle(resizer, 'border-right', '6px solid #f0f0f0');
    this.renderer.appendChild(this.column, resizer);
    this.renderer.listen(resizer, 'mousedown', this.onMouseDown);
    this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.renderer.listen('document', 'mouseup', this.resizerEnd);
    this.renderer.listen(resizer, 'touchstart', this.onTouchStart);
    this.renderer.listen(this.table, 'touchmove', this.onTouchMove);
    this.renderer.listen('document', 'touchend', this.resizerEnd);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isResizingOn) this.initializeResizer();
  }

  setColumnWidth(columnWidth: number) {
    if (this.column) this.column.style.width = columnWidth + 'px';
  }

  setTableWidth(tableWidth: number) {
    if (this.table) this.table.style.width = tableWidth + 'px';
  }
}
