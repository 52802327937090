import { Injectable } from '@angular/core';
import { NotesUIModel } from '@app/models/notes';
import { ConversionService } from '@services/conversion.service';
import { Note, DeviceNoteData } from '@services/jsonapi-services/notes.service';
import { Event } from '@services/event.service';

@Injectable({
  providedIn: 'root',
})
export class NotesMapperService {
  notesUiModel!: NotesUIModel[];

  constructor(private conversionService: ConversionService) {}

  mapNotesDataToFrontEnd(notesData: DeviceNoteData): NotesUIModel[] {
    this.notesUiModel = notesData.included.map(notes => {
      return {
        noteId: notes.id,
        text: notes.attributes?.text!,
        commentCount: notes.relationships?.child_notes?.data.length,
        parentId: notes.relationships?.parent_note?.data?.id,
        dateTime: this.conversionService.convertDateTimeStringToDate(notes.attributes?.created_at!, false, true),
        userId: notes.relationships?.user?.data.id!,
        userName: 'RES Dev',
      } as NotesUIModel;
    });
    return this.notesUiModel;
  }

  mapNotesDataToBackend(message: string, deviceId: string, userId: string, parentId?: string): Note {
    return {
      type: 'notes',
      attributes: {
        text: message,
      },
      relationships: {
        device: {
          data: {
            id: deviceId,
            type: 'devices',
          },
        },
        parent_note: {
          data: parentId
            ? {
                id: parentId,
                type: 'notes',
              }
            : null,
        },
        user: {
          data: {
            id: userId,
            type: 'users',
          },
        },
      },
    };
  }

  mapNotesEventData(notes: Event): NotesUIModel {
    return {
      noteId: notes.data.id,
      text: notes.data.attributes!.text!,
      commentCount: 0,
      parentId: notes.data.relationships?.parent_note?.data?.id,
      dateTime: this.conversionService.convertDateTimeStringToDate(new Date().toString(), false, true),
      userId: notes.data.relationships?.user?.data.id!,
      userName: 'RES Dev',
    };
  }
}
