import { Component, Input } from '@angular/core';
import { ChartOptions } from '@app/models/apex-chart';

@Component({
  selector: 'app-apexcharts',
  templateUrl: './apexcharts.component.html',
  styleUrl: './apexcharts.component.css',
})
export class ApexchartsComponent {
  @Input() chartOptions!: ChartOptions;
  constructor() {}
}
