import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '@services/app-config.service';
import { Base, IncludedRelationship } from '@app/models/device';

const MEDIA_TYPE = 'application/vnd.api+json';

interface Ngc20Attributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'ngc20_actual_configs',
  desired_config: 'ngc20_desired_configs',
  device: 'devices',
  info: 'ngc20_infos',
  latest_actual_config: 'ngc20_actual_configs',
  latest_state: 'ngc20_latest_states',
  states: 'ngc20_states',
};

export interface Ngc20_actual_configAttributes {
  device_tag: string;
  htc_0_control_temperature_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
}

export interface Ngc20_latest_stateAttributes {
  alarm_0_value?: boolean | null;
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_line_voltage?: string | null;
  htc_0_power_consumption_0?: string | null;
  htc_0_load_current?: string | null;
  htc_0_switch_state?: boolean | null;
}

export interface Ngc20_latest_stateRelationships {
  Ngc20?: { data: Ngc20 };
}

export interface Ngc20_latest_actual_config extends Base {
  id: string;
  attributes?: Ngc20_actual_configAttributes;
}

export interface Ngc20_latest_state extends Base {
  id: string;
  attributes?: Ngc20_latest_stateAttributes;
  relationships?: Ngc20_latest_stateRelationships;
}

export interface Ngc20Relationships {
  actual_configs?: { data: Ngc20_latest_actual_config[] };
  desired_config?: { data: ResourceIdentifierObject[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Ngc20_latest_actual_config };
  latest_state?: { data: Ngc20_latest_state };
  states?: { data: unknown[] };
}

export interface Ngc20 extends Base {
  id: string;
  type: string;
  attributes?: Ngc20Attributes;
  relationships?: Ngc20Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc20Data {
  data: Ngc20;
  included?: IncludedRelationship[];
}

export interface Ngc20sData {
  data: Ngc20[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Ngc20Service {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc20s(params: HttpParams): Observable<Ngc20sData> {
    const url = `${this.uiBaseURL}/ngc20s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Ngc20sData>(url, options);
  }

  getNgc20(id: string, params: HttpParams): Observable<Ngc20Data> {
    const url = `${this.uiBaseURL}/ngc20s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Ngc20Data>(url, options);
  }

  addNgc20(ngc20: Ngc20): Observable<Ngc20> {
    const url = `${this.uiBaseURL}/ngc20s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<Ngc20>(url, { data: ngc20 }, options);
  }

  updateNgc20(ngc20: Ngc20): Observable<Ngc20Data> {
    const url = `${this.uiBaseURL}/ngc20s/` + ngc20.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.patch<Ngc20Data>(url, { data: ngc20 }, options);
  }

  removeNgc20(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc20s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }
}
