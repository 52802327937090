<app-overlay class="notes-container col-flex flex-between" [isLoading]="isLoading" [loadingMessage]="blockUIMessage">
  <ng-container body>
    <div class="flex-between notes-title-container">
      <span class="notes-overlay-title" data-cy="title">Notes for {{ deviceTag }}</span>
      <div class="flex">
        <button
          class="notes-header-icon"
          [disabled]="!showSearch && !notesToDisplay.length"
          [matTooltip]="parentNote ? 'No notes available in comments for search' : 'No notes available for search'"
          [matTooltipDisabled]="!!notesToDisplay.length || showSearch"
          (click)="toggleSearchBar()">
          <span class="material-symbols-outlined search-icon"> search{{ showSearch ? '_off' : '' }} </span>
        </button>
        <button
          class="notes-header-icon"
          [disabled]="!notesToDisplay.length"
          [matTooltip]="'No notes to download'"
          [matTooltipDisabled]="!!notesToDisplay.length"
          (click)="exportNotes()">
          <span class="material-symbols-outlined search-icon">download</span>
        </button>
        <button class="notes-header-icon" (click)="onClose()">
          <span class="material-symbols-outlined flex-col-center font-med cl-white close-btn">close</span>
        </button>
      </div>
    </div>
    <div class="col-flex notes-body">
      <div class="parent-note-container">
        @if (showSearch) {
          <div class="notes-search-container">
            <input
              matInput
              type="text"
              placeholder="Search Note"
              [formControl]="noteSearchControl"
              class="notes-search-input" />
          </div>
        }
        @if (parentNote) {
          <div class="row-flex back-button" (click)="getParentNotes()">
            <span class="material-symbols-filled"> arrow_back_ios </span>
            <span class="font-med-bold">Back to All Notes</span>
          </div>
          <div class="col-flex notes-card">
            <ng-container [ngTemplateOutlet]="noteTemplate" [ngTemplateOutletContext]="{ noteInfo: parentNote }">
            </ng-container>
          </div>
          <span class="comment-divider row-flex">
            @if (showSearch) {
              {{ notesToDisplay.length }} results in comments
            } @else if (parentNote.commentCount! > 0) {
              {{ parentNote.commentCount }} comments
            } @else {
              No comments yet
            }
          </span>
        }
      </div>
      <div class="notes-container-body" #chatContainer>
        @for (note of notesToDisplay; track $index) {
          <div [ngClass]="{ 'comment-card': parentNote }" class="col-flex notes-card" #chatContent>
            <ng-container [ngTemplateOutlet]="noteTemplate" [ngTemplateOutletContext]="{ noteInfo: note }">
            </ng-container>
          </div>
        }
        <div class="flex-center notes-end" #chatEnd>
          @if (showSearch && notesToDisplay.length === 0) {
            No Data Found
          } @else {
            You've reached the end of
            {{ showSearch ? 'result' : parentNote ? 'comments' : 'notes' }}
          }
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    @if (!showSearch) {
      <div
        class="notes-footer-options flex-between flex-col-center"
        *check-permissions="[permissionList.DeviceNotesPermissions.CreateDeviceNote]">
        <div class="flex-center note-footer-container">
          <input
            type="text"
            id="note"
            name="note"
            [placeholder]="parentNote ? 'Add Comment' : 'Add a note'"
            matInput
            [formControl]="notesControl"
            (keydown.enter)="postNotesToJsonApi()" />
          <span
            class="material-symbols-outlined cl-grey"
            [ngClass]="
              !spaceCheckPattern.test(notesControl.value!) && notesControl.value! ? 'send-icon' : 'send-icon-disabled'
            "
            (click)="!spaceCheckPattern.test(notesControl.value!) && notesControl.value! ? postNotesToJsonApi() : ''"
            >send</span
          >
        </div>
      </div>
    }
  </ng-container>
</app-overlay>

<ng-template #noteTemplate let-noteInfo="noteInfo">
  <mat-card>
    <mat-card-header>
      <div class="row-flex note-header-text">
        <span class="header-name"> {{ noteInfo.userName }} </span>
        <span class="header-time"> {{ noteInfo.dateTime }}</span>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="text-body" [ngClass]="this.parentNote ? 'comment-main-note' : ''">
        {{ noteInfo.text }}
      </div>
      @if (!parentNote) {
        <div (click)="getComments(noteInfo)" class="flex-center comment-button">
          <span class="material-symbols-filled"> comment </span>
          @if (noteInfo.commentCount > 0) {
            Show comments ({{ noteInfo.commentCount }})
          } @else {
            Comment
          }
        </div>
      }
    </mat-card-content>
  </mat-card>
</ng-template>
