import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogOptions } from '@app/models/dialog-options';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
  @Input() modal: DialogOptions = {};
  @Output() onConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onConfirmation() {
    this.modal.show = false;
    this.onConfirm.emit(true);
  }
}
