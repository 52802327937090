import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdTokenPayload } from '@app/models/id-token';
import { Authorize, TestIdProvider, TokenResponse, localStorageKeyList } from '@app/models/tidp';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';

const SECOND = 1000;
const secondsSinceEpoch = (): number => Math.round(new Date().getTime() / SECOND);

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  provider: TestIdProvider | null;
  tidp = false;
  authorize = {} as Authorize;
  idTokenPayload: IdTokenPayload | null = null;

  constructor(
    public router: Router,
    private http: HttpClient,
  ) {
    this.provider = environment.testIdProvider!;
    if (environment.e2e) {
      this.tidp = true;
    }
  }

  fetchIdToken(): Observable<TokenResponse> {
    const idTokenPayload = this.getIdTokenPayload();
    const claims = JSON.stringify(idTokenPayload);
    if (this.provider) {
      return this.getTestIdToken(this.provider, claims);
    }
    return of({ id_token: '' } as TokenResponse);
  }

  getTestIdToken(provider: TestIdProvider, claims: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(provider.tokenEndpoint, claims, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  private getIdTokenPayload() {
    const time = {
      hours: 10,
      minutes: 60,
      seconds: 60,
    };
    const idTokenPayload = {
      iss: 'https://example.tidp.com/',
      sub: 'tidp|60d22eb315bb780069fff80a',
      aud: 'AKWiMO7Gwg5YlJMsser3CuZWeMxjapDw',
      iat: secondsSinceEpoch(),
      exp: secondsSinceEpoch() + time.hours * time.minutes * time.seconds,
      name: 'nVent User',
      given_name: 'nVent',
      family_name: 'User',
      email: 'nvent.user@nvent.com',
    };
    return idTokenPayload;
  }

  isAuthenticated() {
    return !this.authorize.idToken || this.authorize.idToken === '' || this.authorize.idToken === null ? false : true;
  }

  processIdTokenResponse(response: TokenResponse): void {
    this.authorize.idToken = response.id_token;
    if (this.authorize.idToken === '') {
      return;
    }
    this.authorize.refreshToken = response.refresh_token;
    this.idTokenPayload = this.parseIdToken();
    localStorage.setItem(localStorageKeyList.authorizeKeyName, JSON.stringify(this.authorize));
    this.setSessionTimeout(this.idTokenPayload.exp, this.authorize.providerName);
  }

  parseIdToken(): IdTokenPayload {
    const idToken = this.authorize.idToken;
    const limit = 3;
    const index = { one: 1 };
    let idTokenBody = idToken.split('.', limit)[index.one];
    idTokenBody = idTokenBody.replace(/-/g, '+').replace(/_/g, '/');
    const idTokenPayload: IdTokenPayload = JSON.parse(atob(idTokenBody)) as IdTokenPayload;
    return idTokenPayload;
  }

  private setSessionTimeout(exp: number, providerName: string): void {
    const time = {
      seconds: 60,
      milliseconds: 1000,
    };
    let secondsToExpiration = 0;
    if (this.idTokenPayload !== null) {
      secondsToExpiration = exp - secondsSinceEpoch();
    }
    setTimeout(
      () => {
        if (providerName !== '') {
          localStorage.clear();
        }
      },
      (secondsToExpiration - time.seconds) * time.milliseconds,
    );
  }
}
