import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';

export interface IdTokenAttributes {
  subject: string;
}

export interface IdTokenRelationships {
  user: { data: ResourceIdentifierObject };
  id_provider?: { data: ResourceIdentifierObject };
}

export interface IdToken<Attrs = Partial<IdTokenAttributes>, Rels = Partial<IdTokenRelationships>> {
  type?: string;
  id?: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface IdTokenData {
  data: IdToken;
  included?: ResourceIdentifierObject[];
}

export interface MultiIdTokenData {
  data: IdToken[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class IdTokenService {
  uiBaseURL = '';

  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<MultiIdTokenData> {
    const url = `${this.uiBaseURL}/id_tokens`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiIdTokenData>(url, options);
  }

  addWithoutId(IdToken: IdToken): Observable<IdToken> {
    const url = `${this.uiBaseURL}/id_tokens`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.post<IdToken>(url, { data: IdToken }, options);
  }
}
