import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  userPermissions: string[] = [];
  constructor() {}

  extractPermissions(data: any[]) {
    this.userPermissions = data.map(
      permission => `${permission.attributes.operation}.${permission.attributes.resource}`,
    );
  }

  checkPermission(permission: string) {
    return this.userPermissions.includes(permission);
  }
}
