import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeviceUIModel } from '@models/device';
import { DataStoreService } from '@services/data-store.service';
import { STORAGE_KEYS } from '@utils/constants/local-storage-constants';
import { GroupTypes } from '@utils/enums/device-enums';
import { Page } from '@utils/enums/page-enum';
import { combineLatestWith, map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  @Input() title: string = '';
  @Output() selectedGroupEvent = new EventEmitter<GroupTypes>();
  @Output() searchEvent = new EventEmitter<DeviceUIModel[]>();
  @Output() searchTag = new EventEmitter<string>();
  destroyRef = inject(DestroyRef);
  selectedGroup = (localStorage.getItem(STORAGE_KEYS.selectedGroup) as GroupTypes) || GroupTypes.Parent;
  searchString: string = localStorage.getItem(STORAGE_KEYS.searchString) || '';
  searchControl = new FormControl(this.searchString);
  searchedDevices!: Observable<DeviceUIModel[]>;
  allDevicesData: DeviceUIModel[] = [];
  deviceCount: number = 0;
  searchInit: boolean = true;
  pageEnum = Page;
  filteredDevices: DeviceUIModel[] = [];

  constructor(private dataStoreService: DataStoreService) {}

  ngOnInit(): void {
    if (this.title.toLowerCase().includes('devices') || this.title.toLowerCase().includes('alarms'))
      this.getDevicesFromDataStore();
  }

  getDevicesFromDataStore() {
    this.dataStoreService
      .getDevicesData()
      .pipe(combineLatestWith(this.dataStoreService.getAlarmsData()))
      .subscribe({
        next: ([devicesData, alarmsData]) => {
          if (!devicesData.isLoading && !alarmsData.isLoading) {
            this.allDevicesData = devicesData.data;
            if (this.searchInit) {
              this.searchedDevices = this.searchControl.valueChanges.pipe(
                startWith(this.searchString),
                map(value => {
                  this.searchTag.emit(value?.toLowerCase());
                  return this.getSearchedDevices(value!);
                }),
              );
              this.searchInit = false;
            }
            if (this.allDevicesData.length && alarmsData.data.length) {
              this.allDevicesData.forEach((device: DeviceUIModel) => {
                device.alarmDetails = alarmsData.data.filter(alarm => alarm.deviceTag === device.deviceTag);
              });
              this.getSearchedDevices(this.searchString);
            }
          }
        },
        error: (err: unknown) => {
          console.error('Error getting devices from Redis', err);
        },
      });
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  private getSearchedDevices(value: string): DeviceUIModel[] {
    this.searchString = value.toLowerCase();
    this.filteredDevices = this.allDevicesData.filter(device =>
      device.deviceTag ? device.deviceTag!.toLowerCase().includes(this.searchString) : false,
    );
    this.deviceCount = this.filteredDevices.length;
    this.searchEvent.emit(this.filteredDevices);
    localStorage.setItem(STORAGE_KEYS.searchString, this.searchString);
    return this.filteredDevices;
  }
}
