import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@app/models/device';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';

const typeNameByRelName: Record<string, string> = {
  actual_configs: 'elexant3500i_actual_configs',
  desired_config: 'elexant3500i_desired_configs',
  device: 'devices',
  info: 'elexant3500i_infos',
  latest_actual_config: 'elexant3500i_actual_configs',
  latest_state: 'elexant3500i_latest_states',
  states: 'elexant3500i_states',
};

export interface Elexant3500i_actual_configAttributes {
  device_tag: string;
  htc_0_control_temperature_setpoint?: string;
  htc_0_control_temperature_high_alarm_setpoint?: string;
  htc_0_control_temperature_low_alarm_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
}

export interface Elexant3500i_latest_stateAttributes {
  alarm_0_value?: boolean | null;
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_load_current?: string | null;
  htc_0_switch_state?: boolean | null;
}

export interface Elexant3500i_latest_actual_config extends Base {
  id: string;
  attributes?: Elexant3500i_actual_configAttributes;
}

export interface Elexant3500iAttrs {
  name: string | null;
  serial: number | null;
}

export interface Elexant3500iRels {
  actual_configs: { data: ResourceIdentifierObject[] };
  desired_config: { data: ResourceIdentifierObject[] };
  device: { data: ResourceIdentifierObject };
  info: { data: ResourceIdentifierObject };
  latest_actual_config: { data: ResourceIdentifierObject };
  latest_state: { data: ResourceIdentifierObject };
  states: { data: ResourceIdentifierObject[] };
}

export interface Elexant3500i<Attrs = Partial<Elexant3500iAttrs>, Rels = Partial<Elexant3500iRels>> {
  type: string;
  id: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Elexant3500iData {
  data: Elexant3500i;
  included?: ResourceIdentifierObject[];
}

export interface Elexant3500isData {
  data: Elexant3500i[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Elexant3500iService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<Elexant3500isData> {
    const url = `${this.uiBaseURL}/elexant3500is`;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Elexant3500isData>(url, options);
  }

  get(id: string, params: HttpParams): Observable<Elexant3500iData> {
    const url = `${this.uiBaseURL}/elexant3500is/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Elexant3500iData>(url, options);
  }

  add(elexant3500i: Elexant3500i): Observable<never> {
    const url = `${this.uiBaseURL}/elexant3500is`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<never>(url, { data: elexant3500i }, options);
  }

  update(elexant3500i: Elexant3500i): Observable<Elexant3500iData> {
    const url = `${this.uiBaseURL}/elexant3500is/` + elexant3500i.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.patch<Elexant3500iData>(url, { data: elexant3500i }, options);
  }

  remove(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/elexant3500is/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getElexant3500iTrendData(params: HttpParams, id: string): Observable<Elexant3500isData> {
    const url = `${this.uiBaseURL}/elexant3500is/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: MEDIA_TYPE,
      }),
      params,
    };
    return this.http.get<Elexant3500isData>(url, options);
  }
}
