import { RoleEnum } from '@utils/enums/roles-enum';

export const rolePermissions: { [key: string]: string[] } = {
  'Level 1 - Limited': [
    'Monitor devices',
    'View device configuration settings',
    'View alarms',
    'Change user preferences',
  ],
  'Level 2 - Alarm Control': ['Reset, Acknowledge, Shelve, Un-Shelve'],
  'Level 3 - Device Management': ['Add / Remove devices', 'Configure devices', 'Create Device Notes and add Comments'],
  'Level 4 - Administrator': ['Site Hierarchy', 'Network / Port configurations'],
};

export const roleDescriptions: { [key: string]: string } = {
  'Level 1 - Limited': 'Allows the user to VIEW all aspects of the application.  For example:',
  'Level 2 - Alarm Control': 'Level 1 capabilities + the ability to change the State of Alarms.  For example:',
  'Level 3 - Device Management': 'Level 2 capabilities + the ability to manage devices.  For example:',
  'Level 4 - Administrator':
    'Level 3 capabilities + the ability to change User Levels (permissions), Roles, and Passwords, as well as manage System Level settings.  For example:',
};

const time = {
  hours: 10,
  minutes: 60,
  seconds: 60,
};
const SECOND = 1000;
const secondsSinceEpoch = (): number => Math.round(new Date().getTime() / SECOND);
export const idTokenPayload = {
  name: RoleEnum.level1,
  iss: 'https://example.tidp.com/',
  sub: 'tidp|60d22eb315bb780069fff80a',
  aud: 'AKWiMO7Gwg5YlJMsser3CuZWeMxjapDw',
  exp: secondsSinceEpoch() + time.hours * time.minutes * time.seconds,
  preferred_username: RoleEnum.level1,
};
